import React, { useEffect, useState ,useRef} from "react";
import Sidebar from "./Sidebar";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import solutionContext from "../../context/solution/solutionContext";
import { useContext } from "react";
import Chart from "react-apexcharts";
import { Alert } from "react-bootstrap";
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js/lib/core';
import { useResetAxes } from '../../context/ReactAxesContext';
import CopperlossSkelton from "./CopperlossSkelton";

function CopperLoss(props) {
  const plotRef = useRef(null);
  const { resetAxes } = useResetAxes();
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [onload, setOnLoad] = useState(false);

  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();

  const [totalCopperLoss, setTotalCopperLoss] = useState();
  const [copperLossMaxLimit, setCopperLossMaxLimit] = useState();
  const [totalHoursAboveMaxLimit, setTotalHoursAboveMaxLimit] = useState();
  const [kwhChartData, setKwhChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: [], // Empty initially until data is fetched
      },
      // Rest of your options remain unchanged
    },
    series: [
      {
        name: "Copper",
        data: [],
      },
    ],
  });
  const [isChartDataAvailable, setIsChartDataAvailable] = useState(true);
  const [isChartDataAvailableTwo, setIsChartDataAvailableTwo] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: [], // Empty initially until data is fetched
      },
      // Rest of your options remain unchanged
    },
    series: [
      {
        name: "Copper",
        data: [],
      },
    ],
  });



  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          // location: selectedLocation,
          // site: selectedAsset,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) { }
    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

// for chart responsive 
useEffect(() => {
  const handleModeBarButtonClick = (buttonName) => {
    console.log("clicked-hotspot", buttonName);

    if (plotRef.current) {
      setTimeout(() => {
        const button = plotRef.current.querySelector(`.modebar-btn[data-title="${buttonName}"]`);
        if (button) {
          button.click();
          console.log("button-clicked", button);
        } else {
          console.warn(`Button with title "${buttonName}" not found.`);
        }
      }, 500);
    }
  };

  handleModeBarButtonClick('Zoom');
}, [resetAxes]);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location)
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];

        // setSelectedAsset(new_asset);
        setOnloadAsset(new_asset)
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // if (isLoading) {
  //   return (
  //     <div className="loaderWpr">
  //       <div className="loader"></div>
  //     </div>
  //   );
  // }

  // date format for breadcrums
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }

  //Total Copper Loss api
  const total_copper_loss = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      const data = props;
      setIsLoading(true);
      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/copper_loss/total_copper_loss_kwh`,
          data
        );
        // if (response.data.data.length === 0) {
        //   setTotalCopperLoss();
        //   showFlashMessage("Data Not Available Total Copper Loss (kWh)", "danger");
        //   setIsLoading(false);
        //   return;
        // }
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setTotalCopperLoss(response.data.data);
          success = true;
          if (response.data.start_date && response.data.end_date) {
            setNewStartDate(response.data.start_date);
            setNewEndDate(response.data.end_date);
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        setIsLoading(false);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      setTotalCopperLoss();
      // showFlashMessage("Data Not Available Total Copper Loss (kWh)", "danger");
      setIsLoading(false);
    }
  };

  // Copper Loss Max Limit
  const copper_loss_max_limit = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      const data = props;
      // setIsLoading(true);
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/copper_loss/copper_loss_max_limit_kwh`,
          data
        );
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setCopperLossMaxLimit(response.data.data);
          success = true;
        } else {
          // setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      setCopperLossMaxLimit();
      // showFlashMessage("Data Not Available Copper Loss Max Limit (kW)", "danger");
    }
    // setIsLoading(false);
  };


  // total_hours_above_max_limit
  const total_hours_above_max_limit = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      const data = props;
      // setIsLoading(true);
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/copper_loss/total_hours_above_max_limit`,
          data
        );
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setTotalHoursAboveMaxLimit(response.data.data);
          success = true;
        } else {
          // setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      setTotalHoursAboveMaxLimit();
      // showFlashMessage("Data Not Available Total Hours Above Max Limit", "danger");
    }
    // setIsLoading(false);
  };
  const emptyChartDataKwh = {
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: true,
        },
      },
      yaxis: {
        title: {
          text: 'Total Copper Loss (kWh)',
          style: {
            fontFamily: "Montserrat",
            fontSize: '12px',  // Set the font size here
            fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
            color: '#000000'  // Optional: Set the color of the title
          }
        },
      },
      xaxis: {
        type: 'category',
        title: {
          text: 'Datetime',
          style: {
            fontFamily: "Montserrat",
            fontSize: '12px',  // Set the font size here
            fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
            color: '#000000'  // Optional: Set the color of the title
          }
        },
        labels: {
          show: false,
        },
        categories: [],
      },
    },
    series: [
      {
        name: "Copper",
        data: [],
      },
    ],
  };

  // Function to format date to "DD-MM-YYYY"
  const chartFormatDate = (timestamp) => {
    let date = new Date(timestamp).toUTCString();
    const originalDate = new Date(date);

    // Add 5 hours and 30 minutes to the original date
    originalDate.setHours(originalDate.getHours() - 5);
    originalDate.setMinutes(originalDate.getMinutes() - 30);

    // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
    const formattedDate =
      ('0' + originalDate.getDate()).slice(-2) + '-' +
      ('0' + (originalDate.getMonth() + 1)).slice(-2) + '-' +
      originalDate.getFullYear()

    return formattedDate;
  }

  // bar graph
  const total_copper_loss_kwh_graph = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      const data = props;
      // setIsLoading(true);

      try {

        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/copper_loss/total_copper_loss_kwh_graph`,
          data
        );

        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          const apiData = response.data.data;
          // Convert timestamps to date strings for x-axis categories
          // const dates = apiData.created_at.map((timestamp) => {
          //   const date = new Date(timestamp);
          //   return date.toLocaleString(); // Adjust format as needed
          // });

          const dates = apiData.created_at
          // const dates = apiData.created_at.map((timestamp) => {
          //   const date = new Date(timestamp);
          //   const formattedDate =
          //     date.getFullYear() +
          //     "-" +
          //     ("0" + (date.getMonth() + 1)).slice(-2) +
          //     "-" +
          //     ("0" + date.getDate()).slice(-2);
          //   return formattedDate;
          // });

          const copperValues = apiData.total_copper_loss_kwh
          // apiData.total_copper_loss_kwh.map(truncateToOneDigits);
          const updatedChartData = {
            options: {
              chart: {
                toolbar: {
                  export: {
                    csv: {
                      filename: "Total Copper Loss (kWh)",
                      headerCategory: "Date",
                      categoryFormatter:  (dateString) => {
                        const dateParts = dateString.split('-');
                        
                        // Expecting dateString in format YYYY-MM-DD
                        const [year, month, day] = dateParts;
                      
                        // Format the date in "DD-MM-YYYY"
                        return `${day}-${month}-${year}`;
                      },
                    },
                    png: {
                      filename: "Total Copper Loss (kWh)",
                    },
                    svg: {
                      filename: "Total Copper Loss (kWh)",
                    }
                  },
                },
                animations: {
                  enabled: false
                },
                type: "bar",
                height: 200,
                with: '90%',
                zoom: {
                  enabled: true,
                },
              },
              xaxis: {
                // type: "datetime",
                categories: dates,
                labels: {
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: '12px',  // Set the font size here
                    fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                    color: '#000000'  // Optional: Set the color of the title
                  },
                  formatter: (value) => chartFormatDate(new Date(value)),
                },
                title: {
                  text: "Date",
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: '12px',  // Set the font size here
                    fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                    color: '#000000'  // Optional: Set the color of the title
                  }
                },
              },
              yaxis: {
                title: {
                  text: "Total Copper Loss (kWh)",
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: '12px',  // Set the font size here
                    fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                    color: '#000000'  // Optional: Set the color of the title
                  }
                },
              },
              dataLabels: {
                enabled: false, // This disables data labels for all series
              },
              tooltip: {
                x: {
                  format: "dd-MM-yyyy",
                },
                cssClass: 'custom-tooltip',
      style: {
        fontFamily: 'Montserrat, sans-serif',  // Set font family for tooltip
        fontSize: '14px',  // Set font size for tooltip
        
      }
              },
              grid: {
                padding: {
                  bottom: 20, // Adjust bottom padding to ensure x-axis title is not cut off
                }
              }
            },
            series: [
              {
                name: "Total Copper Loss (kWh)",
                data: copperValues,
                color: "#1B75BB",
                style: {
                  fontFamily: "Montserrat",
                  fontSize: '12px',  // Set the font size here
                  fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                  color: '#000000'  // Optional: Set the color of the title
                }
              },
            ],
          };

          setKwhChartData(updatedChartData);
          success = true;
          setIsChartDataAvailable(true);
          // setIsLoading(false);
        } else {
          setIsChartDataAvailable(false);
          // showFlashMessage("Data Not Available For Total Copper Loss (kWh)", "danger");
          // setIsLoading(false);
        }
      } catch (error) {
        setIsChartDataAvailable(false);
        // showFlashMessage("Data Not Available For Total Copper Loss (kWh)", "danger");
        showFlashMessage("Error fetching Copper data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      setIsChartDataAvailable(false);
      showFlashMessage("Data Not Available For Total Copper Loss (kWh)", "danger");
    }
  };

  // bar graph
  const [copperDates, setCopperDates] = useState([])
  const [copperValues, setCopperValues] = useState([])
  const [thresholdValue, setThresholdValue] = useState("")

  const emptyChartData = {
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: true,
        },
      },
      yaxis: {
        title: {
          text: 'Total Copper Loss (kW)',
          style: {
            fontFamily: 'Montserrat',
            fontSize: '12px',  // Set the font size here
            fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
            color: '#000000'  // Optional: Set the color of the title
          }
        },

      },
      xaxis: {
        type: 'category',
        title: {
          text: 'Datetime',        
        },
        style: {
          fontFamily: 'Montserrat',
          fontSize: '12px',  // Set the font size here
          fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
          color: '#000000'  // Optional: Set the color of the title
        },
        labels: {
          show: false,
        },
        categories: [],
      },
    },
    series: [
      {
        name: "Copper",
        data: [],
        style: {
          fontFamily: "Montserrat",
          fontSize: '12px',  // Set the font size here
          fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
          color: '#000000'  // Optional: Set the color of the title
        }
      },
    ],
  };


  const total_copper_loss_kw = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const data = props;
        // setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/copper_loss/total_copper_loss_kw_graph`,
          data
        );

        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          const apiData = response.data.data;
          // Convert timestamps to date strings for x-axis categories
          const dates = apiData.created_at.map((timestamp) => {
            const date = new Date(timestamp);
            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const day = date.getUTCDate().toString().padStart(2, '0');
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            const seconds = date.getUTCSeconds().toString().padStart(2, '0');
            const utcFormattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

            return utcFormattedDate;
          });
          const copperValues = apiData.total_copper_loss_kw
          // apiData.total_copper_loss_kw.map(truncateToOneDigits);
          const thresholdValue = apiData.copper_loss_max_limit
          //  truncateToOneDigits(
          //   apiData.copper_loss_max_limit
          // );

          const updatedChartData = {
            options: {
              chart: {
                toolbar: {
                  export: {
                    csv: {
                      filename: "Total Copper Loss (kW)",
                      headerCategory: "Date",
                      categoryFormatter: function (timestamp) {
                        let date = new Date(timestamp).toUTCString();
                        const originalDate = new Date(date);

                        // Add 5 hours and 30 minutes to the original date
                        originalDate.setHours(originalDate.getHours() - 5);
                        originalDate.setMinutes(originalDate.getMinutes() - 30);

                        // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                        const formattedDate =
                          ("0" + originalDate.getDate()).slice(-2) +
                          "-" +
                          ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                          "-" +
                          originalDate.getFullYear()
                        // ("0" + originalDate.getHours()).slice(-2) +
                        // ":" +
                        // ("0" + originalDate.getMinutes()).slice(-2) +
                        // ":" +
                        // ("0" + originalDate.getSeconds()).slice(-2);
                        return formattedDate;
                      },
                    },
                    png: {
                      filename: "Total Copper Loss (kW)",
                    },
                    svg: {
                      filename: "Total Copper Loss (kW)",
                    }
                  },
                },
                animations: {
                  enabled: false
                },
                type: "bar",
                height: 200,
                zoom: {
                  enabled: true,
                },
              },
              xaxis: {
                type: "datetime",
                categories: dates,
                automargin: true,
                labels: {
                  datetimeFormatter: {
                    day: "dd-MM-yyyy",
                    month: "MM-yyyy",
                    year: "yyyy",
                    hour: "dd-MM-yyyy HH:mm",
                    
                  },
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: '12px',  // Set the font size here
                    fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                    color: '#000000'  // Optional: Set the color of the title
                  }
                  // formatter: function (value) {
                  //   return new Date(value).toLocaleString();
                  // },
                },
                title: {
                  text: "Datetime",
                },
                style: {
                  fontFamily: 'Montserrat',
                  fontSize: '12px',  // Set the font size here
                  fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                  color: '#000000'  // Optional: Set the color of the title
                }
              },
              annotations: {
                yaxis: [
                  {
                    title: {
                      text: "Total Copper Loss (kW)",
                      
                    },
                    style: {
                      fontFamily: "Montserrat",
                      fontSize: '12px',  // Set the font size here
                      fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                      color: '#000000'  // Optional: Set the color of the title
                    },
                    y: thresholdValue,
                    borderColor: "#000",
                    borderWidth: 1,
                    label: {
                      borderColor: "#000",
                      style: {
                        color: "#000",
                        background: "#fff",
                        fontFamily: "Montserrat",
                        fontSize: '12px',  // Set the font size here
                        fontWeight: '500',
                      },
                      text: "Limit: " + thresholdValue.toString(), // Label for the threshold value
                      // offsetX: -1200, // Adjust the horizontal offset to position closer to x-axis
                    },
                  },
                ],
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [
                      {
                        from: -Infinity,
                        to: thresholdValue,
                        color: "#0095DA",
                      },
                      {
                        from: thresholdValue,
                        to: Infinity,
                        color: "#FF6873",
                      },
                    ],
                  },
                },
              },
              yaxis: {
                title: {
                  text: "Total Copper Loss (kW)",
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: '12px',  // Set the font size here
                    fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
                    color: '#000000'  // Optional: Set the color of the title
                  },
                },
                y: thresholdValue,
                borderColor: "#000",
                borderWidth: 1,
                label: {
                  borderColor: "#000",
                  style: {
                    color: "#000",
                    background: "#fff",
                    fontFamily: "Montserrat",
                    fontSize: '12px',  // Set the font size here
                    fontWeight: '500', 
                  },
                  text: "Limit: " + thresholdValue.toString(), // Label for the threshold value
                  // offsetX: -1200, // Adjust the horizontal offset to position closer to x-axis
                },
              },
              dataLabels: {
                enabled: false, // This disables data labels for all series
              },
              tooltip: {
                x: {
                  format: "dd-MM-yyyy HH:mm",
                },
                cssClass: 'custom-tooltip',
      style: {
        fontFamily: 'Montserrat, sans-serif',  // Set font family for tooltip
        fontSize: '14px',  // Set font size for tooltip
        
      }
              },
            },
            series: [
              {
                name: "Total Copper Loss (kW)",
                data: copperValues,
                
style: {
  fontFamily: "Montserrat",
  fontSize: '12px',  // Set the font size here
  fontWeight: '500',  // Set the font weight here (e.g., 'bold', 'bolder', 'lighter', or a numeric value)
},
              },
            ],
          };
          setCopperValues(copperValues)
          setCopperDates(dates)
          setThresholdValue(thresholdValue)
          setChartData(updatedChartData); // Set the updated chart data state
          setIsChartDataAvailableTwo(true);
          success = true;
          // setIsLoading(false);
        } else {
          // showFlashMessage("Data Not Available For Total Copper Loss (kW)", "danger");
          setIsChartDataAvailableTwo(false);
          // setIsLoading(false);
        }
      } catch (error) {
        // showFlashMessage("Data Not Available For Total Copper Loss (kW)", "danger");
        setIsChartDataAvailableTwo(false);
        showFlashMessage("Error fetching Copper data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available For Total Copper Loss (kW)", "danger");
      setIsChartDataAvailableTwo(false);
    }
  };

  var excelIcon = {};
  const formatDateTime = (timestamp) => {
    let date = new Date(timestamp).toUTCString();
    const originalDate = new Date(date);
    const formattedDate =

      ('0' + originalDate.getDate()).slice(-2) + ' ' +
      ('0' + (originalDate.getMonth() + 1)).slice(-2) + '-' +
      originalDate.getFullYear() + '-' +
      ('0' + originalDate.getHours()).slice(-2) + ':' +
      ('0' + originalDate.getMinutes()).slice(-2) + ':' +
      ('0' + originalDate.getSeconds()).slice(-2);
    return formattedDate;
  };

  if (copperDates.length > 0 && copperValues.length > 0) {
    var iExcel = {
      width: 400,
      height: 500,
      path: "M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z",
    };

    excelIcon = {
      name: "excel-icon",
      icon: iExcel,
      click: function (gd) {

        const csvContent =
          'data:text/csv;charset=utf-8,' +
          'Date Time,Copper Loss\n' +
          copperDates.map((date, index) => `${formatDateTime(date)},${copperValues[index]}`).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'Total Copper Loss (kW).csv');
        document.body.appendChild(link);
        link.click();
      },
      title: "Export to Excel",
    };
  }


  const handleSubmitData = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;

    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location)
    setOnloadAsset(site)


    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // start_date: convertDateCloneApi(fromDate),
        // end_date: convertDateCloneApi(toDate),
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer",
      };
      try {
        await total_copper_loss(data);
        await copper_loss_max_limit(data);
        await total_hours_above_max_limit(data)
        await total_copper_loss_kwh_graph(data)
        await total_copper_loss_kw(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  }

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      try {
        await total_copper_loss(data);
        await copper_loss_max_limit(data);
        await total_hours_above_max_limit(data)
        await total_copper_loss_kwh_graph(data)
        await total_copper_loss_kw(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  }

  const truncateToOneDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = Math.trunc(value * 10) / 10;
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "";
  };

  return (
    <>
      <div className="wrapper">
        {isLoading && (
          <CopperlossSkelton/>
        )}
       
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              {/* <div className="content-header">
                
              </div> */}
              <div className="mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">{selectedAsset.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">{selectedLocation.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">
                        {" "}
                        {newStartDate ? (
                          <a href="#">{newStartDate}</a>
                        ) : (
                          <a href="#">{formatDate(fromDate)}</a>
                        )}
                        &nbsp;-&nbsp;
                        {newEndDate ? (
                          <a href="#">{newEndDate}</a>
                        ) : (
                          <a href="#">{formatDate(toDate)}</a>
                        )}
                      </a>
                    </li>
                  </ol>
                </div>

                <div className="mdlContent card-body mt20">
                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <div className="card pr-card">
                        <div className="inrCardHdr">
                          <h5>Total Copper Loss (kWh)</h5>
                        </div>
                        <div className="inrCardBody text-center">
                          <h3 className="mb0">
                            {isLoading ? (
                              <></>
                            ) : totalCopperLoss ? (
                              <>{(totalCopperLoss.Value)}</>
                              /* <>{truncateToOneDigits(totalCopperLoss.Value)}</> */
                            ) : (
                              <>-</>
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="card pr-card">
                        <div className="inrCardHdr">
                          <h5>Copper Loss Max Limit (kW)</h5>
                        </div>
                        <div className="inrCardBody text-center">
                          <h3 className="mb0">
                            {isLoading ? (
                              <></>
                            ) : copperLossMaxLimit ? (
                              <>
                                {(copperLossMaxLimit.Value)}
                                {/* {truncateToOneDigits(copperLossMaxLimit.Value)} */}
                              </>
                            ) : (
                              <>-</>
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="card pr-card">
                        <div className="inrCardHdr">
                          <h5>Total Hours Above Max Limit</h5>
                        </div>
                        <div className="inrCardBody text-center">
                          <h3 className="mb0">
                            {isLoading ? (
                              <></>
                            ) : totalHoursAboveMaxLimit ? (
                              <>
                                {(totalHoursAboveMaxLimit.total_hours_above_max_limit)}
                                {/* {truncateToOneDigits(totalHoursAboveMaxLimit.total_hours_above_max_limit)} */}
                              </>
                            ) : (
                              <>0</>
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="kwCard card pr-card">
                    <div className="inrCardHdr">
                      <h5>Total Copper Loss (kW)</h5>
                    </div>
                    <div className="inrCardBody">
                      {isChartDataAvailableTwo ? (

                        <Plot
                        onInitialized={(figure, graphDiv) => {
                          plotRef.current = graphDiv; // Set the graphDiv to the ref
        }}
                          data={[
                            {
                              x: copperDates.map(date => new Date(date)),
                              y: copperValues,
                              type: 'bar',
                              marker: {
                                color: chartData.series[0].data.map(value => value > thresholdValue ? '#FF6873' : '#0095DA'),
                                base: 0, // Base of the bars
                                height: 50, // Height of the bars
                              },
                            },
                          
                          ]}
                          layout={{
                            autosize: true,
                            responsive: true,
                            modebardisplay: false,
                            // title: 'Total Copper Loss (kW)',
                            margin: {
                              l: 10, 
                              r: 10, 
                              b: 0,
                              t: 35,
                            },
                            xaxis: {
                              type: "date",
                              title: {
                                text: 'Datetime',  // Set your y-axis title here
                                font: {
                                  family: 'Montserrat',
                                  size: 13,  // Set your desired font size here
                                  weight:500,
                                }
                              },
                              tickfont: {
                                family: 'Montserrat',
                                size: 12, // Set your desired font size here
                              },
                              tickmode: "auto",
                              nticks: 8,
                              tickangle: 0,
                              automargin: true,
                              // tickformat: "%d-%m-%Y %H:%M",
                              hoverformat: "%d-%m-%Y %H:%M:%S",
                            },
                            yaxis: {
                              title: {
                                text: 'Total Copper Loss (kW)',  // Set your y-axis title here
                                font: {
                                  family: 'Montserrat',
                                  size: 13,  // Set your desired font size here
                                  weight:500
                                },
                                standoff: 20
                              },
                              tickfont: {
                                family: 'Montserrat',
                                size: 12, // Set your desired font size here
                                color: '#000000' , // Optional: Set the color of the labels
                              },
                              automargin: true,
                              fixedrange: true,
                            },
                            height: 350,
                            shapes: [{
                              type: 'line',
                              x0: new Date(copperDates[0]),
                              x1: new Date(copperDates[copperDates.length - 1]),
                              y0: thresholdValue,
                              y1: thresholdValue,
                              line: {
                                color: 'black',
                                dash: 'dot',
                                width: 1,
                              },
                            }],
                            annotations: [
                              {
                                x: new Date(copperDates[0]).getTime() + (new Date(copperDates[copperDates.length - 1]).getTime() - new Date(copperDates[0]).getTime()) * 0.9, // Adjust as needed
                                y: thresholdValue,
                                xref: 'x',
                                yref: 'y',
                                text: `Limit: ${thresholdValue}`,
                                showarrow: false,
                                arrowhead: 0,
                                ax: 0,
                                ay: -50, // Adjust as needed
                                font: {
                                  family: 'Arial',
                                  size: 12,
                                  color: 'black'
                                },
                                bgcolor: 'lightgrey',
                                bordercolor: 'black',
                                borderwidth: 1,
                                borderpad: 4,
                                opacity: 0.9
                              }
                            ],
                            hoverlabel: {
      font: {
        color: 'white'
      },
      bordercolor: 'white',
                            }
                          }}
                          
                          config={{
                            displayModeBar: true,
                            displaylogo: false,
                            responsive: true,
                            toImageButtonOptions: {
                              filename: 'Total Copper Loss (kW)',
                            },
                            modeBarButtonsToAdd: [excelIcon],
                            modeBarButtonsToRemove: ['select2d', 'lasso2d', 'autoscale'],
                          }}
                          style={{ width: '100%' }}
                          onUpdate={(eventdata) => {
                            const plot = document.getElementById('chart-container');
                            if (plot) {
                              const xaxis = plot._fullLayout.xaxis;
                              const numBars = plot.data[0].x.length;

                              if (numBars <= 10) {
                                xaxis.tickformat = "%H:%M:%S";
                              } else {
                                xaxis.tickformat = "%d-%m-%Y %H:%M:%S";
                              }

                              if (
                                eventdata["xaxis.range[0]"] &&
                                eventdata["xaxis.range[1]"] &&
                                xaxis
                              ) {
                                const xRange = [
                                  new Date(eventdata["xaxis.range[0]"]),
                                  new Date(eventdata["xaxis.range[1]"]),
                                ];
                                const xDiff = xRange[1].getTime() - xRange[0].getTime();
                                const daysDiff = xDiff / (1000 * 3600 * 24);

                                let newTickFormat;

                                if (daysDiff <= 1) {
                                  newTickFormat = "%d-%m-%Y %H:%M:%S";
                                } else if (daysDiff <= 7) {
                                  newTickFormat = "%d-%m-%Y";
                                } else {
                                  newTickFormat = "%d-%m-%Y";
                                }
                                if (xaxis.tickformat !== newTickFormat) {
                                  Plotly.relayout("chart-container", {
                                    "xaxis.tickformat": newTickFormat,
                                  })
                                }
                              }
                            }
                          }}
                        />
                      ) : (
                        <>
                          <Chart
                            options={emptyChartData.options}
                            series={emptyChartData.series}
                            type="bar"
                            height={200}
                          />
                        </>

                     

                      )}
                    </div>
                  </div>

                  <div className="card pr-card">
                    <div className="inrCardHdr">
                      <h5>Total Copper Loss (kWh)</h5>
                    </div>
                    <div className="inrCardBody appchartWpr">
                      {isChartDataAvailableTwo ? (
                        <Chart
                          options={kwhChartData.options}
                          series={kwhChartData.series}
                          type="bar"
                          height={200}
                        />
                      ) : (
                        <>
                          <Chart
                            options={emptyChartDataKwh.options}
                            series={emptyChartDataKwh.series}
                            type="bar"
                            height={200}
                          />
                        </>

                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default CopperLoss;
