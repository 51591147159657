import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const TxiqSkeleton = ({ isLoading, alerts = [] }) => {
  return (
    <>
    
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {/* Loader */}
            {isLoading && (
              <div className="loaderWpr">
                <div className="loader"></div>
              </div>
            )}

       

            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                
                <div className="mdlContent card-body">
                  <div className="col-md-12 col-lg-12">
                    <div className="row">
                      {/* Active Device Count Skeleton */}
                      <div className="col-md-6 col-lg-6">
                        <div className="info-box">
                          <span className="info-box-icon bg-greenPin elevation-1"></span>
                          <div className="info-box-content">
                            <Skeleton variant="text" width={100} />
                          </div>
                          <div className="info-box-number">
                            <Skeleton variant="text" width={50} />
                          </div>
                        </div>
                      </div>

                      {/* Inactive Device Count Skeleton */}
                      <div className="col-md-6 col-lg-6">
                        <div className="info-box">
                          <span className="info-box-icon bg-grayPin elevation-1"></span>
                          <div className="info-box-content">
                            <Skeleton variant="text" width={100} />
                          </div>
                          <div className="info-box-number">
                            <Skeleton variant="text" width={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Map Section Skeleton */}
                  <div className="map col-md-12 col-lg-12">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <Skeleton variant="rectangular" width="100%" height={250} />
                      </div>
                    </div>
                  </div>

                  {/* Table Section Skeleton */}
                  <div className="col-md-12 col-lg-12">
                    <Skeleton variant="rectangular" width="100%" height={400} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default TxiqSkeleton;
