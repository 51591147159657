import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const TrendSkeleton = () => {
  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                {/* Breadcrumb Skeleton */}
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={150} height={30} />
                    </li>
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={150} height={30} />
                    </li>
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={300} height={30} />
                    </li>
                  </ol>
                </div>

                <div className="mdlContent card-body">
                  {/* Line Chart Skeleton */}
                  <Skeleton variant="rectangular" width="100%" height={400} />

                  {/* Table Skeleton */}
                  <div className="table-responsive mt-3">
                    <Skeleton variant="text" width={200} height={30} />
                    <Skeleton variant="rectangular" width="100%" height={300} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TrendSkeleton;
