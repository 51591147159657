import EnergyLossChart from "./EnergyLossChart";

function EnergyLossBlockes({
  benchmarkLossKWH,
  actualLossKWH,
  benchmarkLossINR,
  actualLossINR,
  benchmarkLoss,
  actualLoss,
  requestData,
  chartData,
}) {
  const getName = (data, title) => {
    return data ? data : title;
  };

  const getValue = (data) => {
    return data ? data : "-";
  };

  const getSymbol = (data) => {
    return data ? data : " ";
  };
  return (
    <>
      <div className="enryLosPg mdlContent card-body mt20">
        <div className="row">
          <div className="col-md-2">
            <div className="card pr-card">
              <div className="inrCardHdr">
                <h5>
                  {getName(
                    benchmarkLossKWH["Name"],
                    "Benchmark Energy Loss (kWh)"
                  )}
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getValue(benchmarkLossKWH["Value"])}</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card pr-card">
              <div className="inrCardHdr">
                <h5>
                  {" "}
                  {getName(actualLossKWH["Name"], "Actual Energy Loss (kWh)")}
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getValue(actualLossKWH["Value"])}</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card pr-card">
              <div className="inrCardHdr">
                <h5>
                  {" "}
                  {getName(benchmarkLossINR["Name"], "Benchmark Energy Loss")}
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">
                  {/* <i className="fa fa-rupee"></i>  */}
                  {getSymbol(benchmarkLossINR["Symbol"]) +
                    " " +
                    getValue(benchmarkLossINR["Value"])}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card pr-card">
              <div className="inrCardHdr">
                <h5>{getName(actualLossINR["Name"], "Actual Energy Loss")}</h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">
                  {/* <i className="fa fa-rupee"></i>  */}
                  {getSymbol(actualLossINR["Symbol"]) +
                    " " +
                    getValue(actualLossINR["Value"])}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card pr-card">
              <div className="inrCardHdr">
                <h5>
                  {getName(benchmarkLoss["Name"], "Benchmark Energy Loss %")}
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getValue(benchmarkLoss["Value"])}</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card pr-card">
              <div className="inrCardHdr">
                <h5>{getName(actualLoss["Name"], "Actual Energy Loss %")}</h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getValue(actualLoss["Value"])}</h3>
              </div>
            </div>
          </div>
        </div>

        <EnergyLossChart chartData={chartData} />
      </div>
    </>
  );
}
export default EnergyLossBlockes;
