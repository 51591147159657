import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const InsightsSkeleton = () => {
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content">
          <div className="insightPg container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  {/* Breadcrumb Skeleton */}
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Skeleton variant="text" width={150} height={30} />
                      </li>
                      <li className="breadcrumb-item">
                        <Skeleton variant="text" width={150} height={30} />
                      </li>
                      <li className="breadcrumb-item">
                        <Skeleton variant="text" width={300} height={30} />
                      </li>
                    </ol>
                  </div>

                  <div className="mdlContent card-body">
                    <div className="row">
                      {/* Average Efficiency vs Load Band Skeleton */}
                      <div className="loadBar col-md-5 col-lg-5">
                        <div className="card pr-card mb30">
                          <div className="inrCardHdr">
                            <Skeleton variant="text" width={250} height={30} />
                          </div>
                          <div className="inrCardBody">
                            <Skeleton variant="rectangular" width="100%" height={250} />
                          </div>
                        </div>
                      </div>

                      {/* Load Profile Skeleton */}
                      <div className="loadProfile col-md-4 col-lg-4">
                        <div className="card pr-card mb30">
                          <div className="inrCardHdr">
                            <Skeleton variant="text" width={150} height={30} />
                          </div>
                          <div className="inrCardBody">
                            <Skeleton variant="rectangular" width="100%" height={250} />
                          </div>
                        </div>
                      </div>

                      {/* Relay Operated Count Skeleton */}
                      <div className="relayCnt col-md-3 col-lg-3">
                        <div className="card pr-card mb30">
                          <div className="inrCardHdr text-center">
                            <Skeleton variant="text" width={200} height={30} />
                          </div>
                          <div className="inrCardBody">
                            <Skeleton variant="rectangular" width="100%" height={150} />
                          </div>
                        </div>

                        {/* OLTC Operated Count Skeleton */}
                        <div className="oltcCnt card pr-card mb30">
                          <div className="text-center inrCardHdr">
                            <Skeleton variant="text" width={200} height={30} />
                          </div>
                          <div className="inrCardBody text-center">
                            <Skeleton variant="text" width={100} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* Current Unbalance Skeleton */}
                      <div className="col-md-12 col-lg-6 text-center">
                        <div className="card pr-card mb30 unbalaWpr">
                          <div className="unbalaMidl">
                            <div className="inrCardHdr">
                              <Skeleton variant="text" width={200} height={30} />
                            </div>
                            <div className="inrCardBody">
                              <div className="row text-center">
                                <div className="separator col-md-6 col-lg-6">
                                  <Skeleton variant="text" width={50} height={20} />
                                  <Skeleton variant="rectangular" width="100%" height={200} />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                  <Skeleton variant="text" width={50} height={20} />
                                  <Skeleton variant="rectangular" width="100%" height={200} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Voltage Unbalance Skeleton */}
                      <div className="col-md-12 col-lg-6 text-center">
                        <div className="card pr-card mb30 unbalaWpr">
                          <div className="unbalaMidl">
                            <div className="inrCardHdr">
                              <Skeleton variant="text" width={200} height={30} />
                            </div>
                            <div className="inrCardBody">
                              <div className="row text-center">
                                <div className="separator col-md-6 col-lg-6">
                                  <Skeleton variant="text" width={50} height={20} />
                                  <Skeleton variant="rectangular" width="100%" height={200} />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                  <Skeleton variant="text" width={50} height={20} />
                                  <Skeleton variant="rectangular" width="100%" height={200} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default InsightsSkeleton;
