import React, { useState } from "react";
import solutionContext from "./solutionContext";
const SolutionState = (props) => {
  const [solution_id, setSolution_id] = useState(null);
  const update_solution = (props) => {
    setSolution_id(props);
  };
  return (
    <solutionContext.Provider value={{ solution_id, update_solution }}>
      {props.children}
    </solutionContext.Provider>
  );
};
export default SolutionState;
