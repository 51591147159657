/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import "./UMHC.css"

function UMHC(props) {
  const { data } = props
  const truncateToThreeDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = parseFloat(Number(value).toFixed(3));
      return isNaN(truncatedValue) ? '-' : truncatedValue;
    }
    return '';
  };

  const location = JSON.parse(localStorage.getItem('location'));
  const asset = JSON.parse(localStorage.getItem('asset'));

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timePeriod = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour format to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the time as hh:mm AM/PM
    const timeString = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${timePeriod}`;

    return `${day} ${month} ${year} ${timeString}`;
  }

  // date format for breadcrums
  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  return (


    <>
      {/* <div className="wrapper"> */}
      {/* <!-- Content Wrapper. Contains page content --> */}
      {/* <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="mdlContent umhc card pr-card mb30">
                    <div className="breadcrumbHdr card-header">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a >{asset.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a >{location.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a >
                            {formatDate(data.sensor_data.datetime)}
                          </a>
                        </li>
                      </ol>
                    </div>
                    <div className="mdlContent card-body"> */}
      <h4 className='text-center mb20 boldText'>
        {/* UMHC */}
      </h4>
      <div className="newtree newspace">

        <ul>

          <li>
            <span className='newarrow2'></span>
            <span className='newarrow3'></span>
            <span className='newarrow4'></span>
            <span className='newarrow5'></span>
            <span className='newarrow6'></span>
            <span className='newarrow7'></span>
            <span className='newarrow8'></span>
            <span className='newarrow9'></span>
            {/* <span className='newthreearrow10'></span> */}
            {/* <span className='newthreearrow11'></span> */}
            {/* <span className='newarrow12'></span> */}
            {/* <span className='newarrow13'></span> */}
            <div className='newleftupL3'>  </div>
            <div className='newleftupL4'>  </div>
            <div className='newleftupL5'>  </div>
            <span className='newrighttext'>{truncateToThreeDigits(data?.sensor_data['Global_Horizontal_Solar_Irradiance_Watt/sq_meter'] ?? `-`)} W/sq.m</span>
            <span className='newrighttext2'>{truncateToThreeDigits(data?.sensor_data['PV_Panel_Voltage_V_(System_1)'] ?? UMHC`-`)} V</span>
            <span className='newrighttext3'>V</span>
            <span className='newrighttext4'>{truncateToThreeDigits(data?.sensor_data['Inverter_Input_Voltage_V_(System_1)'] ?? "-")} v</span>

            {/* lefttop */}
            <span className='newrighttext5'>{truncateToThreeDigits(data?.sensor_data['Grid_Input_Voltage_V_(R_Phase)'] ?? "-")} v</span>
            <span className='newrighttext6'>{truncateToThreeDigits(data?.sensor_data['Grid_Input_Voltage_V_(Y_Phase)'] ?? "-")} v</span>
            <span className='newrighttext7'>{truncateToThreeDigits(data?.sensor_data['Grid_Input_Voltage_V_(B_Phase)'] ?? "-")} v</span>

            <div className='newmainTitle'><a href="#">Battery</a></div>

            <ul className='newline'>

              <li>

                <ul>
                  <span className='newtoptext '>Charging {truncateToThreeDigits(data?.sensor_data['Battery_Charging_Voltage_V_(System_1)'] ?? ` - - - - `)} V </span>
                  <a className='newleftTitle newdownLine bigDataWidth' href="#">
                    {truncateToThreeDigits(data?.sensor_data?.['Battery_Charging_Power_kW_(System_1)'] ?? "-")}
                  </a>
                  &nbsp;&nbsp;&nbsp;

                  <a className='newrightTitle newdownLine bigDataWidth' href="#">
                    {truncateToThreeDigits(data?.sensor_data?.['Battery_Discharging_Power_kW_(System_1)'] ?? "-")}
                  </a> <span className='newtoptext2'>Discharging {truncateToThreeDigits(data?.sensor_data['Battery_Discharging_Voltage_V_(System_1)'] ?? ` - - - - `)} V</span>
                  <ul>
                    <a className='newrightLine newdownLine' href="#">Solar PCU</a>
                    <div className='newleftLine newarrow bigDataWidth'>{truncateToThreeDigits(data?.sensor_data['Inverter_Input_Active_Power_kW_(System_1)'] ?? `-`)}</div>

                    {/* <div className='newthreep1 ' >0.01</div>
                        <div className='newthreep2 ' >0.02</div>
                        <div className='newthreep3 ' >0.03</div>

                        <div className='newleftup1' >Main Control Switch</div>

                        <div className='newleftupL3'>  </div>
                        <div className='newleftupL4'>  </div>
                        <div className='newthreePleftupR' >Grid<br />Input</div>
                        <div className='newthreePleftupL'>Other<br />Input</div>
                        <div className='newthreePleftupL2'>  </div>
                        <div className='newleftLine3' href="#">0.02</div>
                        <div className='newleftLine4' href="#">Distribution Box</div>
                        <div className='newleftLine5' href="#">0.02</div>
                        <div className='newleftLine6' href="#">Non<br />Solar<br />Loads</div> */}

                    <div className='newrightbox' href="#">Solar Panel</div>
                    <a className='newrightbox2 bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['PV_Panel_Power_kW_(System_1)'] ?? `-`)}</a>
                    <div className='newrightbox3 bigDataWidth' href="#"> {truncateToThreeDigits(data?.sensor_data.Potential_Solar_Generation_kW ?? "-")}</div>
                    <ul>
                      <a className='newm1 newdownLine bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Inverter_Output_Active_Power_kW_(System_1)'] ?? `-`)}</a><span className='newstext newmspace'>{truncateToThreeDigits(data?.sensor_data['Inverter_Output_Voltage_V_(System_1)'] ?? `-`)} v</span>

                      <ul>
                        <a className='newm2 newdownLine newdownLine' href="#">Changeover Switch</a>
                        <ul>

                          <a className='newm1 newdownLine2 bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Change_Over_Output_Active_Power_kW_(System_1)'] ?? `-`)}</a><span className='newstext2 newmspace'> {truncateToThreeDigits(data?.sensor_data['Change_Over_Output_Voltage_V_(System_1)'] ?? `-`)} v</span>
                          <ul>

                            <a className='newm2 newdownLine3' href="#">Distribution Box</a>
                            <ul>

                              <a className='newm4 newdownLine bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Change_Over_Output_Active_Power_kW_(System_1)'] ?? `-`)}</a>
                            </ul>


                            <a className='newm3' href="#">Solar <br />Loads</a>


                          </ul>
                        </ul>
                      </ul>

                    </ul>

                  </ul>

                </ul>

              </li>

            </ul>
          </li>
        </ul>

      </div>
      &nbsp;&nbsp;&nbsp;
      <div className="new2tree new2space">

        <ul>

          <li>
            <span className='new2arrow2'></span>
            <span className='new2arrow3'></span>
            <span className='new2arrow4'></span>
            <span className='new2arrow5'></span>
            <span className='new2arrow6'></span>
            <span className='new2arrow7'></span>
            <span className='new2arrow8'></span>
            <span className='new2arrow9'></span>
            <span className='new2threearrow10'></span>
            <span className='new2threearrow11'></span>
            <span className='new2arrow12'></span>
            <span className='new2arrow13'></span>

            <span className='new2righttext'>{truncateToThreeDigits(data?.sensor_data['Global_Horizontal_Solar_Irradiance_Watt/sq_meter'] ?? `-`)} W/sq.m</span>
            <span className='new2righttext2'>{truncateToThreeDigits(data?.sensor_data['PV_Panel_Voltage_V_(System_2)'] ?? "-")} V</span>
            <span className='new2righttext4'>{truncateToThreeDigits(data?.sensor_data['Inverter_Input_Voltage_V_(System_2)'] ?? "-")} V</span>
            <span className='new2righttext3'>V</span>

            <div className='new2mainTitle'><a href="#">Battery</a></div>

            <ul className='new2line'>

              <li>

                <ul>
                  <span className='new2toptext'>Charging {truncateToThreeDigits(data?.sensor_data['Battery_Charging_Voltage_V_(System_2)'] ?? ` - - - - `)} V</span> <a className='new2leftTitle new2downLine bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Battery_Charging_Power_kW_(System_2)'] ?? "-")}</a>
                  &nbsp;&nbsp;&nbsp;
                  <a className='new2rightTitle new2downLine bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Battery_Discharging_Power_kW_(System_2)'] ?? "-")}</a> <span className='new2toptext2'>Discharging {truncateToThreeDigits(data?.sensor_data['Battery_Discharging_Voltage_V_(System_2)'] ?? ` - - - - `)} </span>
                  <ul>
                    <a className='new2rightLine new2downLine' href="#">Solar PCU</a>
                    <div className='new2leftLine new2arrow bigDataWidth'>{truncateToThreeDigits(data?.sensor_data['Inverter_Input_Active_Power_kW_(System_2)'] ?? "-")}</div>
                    <div className='new2threep1 smallDataWidth' >{truncateToThreeDigits(data?.sensor_data['Grid_Input_Active_Power_kW_(R_Phase)'] ?? "-")}</div>
                    <div className='new2threep2 smallDataWidth' >{truncateToThreeDigits(data?.sensor_data['Grid_Input_Active_Power_kW_(Y_Phase)'] ?? "-")}</div>
                    <div className='new2three4 smallDataWidth'>{truncateToThreeDigits(data?.sensor_data['Grid_Input_Active_Power_kW_(B_Phase)'] ?? "-")}</div>
                    <div className='new2threep3'> </div>


                    <div className='new2leftup1' >Main Control Switch</div>

                    <div className='new2leftupL3'>  </div>
                    <div className='new2leftupL4'>  </div>
                    <div className='new2leftupL5'>  </div>
                    <div className='new2threePleftupR' >Grid Input</div>
                    <div className='new2threePleftupL'>Other Input</div>
                    <div className='new2threePleftupL2 bigDataWidth'>  </div>
                    <div className='new2leftLine3 bigDataWidth' href="#">
                      {truncateToThreeDigits(data?.sensor_data.Total_Non_Solar_load_Active_Power_kW ?? "-")}
                    </div>
                    <div className='new2leftLine4' href="#">Distribution Box</div>
                    <div className='new2leftLine5 bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Total_Non_Solar_load_Active_Power_kW ?? "-")}</div>
                    <div className='new2leftLine6' href="#">Non<br />Solar<br />Loads</div>

                    <div className='new2rightbox' href="#">Solar Panel</div>
                    <a className='new2rightbox2 bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['PV_Panel_Power_kW_(System_2)'] ?? "-")}</a>
                    <div className='new2rightbox3 bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Potential_Solar_Generation_kW ?? "-")}</div>
                    <ul>
                      <a className='new2m1 new2downLine bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Inverter_Output_Active_Power_kW_(System_2)'] ?? "-")}</a><span className='new2stext new2mspace'> {truncateToThreeDigits(data?.sensor_data['Inverter_Output_Voltage_V_(System_2)'] ?? "-")} v</span>

                      <ul>
                        <a className='new2m2 new2downLine new2downLine' href="#">Changeover Switch</a>
                        <ul>

                          <a className='new2m1 new2downLine bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Change_Over_Output_Active_Power_kW_(System_2)'] ?? "-")}</a><span className='new2stext new2mspace'> {truncateToThreeDigits(data?.sensor_data['Change_Over_Output_Voltage_V_(System_2)'] ?? "-")} v</span>
                          <ul>

                            <a className='new2m2 new2downLine' href="#">Distribution Box</a>
                            <ul>

                              <a className='new2m4 new2downLine bigDataWidth' href="#">{truncateToThreeDigits(data?.sensor_data['Change_Over_Output_Active_Power_kW_(System_2)'] ?? "-")}</a>
                            </ul>


                            <a className='new2m3' href="#">Solar <br />Loads</a>


                          </ul>
                        </ul>
                      </ul>

                    </ul>

                  </ul>

                </ul>

              </li>

            </ul>
          </li>
        </ul>
      </div>
      {/* </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}

    </>
  )
}

export default UMHC
