/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Table = (props) => {
    const { allParams, changeAllParams, searchText, setSearchText, filterTableData, tableData } = props
    const fileType = "text/csv;charset=utf-8";
    const fileExtension = ".csv";
    
    const exportToCSV = () => {
        const customHeadings = filterTableData.columns.map((column) => column.name);
        const filteredDataExcel = filterTableData.data.map((row) => {
            row = Object.values(row);
            const filteredItem = {};
            row.forEach((item, index) => {
                const heading = customHeadings[index];
                filteredItem[heading] = item;
            });
            return filteredItem;
        });
        const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
        const csvData = XLSX.utils.sheet_to_csv(ws);
        const dataBlob = new Blob([csvData], { type: fileType });
        FileSaver.saveAs(dataBlob, "Trend Data" + fileExtension);
    };
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL",
    };

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                whiteSpace: 'normal',// Custom font weight for the table header
            },
        },
        headRows: {
            style: {
                whiteSpace: 'normal',
            },
        }
    };
    // no data available columns
    const NoDataComponent = () => (
        <>
            {/* {tableData.length > 0 && ( */}
            <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        {tableData.columns.map((column) => (
                            <th key={column.name}>{column.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={tableData.columns.length}>
                            <div className="text-center">
                                <p>No Data Available</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* )} */}

        </>
    );
    return (
        <>
            <div className="col-md-12 col-lg-12">
                <div className="card pr-card mb30 tableCard">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="form-inline dataTables_filter">
                                    <label className="mr10">
                                        <input type="checkbox" name=""
                                            checked={allParams}
                                            onChange={(e) => changeAllParams(e.target.checked)}
                                        // onClick={changeAllParams(allParams)}
                                        /> All
                                    </label>

                                    <div id="" className="mr10">
                                        <label>
                                            <input
                                                type="search"
                                                value={searchText}
                                                className="form-control form-control-sm"
                                                placeholder="Search..."
                                                onChange={(e) => {
                                                    setSearchText(e.target.value);
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <div className="input-group pr-form-group" >
                                        <a className="" alt="Download XLS" onClick={exportToCSV}>
                                            <i className="fa fa-file-excel-o" ></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body' >
                        {tableData && tableData.columns && tableData.data && (
                            <DataTable
                                columns={filterTableData.columns}
                                data={filterTableData.data}
                                pagination
                                fixedHeader
                                paginationComponentOptions={paginationComponentOptions}
                                // progressPending={data_table_loading}
                                defaultSortField="date_time" // Set the default sort field to 'id'
                                defaultSortAsc={true}
                                noDataComponent={<NoDataComponent />}
                                customStyles={customStyles}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table