import React, { useEffect, useState, useContext } from "react";
import Sidebar from "./Sidebar";
import moment from "moment";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import solutionContext from "../../context/solution/solutionContext";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import MainContent from "./Route_map/MainContent";
import MapLoad from "./Route_map/MapLoad";

import { Alert } from "react-bootstrap";
import CableRouteSkeleton from "./CableRouteSkeleton";

function RouteMap() {
  const [locationData, setLocationData] = useState([]);
  const [cableTypeData, setCableTypeData] = useState([]);
  const [cableData, setCableData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCableType, setSelectedCableType] = useState("");
  const [selectedCable, setSelectedCable] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadCableType, setOnloadCableType] = useState("");
  const [onloadCable, setOnloadCable] = useState("");
  const [token, setToken] = useState("");
  const [vehical_id, setVehicalId] = useState("");
  const [location1, setLocation1] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [filteredDataTableData, setFilteredDataTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [originalDataTableData, setOriginalDataTableData] = useState([]);
  const [cableType, setCableType] = useState("");
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [onload, setOnLoad] = useState(false);
  const params = new URLSearchParams(useLocation().search);
  const param_type = params.get("tp");
  const param_location = params.get("lc");
  const [locations, setLocations] = useState([]); // State for locations
  const [faults, setFaults] = useState([]); // State for 
  const [joints, setJoints] = useState([]); // State for joints
  const [device_count, setDeviceCount] = useState({
    grey: "-",
    red: "-",
    green: "-",
  });
  const [onloadStartDate, setOnloadStartDate] = useState("");
  const [onloadEndDate, setOnloadEndDate] = useState("");
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [routeMap, setRouteMap] = useState({});
  function formatDate(date) {
    // Subtract 5 hours and 30 minutes
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageCableType();
    }
  }, [cableTypeData]);
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageCable();
    }
  }, [cableData]);

  useEffect(() => {
    if (onload && onloadLoc && onloadCableType && onloadCable) {
      const data = {
        location: onloadLoc,
        type: onloadCableType,
        cable_id: onloadCable,
      };
      handleSubmitData(data);
    }
  }, [onload, onloadLoc, onloadCableType, onloadCable]);
  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);

      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        setLocation1(location.value);
        getCableType(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("cableType");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location);
        setLocation1(new_location.value);
        getCableType(new_location.value);

        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageCableType = () => {
    if (
      localStorage.getItem("cableType") !== undefined &&
      localStorage.getItem("cableType") !== "undefined" &&
      localStorage.getItem("cableType") !== "null" &&
      localStorage.getItem("cableType") !== null
    ) {
      let cableType = localStorage.getItem("cableType");
      cableType = JSON.parse(cableType);
      const flag = cableTypeData.some((item) => item.value === cableType.value);
      if (flag) {
        setSelectedCableType(cableType);
        setOnloadCableType(cableType);
        setCableType(cableType.value);
        getCable(cableType.value);
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      localStorage.removeItem("cable");
      if (cableTypeData.length > 0) {
        const new_cableType = cableTypeData[0];

        // setSelectedAsset(new_asset);
        setOnloadCableType(new_cableType);
        setCableType(new_cableType.value);
        getCable(new_cableType.value);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageCable = () => {
    const cable = localStorage.getItem("cable");
    if (cable) {
      const parsedCable = JSON.parse(cable);
      const flag = cableData.some((item) => item.value === parsedCable.value);
      if (flag) {
        setSelectedCable(parsedCable);
        setOnloadCable(parsedCable);
        setOnLoad(true);
      }
    } else if (cableData.length > 0) {
      const new_cable = cableData[0];
      setOnloadCable(new_cable);
      setOnLoad(true);
    } else {
      showFlashMessage(
        "Please select Location, Cable Type and Cable to display data."
      );
    }
  };

  const changeLocation = (props) => {
    localStorage.removeItem("location");
    localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);

    setOnloadLoc(props);
    setLocation1(props.value);
    if (token !== null && token !== undefined && token !== "") {
      getCableType(props.value);
    }
  };
  const changeCableType = (props) => {
    localStorage.removeItem("cableType");
    localStorage.setItem("cableType", JSON.stringify(props));
    setOnloadCableType(props);
    setCableType(props.value);
    if (token !== null && token !== undefined && token !== "") {
      getCable(props.value);
    }
  };
  const changeCable = (props) => {
    localStorage.removeItem("cable");
    localStorage.setItem("cable", JSON.stringify(props));
    setOnloadCable(props);
    if (token !== null && token !== undefined && token !== "") {
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/van_details/get_cable_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item,
              label: item,
            }));
            // .sort((a, b) =>
            //   a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getCableType = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/van_details/get_cable_type`,
            {
              access_token: token,
              location: props,
              user_log_id: userLogId,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item,
              label: item,
            }));

            setCableTypeData(data);
            success = true;
            if (response.data.data.length === 0) {
              setCableTypeData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setCableTypeData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setCableTypeData([]);
      showFlashMessage("Cable Type are Not Found");
    }
  };
  const getCable = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/van_details/get_route_cable`,
            {
              access_token: token,
              location: location1,
              type: props,
              user_log_id: userLogId,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item,
              label: item,
            }));

            setCableData(data);

            success = true;
            if (response.data.data.length === 0) {
              setCableData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setCableData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setCableData([]);
      showFlashMessage("Cable are Not Found");
    }
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDate;
  };
  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);
  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };
  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  const truncateToOneDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = Math.trunc(value * 10) / 10;
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "";
  };
  const handleSubmitData = (props) => {
    const { fromDate, toDate, location, type, cable_id, group } = props;
    // setHeader({location: location.label, asset: site.label});
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("cableType", JSON.stringify(onloadCableType));
    localStorage.setItem("cable", JSON.stringify(onloadCable));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedType = JSON.parse(localStorage.getItem("cableType"));
    const updatedType = storedType || type;
    const storedCable = JSON.parse(localStorage.getItem("cable"));
    const updatedCable = storedCable || cable_id;
    setSelectedLocation(updatedLocation);
    setSelectedCableType(updatedType);
    setSelectedCable(updatedCable);

    setOnloadLoc(location);
    setOnloadCableType(type);
    setOnloadCable(cable_id);

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        location: location.value,
        cable_id: cable_id.value,
        type: type.value,
      };

      getRouteMapData(data);

      try {
        data = Object.assign({}, data);

        getRouteMapData(data);
      } catch (error) {}
    }
  };

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, type, cable_id } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("cableType", JSON.stringify(onloadCableType));
    localStorage.setItem("cable", JSON.stringify(onloadCable));
    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedType = JSON.parse(localStorage.getItem("cableType"));
    const updatedType = storedType || type;
    const storedCable = JSON.parse(localStorage.getItem("cable"));
    const updatedCable = storedCable || cable_id;

    setSelectedLocation(updatedLocation);
    setSelectedCableType(updatedType);
    setSelectedCable(updatedCable);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location: onloadLoc.value,
        type: onloadCableType.value,
        cable_id: onloadCable.value,
      };
      try {
        getRouteMapData(data);
        // getBusbarParametersTable(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };
  const getRouteMapData = async (props) => {
    const requestData = props;
    setIsLoading(true);
    if (token !== null && token !== undefined && token !== "") {
      await RouteMapData(
        "iotrms/van_details/get_route_data",
        setRouteMap,
        requestData,
        "Route Map"
      );
      setIsLoading(false);
    }
  };
  const RouteMapData = async (url, setData, requestData, errMSG) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    setLocations([]);
    setFaults([]);
    setJoints([]);
    setFilteredDataTableData([]);
    setOriginalDataTableData([]);
    while (attempts < maxAttempts && !success) {
      // setIsLoading(true);
      if (requestData.start_date && requestData.end_date) {
        setOnloadStartDate(breadcrum_date_converter(requestData.start_date));
        setOnloadEndDate(breadcrum_date_converter(requestData.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/${url}`,
          requestData
        );
        if (response.data.success === true) {
          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          }

          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          } else {
            setOnloadStartDate("");
            setOnloadEndDate("");
          }
          setData(response.data.data[0]);
          const result = response.data.data[0].routes;
          const faults = response.data.data[0].faults;
          const joints =response.data.data[0].joints;
          console.log("join data :",joints)

         
          setLocations(result);
          setFaults(faults);
          setJoints(joints);
        
          success = true;
        } else {
          setIsLoading(false);

          setRouteMap(response.data.data[0]);

          success = true;
       
        }
      } catch (error) {
      
        setIsLoading(false);
        setRouteMap([]);
        setFilteredDataTableData([]);
        setOriginalDataTableData([]);
        setLocations([]);
        setFaults([]);
        setJoints([])
      
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(`Data Not Available For ${errMSG}`);
    }
  };
  const formatDateInTable = (dateString) => {
    const date = new Date(dateString);

    const pad = (num) => (num < 10 ? "0" + num : num);

    const day = pad(date.getUTCDate());
    const month = pad(date.getUTCMonth() + 1);
    const year = date.getUTCFullYear();

    // const hours = pad(date.getUTCHours());
    // const minutes = pad(date.getUTCMinutes());
    // const seconds = pad(date.getUTCSeconds());

    return `${day}-${month}-${year} `;
  };
  const tableColumns = [
    {
      name: "Fault Date",
      selector: (row) => row.fault_date,
      cell: (row) => formatDateInTable(row.fault_date),
      sortable: true,
    },
    {
      name: "Fault Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <td style={{ textAlign: "left" }}>
          <span
            className={
              row.status === "Resolved"
                ? "faultStatus faultGreen"
                : "faultStatus faultRed"
            }
          ></span>
          {row.status}
        </td>
      ),
      sortable: true,
    },
  ];
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Fault Date</th>
            <th>Fault Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={tableColumns.length}>
              <div className="text-center">
                <p>No Data Available</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal", // Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };
  const handleSearch = (searchText) => {
    // Convert search text to lowercase for case-insensitive comparison
    const searchTextLower = searchText.toLowerCase();

    // Filter the original data based on the search text
    const filteredData = routeMap?.faults.filter((item) => {
      return (
        formatDateInTable(item.fault_date)
          .toLowerCase()
          .includes(searchTextLower) ||
        item.description.toLowerCase().includes(searchTextLower) ||
        item.status.toLowerCase().includes(searchTextLower)
      );
    });

    // Update the filtered data state
    setRouteMap({ ...routeMap, faults: filteredData });
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const customHeadings = tableColumns.map((column) => column.name);
    const filteredDataExcel = [];

    routeMap?.faults.map((row) => {
      filteredDataExcel.push({
        "Fault Date": row.fault_date,
        "Fault Description": row.description,
        Status: row.status,
      });
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "Fault Data" + fileExtension);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <>
    {isLoading && <CableRouteSkeleton/>}
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item ">
                        <a href="#">{location1}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">{cableType}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">{onloadCable.value}</a>
                      </li>
                    </ol>
                  </div>
                  <div className="mdlContent card-body">
                    <div className="col-md-12 col-lg-12">
                      <div className="infoCard card pr-card mb30">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="row">
                                <div className="col-sm-6">
                                  <p>
                                    <b>Vehicle Id</b>
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    {" "}
                                    :{" "}
                                    {routeMap !== undefined &&
                                    Object.keys(routeMap).length !== 0
                                      ? routeMap.cable.cable_id.replace(
                                          /_\d+$/,
                                          ""
                                        )
                                      : "-"}
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    <b>Length</b>
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    :{" "}
                                    {routeMap !== undefined &&
                                    Object.keys(routeMap).length !== 0
                                      ? routeMap.cable.length
                                      : "-"}
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    <b>Make</b>
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    :{" "}
                                    {routeMap !== undefined &&
                                    Object.keys(routeMap).length !== 0
                                      ? routeMap.cable.make
                                      : "-"}
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    <b>Description</b>
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    :{" "}
                                    {routeMap !== undefined &&
                                    Object.keys(routeMap).length !== 0
                                      ? routeMap.cable.description
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="row">
                                <div className="col-sm-6">
                                  <p>
                                    <b>Cable Id</b>
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    :{" "}
                                    {routeMap !== undefined &&
                                    Object.keys(routeMap).length !== 0
                                      ? routeMap.cable.cable_id
                                      : "-"}
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    <b>Type</b>
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    :{" "}
                                    {routeMap !== undefined &&
                                    Object.keys(routeMap).length !== 0
                                      ? routeMap.cable.type
                                      : "-"}
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    <b>Rating</b>
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <p>
                                    :{" "}
                                    {routeMap !== undefined &&
                                    Object.keys(routeMap).length !== 0
                                      ? routeMap.cable.rating
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="map col-md-12 col-lg-12">
                      <div className="card pr-card mb30">
                        <div className="card-body">
                          <div className="row">
                            <MapLoad
                              zoomcntr={true}
                              gesture=""
                              mapType={true}
                              locations={locations}
                              faults={faults}
                              joints={joints}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                      <div className="card pr-card mb30">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                              <div className="dataTables_length" id=""></div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                              <div className="form-inline dataTables_filter">
                                <div className="mr10">
                                  <label>
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder="Search..."
                                      value={searchText}
                                      onChange={(e) => {
                                        const searchText = e.target.value;
                                        setSearchText(searchText);
                                        // Call search function with updated search text
                                        handleSearch(searchText);
                                      }}
                                    />
                                  </label>
                                </div>
                                <div
                                  className="input-group pr-form-group"
                                  id=""
                                >
                                  <a
                                    className=""
                                    alt="Download XLS"
                                    onClick={exportToCSV}
                                  >
                                    <i className="fa fa-file-excel-o"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <DataTable
                            columns={tableColumns}
                            // data={filteredDataTableData}
                            data={routeMap?.faults}
                            pagination
                            fixedHeader
                            paginationComponentOptions={
                              paginationComponentOptions
                            }
                            noDataComponent={<NoDataComponent />}
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Sidebar
        locationData={locationData}
        cableTypeData={cableTypeData}
        cableData={cableData}
        changeLocation={changeLocation}
        changeCableType={changeCableType}
        changeCable={changeCable}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedCableType={selectedCableType}
        selectedCable={selectedCable}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default RouteMap;
