import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const PowerFactorSkeleton = () => {
  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                {/* Breadcrumb Skeleton */}
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={150} height={30} />
                    </li>
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={150} height={30} />
                    </li>
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={300} height={30} />
                    </li>
                  </ol>
                </div>

                <div className="pwrFtrPg mdlContent card-body">
                  <div className="row">
                    {/* Average HV Power Factor Skeleton */}
                    <div className="col-md-6 col-lg-6">
                      <div className="card pr-card mb20">
                        <div className="inrCardBody text-center">
                          <div className="avgBox powerFctBox">
                            <div className="inrCardHdr">
                              <Skeleton variant="text" width={100} height={30} />
                            </div>
                            <div className="inrCardBody text-center">
                              <Skeleton variant="text" width={100} height={50} />
                              <Skeleton variant="text" width={100} height={30} />
                            </div>
                          </div>

                          {/* Max/Min HV Power Factor Skeleton */}
                          <div className="row mb20">
                            {Array.from(new Array(3)).map((_, index) => (
                              <div className="col-md-4" key={index}>
                                <div className="powerFctBox text-center">
                                  <Skeleton variant="text" width={100} height={30} />
                                  <Skeleton variant="text" width={100} height={50} />
                                  <Skeleton variant="text" width={100} height={30} />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="row">
                            {Array.from(new Array(3)).map((_, index) => (
                              <div className="col-md-4" key={index}>
                                <div className="powerFctBox ">
                                  <Skeleton variant="text" width={100} height={30} />
                                  <Skeleton variant="text" width={100} height={50} />
                                  <Skeleton variant="text" width={100} height={30} />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Average LV Power Factor Skeleton */}
                    <div className="col-md-6 col-lg-6">
                      <div className="card pr-card mb20">
                        <div className="inrCardBody text-center">
                          <div className="avgBox powerFctBox">
                            <div className="inrCardHdr">
                              <Skeleton variant="text" width={100} height={30} />
                            </div>
                            <div className="inrCardBody text-center">
                              <Skeleton variant="text" width={100} height={50} />
                              <Skeleton variant="text" width={100} height={30} />
                            </div>
                          </div>

                          {/* Max/Min LV Power Factor Skeleton */}
                          <div className="row mb20">
                            {Array.from(new Array(3)).map((_, index) => (
                              <div className="col-md-4" key={index}>
                                <div className="powerFctBox powerfactor Skeleton">
                                  <Skeleton variant="text" width={100} height={30} />
                                  <Skeleton variant="text" width={100} height={50} />
                                  <Skeleton variant="text" width={100} height={30} />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="row">
                            {Array.from(new Array(3)).map((_, index) => (
                              <div className="col-md-4" key={index}>
                                <div className="powerFctBox text-center">
                                  <Skeleton variant="text" width={100} height={30} />
                                  <Skeleton variant="text" width={100} height={50} />
                                  <Skeleton variant="text" width={100} height={30} />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PowerFactorSkeleton;
