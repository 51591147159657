/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import moment from 'moment';
import Table from "./Table"
import EnergySummarySkeleton from "../../Ems/EnergySummary/EnergySummarySkeleton";


function EnergyConsum() {
  const [pieData, setpieData] = useState([]);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("")
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [energyData, setenergyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pielabels, setPielabels] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onload, setOnLoad] = useState(false);
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [fromDate, setFromDate] = useState(new Date(moment(new Date()).subtract(1, 'days')));
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [header, setHeader] = useState({ location: "", asset: "" });
  const [searchText, setSearchText] = useState("");
  const [filterTableData, setFilterTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) {

      }

    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId)
    }
  }, []);

  useEffect(() => {
    searchTable();
  }, [searchText])

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location)
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      }
      else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };
  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    }
    else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset)
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      }
      else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };
  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };
  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "SELCO"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          }
          else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "SELCO"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          }
          else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };
  const getDatableData = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let newData = null;

    while (attempts < maxAttempts && !success) {
      const data = props;
      setIsLoading(true)
      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {
        if (token !== null && token !== undefined && token !== '') {
          const response = await axios.post(`${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/daily_summary_table`, data);
          const newData = formatTableData(response.data.data);
          if (response.status === 200 && response.data.success === true) {
            setIsLoading(false)
            setTableData(newData)
            success = true;
            setFilterTableData(newData);
            if (response.data.start_date && response.data.end_date) {
              setNewStartDate(response.data.start_date);
              setNewEndDate(response.data.end_date);
            }
          } else {
            setIsLoading(false)
            setTableData(newData)
            setFilterTableData(newData);
            // showFlashMessage("Data Not Available for Energy Parameters", "Danger");
          }
        }
      } catch (error) {
        setIsLoading(false)
        showFlashMessage(error);
      }
      attempts++;
      setIsLoading(false)
    }
    if (!success) {
      setIsLoading(false)
      showFlashMessage("Data Not Available for Energy Parameters", "Danger");
    }
  }

  const convertDate = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const convertDateClone = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }

  const handleSubmitData = (props) => {
    const { location, site } = props;

    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location)
    setOnloadAsset(site)

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // start_date: convertDateCloneApi(fromDate),
        // end_date: convertDateCloneApi(toDate),
        location_id: location.value,
        assset_id: site.value,
      };
      // energy_consumption_data(data);
      getDatableData(data);
    }
  };

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        // iot_sol: "SELCO"
      };
      try {
        await getDatableData(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  }

  const formatTableData = (props) => {
    const responseData = props;
    const fields = props.fields;
    const columns = fields.map((value, index) => {
      return {
        name: index === 0 ? "Date" : value.replace(/_/g, " "),
        selector: (row) => (index === 0 ? row.date_time : row[value.toLowerCase()]),
        sortable: true,
        cell: row => <div className="custom-cell">{(index === 0) ? row["date_time"] : row[value.toLowerCase()]}</div>
      };
    });
  
    const newData = responseData.data.map((row) => {
      const object = {};
      row.forEach((item, index) => {
        if (index === 0) {
          object["date_time"] = item; // Use the date as it is from the API
        } else if (index === 13) {
          object[fields[index].toLowerCase()] = item;
        } else {
          object[fields[index].toLowerCase()] = (item !== "-") ? item.toFixed(3) : "-";
        }
      });
      return object;
    });
  
    return { "columns": columns, "data": newData };
  }

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  if (isLoading) {
    return (
      <EnergySummarySkeleton/>
    );
  }

  // checkk if the data is empty
  const isPieDataEmpty = pieData.every((value) => value === 0);


 

  // date format for breadcrums
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const changeAllParams = (data) => {
    // setAllParams(data)
  }

  const searchTable = () => {
    if (searchText === undefined || searchText === null || searchText === '' || !searchText) {
      setFilterTableData(tableData);
    }
    else if (tableData && tableData.data) {
      const filteredResult = tableData.data.filter((row) => {
        row = Object.values(row);
        const rowValues = row.map((item) =>
          typeof item === "string" ? item.toLowerCase() : item.toString()
        );

        const searchTerm = searchText.toLowerCase();

        return rowValues.some((value) => value.includes(searchTerm));
      });

      setFilterTableData((prevOptions) => ({
        ...prevOptions,
        data: filteredResult
      }));
    }
  }


  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };

  return (
    <>
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
       
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              {/* <div className="content-header">
                
              </div> */}
              <div className="mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">

                    <li className="breadcrumb-item">
                      <a href="#">{selectedAsset.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">{selectedLocation.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">
                        {" "}
                        {newStartDate ? (
                          <a href="#">{newStartDate}</a>
                        ) : (
                          <a href="#">{formatDate(fromDate)}</a>
                        )}
                        &nbsp;-&nbsp;
                        {newEndDate ? (
                          <a href="#">{newEndDate}</a>
                        ) : (
                          <a href="#">{formatDate(toDate)}</a>
                        )}
                      </a>
                    </li>
                  </ol>
                </div>
                <div className="mdlContent card-body">
                  <Table searchText={searchText} setSearchText={setSearchText} filterTableData={filterTableData} tableData={tableData} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default EnergyConsum;
