import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function EmptyPage() {
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const handleCloseFirstModal = () => setShowFirstModal(false);
  const handleShowFirstModal = () => setShowFirstModal(true);

  const handleCloseSecondModal = () => setShowSecondModal(false);
  const handleShowSecondModal = () => setShowSecondModal(true);

  return (
    <>
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          <h3>EmptyPage</h3>
          <h1>There is no data on this page</h1>
          <Button variant="primary" onClick={handleShowFirstModal}>
            Launch modal
          </Button>

          {/* First Modal */}
          <Modal show={showFirstModal} onHide={handleCloseFirstModal}>
            <Modal.Header closeButton>
              <Modal.Title>Modal title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Content for the dialog / modal goes here.Content for the dialog / modal goes hereContent for the dialog / modal goes hereContent for the dialog / modal goes hereContent for the dialog / modal goes hereContent for the dialog / modal goes hereContent for the dialog / modal goes hereContent for the dialog / modal goes here <br/>
              Content for the dialog / modal goes here.
              <Button variant="primary" onClick={handleShowSecondModal}>
                Launch modal
              </Button>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-dark" onClick={handleCloseFirstModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Second Modal */}
          <Modal show={showSecondModal} onHide={handleCloseSecondModal}>
            <Modal.Header closeButton>
              <Modal.Title>2nd Modal title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Content for the dialog / modal goes here.
              Content for the dialog / modal goes here.
              Content for the dialog / modal goes here.
              Content for the dialog / modal goes here.
              Content for the dialog / modal goes here.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-dark" onClick={handleCloseSecondModal}>
                Close
              </Button>
              <Button variant="primary">Save changes</Button>
            </Modal.Footer>
          </Modal>
        </div>
        <style>{/* Your custom styles here */}</style>
      </div>
    </>
  );
}

export default EmptyPage;
