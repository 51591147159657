import EnergyChart from "./EnergyChart";

function EnergyBlockes({
  benchmarkKWH,
  actualKWH,
  benchmarkINR,
  actualINR,
  productivityLoss,
  requestData,
  chartData,
}) {
  const getName = (data, title) => {
    return data ? data : title;
  };

  const getValue = (data) => {
    return data ? data.toFixed(1) : "-";
  };
  const getSymbol = (data) => {
    return data ? data : "";
  };
  return (
    <>
      <div className="enryPg mdlContent card-body">
        <div className="row cards">
          <div className="col-md">
            <div className="card pr-card text-center">
              <div className="inrCardHdr">
                <h5>
                  Benchmark Energy Transfer Capacity (kWh)
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getValue(benchmarkKWH["Value"])}</h3>
              </div>
            </div>
          </div>
          <div className="col-md">
            <div className="card pr-card text-center">
              <div className="inrCardHdr">
                <h5>
                  Actual Energy Transferred (kWh)
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getValue(actualKWH["Value"])}</h3>
              </div>
            </div>
          </div>
          <div className="col-md">
            <div className="card pr-card text-center">
              <div className="inrCardHdr">
                <h5>
                  Benchmark Energy Transfer Capacity
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getSymbol(benchmarkINR["Symbol"]) +" "+ getValue(benchmarkINR["Value"])}</h3>
              </div>
            </div>
          </div>
          <div className="col-md">
            <div className="card pr-card text-center">
              <div className="inrCardHdr">
                <h5>
                  Actual Energy Transferred
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getSymbol(actualINR["Symbol"]) +" "+ getValue(actualINR["Value"])}</h3>
              </div>
            </div>
          </div>
          {/* <div className="col-md">
            <div className="card pr-card text-center">
              <div className="inrCardHdr">
                <h5>
                  {getName(productivityLoss["Name"], "Productivity Loss %")}
                </h5>
              </div>
              <div className="inrCardBody">
                <h3 className="mb0">{getValue(productivityLoss["Value"])}</h3>
              </div>
            </div>
          </div> */}
        </div>
        <EnergyChart chartData={chartData} />
      </div>
    </>
  );
}
export default EnergyBlockes;
