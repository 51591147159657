import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const EnergyDeliveredSkeleton = () => {
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="mdlContent card pr-card mb30">
              {/* Breadcrumb Skeleton */}
              <div className="breadcrumbHdr card-header">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Skeleton variant="text" width={150} height={30} />
                  </li>
                  <li className="breadcrumb-item">
                    <Skeleton variant="text" width={150} height={30} />
                  </li>
                  <li className="breadcrumb-item">
                    <Skeleton variant="text" width={300} height={30} />
                  </li>
                </ol>
              </div>

              {/* Energy Block Skeleton */}
              <div className="mdlContent card-body">
                <div className="row">
                  {/* Benchmark kWh Skeleton */}
                  <div className="col-md-6 col-lg-3">
                    <div className="card pr-card">
                      <div className="inrCardHdr">
                        <Skeleton variant="text" width={150} height={30} />
                      </div>
                      <div className="inrCardBody text-center">
                        <Skeleton variant="text" width={100} height={50} />
                      </div>
                    </div>
                  </div>

                  {/* Actual kWh Skeleton */}
                  <div className="col-md-6 col-lg-3">
                    <div className="card pr-card">
                      <div className="inrCardHdr">
                        <Skeleton variant="text" width={150} height={30} />
                      </div>
                      <div className="inrCardBody text-center">
                        <Skeleton variant="text" width={100} height={50} />
                      </div>
                    </div>
                  </div>

                  {/* Benchmark INR Skeleton */}
                  <div className="col-md-6 col-lg-3">
                    <div className="card pr-card">
                      <div className="inrCardHdr">
                        <Skeleton variant="text" width={150} height={30} />
                      </div>
                      <div className="inrCardBody text-center">
                        <Skeleton variant="text" width={100} height={50} />
                      </div>
                    </div>
                  </div>

                  {/* Actual INR Skeleton */}
                  <div className="col-md-6 col-lg-3">
                    <div className="card pr-card">
                      <div className="inrCardHdr">
                        <Skeleton variant="text" width={150} height={30} />
                      </div>
                      <div className="inrCardBody text-center">
                        <Skeleton variant="text" width={100} height={50} />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Chart Data Skeleton */}
                <div className="card pr-card mt30">
                  <div className="inrCardBody">
                    <Skeleton variant="rectangular" width="100%" height={400} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EnergyDeliveredSkeleton;
