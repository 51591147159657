/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import MainContent from "./MainContent/MainContent";
import { Link } from "react-router-dom";
import solutionContext from "../../context/solution/solutionContext";

const Summary = () => {
  // const { solution_id, update_solution } = useContext(solutionContext);

  // useEffect(() => {
  // }, [])

  return (
    <>
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <MainContent />
        </div>
      </div>
    </>
  );
};

export default Summary;
