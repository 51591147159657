import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

// Create a context for resetAxes
const ResetAxesContext = createContext();

// Create a provider component
export const ResetAxesProvider = ({ children }) => {
  const [resetAxes, setResetAxes] = useState(false);

  const handleResetAxes = () => {
    console.log("handleResetAxes called from context");
    setResetAxes((prevState) => !prevState);
  };

  return (
    <ResetAxesContext.Provider value={{ resetAxes, handleResetAxes }}>
      {children}
    </ResetAxesContext.Provider>
  );
};

// Create a custom hook to use the context
export const useResetAxes = () => {
  return useContext(ResetAxesContext);
};
