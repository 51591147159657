import React from "react";
import Chart from "react-apexcharts";

const LineChart = (props) => {
  const { chartSeries, chartOption } = props;
  //   debugger;

  const dummyChartData = {
    name: "Dummy Series",
    data: [
      { x: new Date("2023-01-01T00:00:00"), y: 10 },
      { x: new Date("2023-01-01T01:00:00"), y: 15 },
      { x: new Date("2023-01-01T02:00:00"), y: 8 },
      // Add more data points as needed
    ],
  };
  return (
    <>
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={chartOption}
              series={[chartSeries]}
              type="line"
              width="100%"
              height="200px"
              id=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LineChart;
