/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const Sidebar = (props) => {
  let {
    locationData,
    assetData,
    parameterData,
    changeLocation,
    changeAsset,
    changeParameter,
    handleSubmitData,
    selectedLocation,
    selectedAsset,
    selectedParameter,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    isParam,
    changeIsParam,
    groupData,
    handleAddGroup,
    getGroupName,
  } = props;
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [fromTime, setFromTime] = useState("00:00");
  const [fromHours, setFromHours] = useState(
    fromDate.getHours().toString().padStart(2, "0")
  );
  const [fromMinutes, setFromMinutes] = useState(
    fromDate.getMinutes().toString().padStart(2, "0")
  );
  const [fromSeconds, setFromSeconds] = useState("00");
  const [toTime, setToTime] = useState("00:00");
  const [toHours, setToHours] = useState(
    toDate.getHours().toString().padStart(2, "0")
  );
  const [toMinutes, setToMinutes] = useState(
    toDate.getMinutes().toString().padStart(2, "0")
  );
  const [toSeconds, setToSeconds] = useState("00");
  // Custom input component to disable manual typing
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="form-control"
      value={value}
      onClick={onClick}
      ref={ref}
      readOnly
    />
  ));
  const validationSchema = Yup.object().shape({
    // fromDate: Yup.date()
    // .required('From Date is required')
    // .max(new Date(moment(toDate).add(1, 'days')), 'From Date should be less than or equal to To Date')
    // .min(new Date(moment(fromDate).subtract(30, 'days')), 'From Date is exceeding the minimum From Date'),
    // .max(new Date(), 'From Date should be less than or equal to the current date'),
    // toDate: Yup.date()
    //   .required('To Date is required')
    //   .min(new Date(moment(fromDate).subtract(1, 'days')), 'To Date should be greater than or equal to From Date')
    //   .max(new Date(moment(fromDate).add(30, 'days')), 'To Date is exceeding the maximum To Date')
    // ,
    location: Yup.object().shape({
      value: Yup.string().required("Location is required"),
    }),

    site: Yup.object().shape({
      value: Yup.string().required("Site is required"),
    }),

    // isParam: Yup.boolean().notRequired(),

    // parameter: Yup.array()
    //   .when('isParam', {
    //     is: false,
    //     then: (validationSchema) => validationSchema
    //       .required('Parameter is required')
    //       .min(1, 'At least one parameter must be selected'),
    //     otherwise: (validationSchema) => validationSchema.notRequired(),
    //   })
    //   .nullable(),

    // group: Yup.object().shape({})
    //   //
    //   .when('isParam', {
    //     is: true,
    //     then: (validationSchema) => validationSchema.required('Group is required'),
    //     otherwise: (validationSchema) => validationSchema.notRequired(),
    //   }),
  });

  const formik = useFormik({
    initialValues: {
      // fromDate: fromDate,
      toDate: toDate,
      location: selectedLocation,
      site: selectedAsset,
      // parameter: selectedParameter,
      // isParam: isParam,
      // group: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const fromDateWithHours = new Date(values.fromDate);
      fromDateWithHours.setHours(fromHours, fromMinutes, fromSeconds);

      const toDateWithHours = new Date(values.toDate);
      toDateWithHours.setHours(toHours, toMinutes, toSeconds);

      
    // Check if To Date is less than From Date
    if (toDateWithHours < fromDateWithHours) {
      formik.setFieldError('toDate', 'To Date cannot be less than From Date.');
      return;
    }

    // Check the difference between fromDate and toDate
    const diffInDays = moment(toDateWithHours).diff(moment(fromDateWithHours), 'days');
    
    // If difference exceeds 30 days, show error message
    if (diffInDays > 30) {
      formik.setFieldError('toDate', 'Maximum allowed date range is 30 days');
    } else {

      const data = {
        fromDate: fromDateWithHours,
        toDate: toDateWithHours,
        // toDate: new Date(toDate),
        location: selectedLocation,
        site: selectedAsset,
        // parameter: selectedParameter,
        // group: values.group,
      };
      props.handleSubmitData(data);
      handleCloseFirstModal();

      props.updateFromDate(fromDateWithHours);
      props.updateToDate(toDateWithHours);
    }
    },
  });

  // const groupFormik = useFormik({
  //   // enableReinitialize: true, // Enable reinitialization when initialValues change
  //   initialValues: {
  //     group_name: '',
  //   },
  //   validationSchema: Yup.object().shape({
  //     group_name: Yup.string()
  //       .max(25, 'Title must be equal to or less than 25 characters')
  //       .required('Title is required'),
  //   }),
  //   onSubmit: (values) => {
  //     handleAddGroup(values.group_name);
  //     handleCloseSecondModal();
  //   },
  // });

  const handleCloseFirstModal = () => {
    setShowFirstModal(false);
  };
  const handleShowFirstModal = () => {
    formik.setErrors({
      fromDate: "",
      toDate: "",
      location: "",
      site: "",
      // parameter: '',
    });
    formik.setFieldValue("fromDate", fromDate);
    formik.setFieldValue("location", selectedLocation);
    formik.setFieldValue("site", selectedAsset);
    // formik.setFieldValue('parameter', selectedParameter || '')
    setShowFirstModal(true);
  };

  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
  };
  const handleShowSecondModal = () => {
    setShowSecondModal(true);
  };

  // const handleCloseTitleGroupForm = () => {
  //   setShowSecondModal(false);
  // };

  const handleLocationChange = (selectedLocation) => {
    localStorage.removeItem("asset");
    props.changeLocation(selectedLocation);
  };

  return (
    <>
      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="modal"
              href="#"
              data-bs-target="#alert"
              data-tooltip-id="tooltip"
              data-tooltip-content="Alert"
            >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
            <Tooltip
              id="tooltip"
              place="bottom"
              type="dark"
              effect="solid"
              style={{
                position: "absolute",
                marginLeft: "-10px",
                padding: "10% 20% 10% 20%",
              }}
            />
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowFirstModal}
              data-tooltip-id="tooltip"
              data-tooltip-content="Filter"
            >
              <i className="fa fa-filter nav-icon"></i>
            </a>
          </li>
        </ul>
      </div>
      {/* filter modal */}
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirstModal}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <b>Filter</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} id="energy_consumtion_form">
            <div className="form-row">
              <div className="col-md-12">
                {/* <div className="selectWpr"> */}

                {/* is param toggle */}
                {/* <div
                  className="form-group switch float-left"
                // onChange={getGroupName}
                >
                  <label className="switch-light">
                    <input
                      type="checkbox"
                      checked={formik.values.isParam}
                      onChange={(e) => {
                        formik.setFieldValue("isParam", e.target.checked);
                        changeIsParam(e.target.checked);
                      }}
                      onBlur={() => {
                        // formik.handleBlur('site');
                        formik.setFieldTouched("isParam", true);
                      }}
                      name="isGroupSwitchOn"
                    />
                    <span>
                      <span>Parameter</span>
                      <span>Group</span>
                    </span>
                    <a className="btn btn-primary"></a>
                  </label>
                </div> */}

                {/* add group */}
                {/* {formik.values.isParam === false && (
                  <div className="float-right form-group">
                    <span
                      onClick={(e) =>
                        formik.values.parameter.length < 2 && setDisabled(true)
                      }
                    >
                      <Button
                        className="btn btn-sm btn-c-outline-primary"
                        onClick={handleShowSecondModal}
                        disabled={
                          formik.values.isParam === false &&
                          formik.values.parameter.length <= 1
                        }
                      >
                        Add Group
                      </Button>
                    </span>
                  </div>
                )} */}

                {/* <br /> */}

                {/* <div className="col-md-12" Style={"clear: both"}></div> */}

                {/* location */}
                <div className="form-group">
                  <label>Location *</label>
                  <Select
                    options={locationData}
                    placeholder="Select Location"
                    onChange={(e) => {
                      handleLocationChange(e);
                      formik.setFieldValue("site", "");
                      // changeLocation(e);
                      formik.setFieldTouched("location", true);
                      formik.setFieldValue("location", e);
                    }}
                    value={formik.values.location}
                    onBlur={() => {
                      // formik.handleBlur('location');
                      formik.setFieldTouched("location", true);
                    }}
                  />
                  {formik.errors.location && formik.touched.location && (
                    <div className="error-message text-danger">
                      {formik.errors.location.value}
                    </div>
                  )}
                </div>
                {/* site */}
                <div className="form-group">
                  <label>Asset *</label>
                  <Select
                    options={assetData}
                    placeholder="Select Site"
                    value={formik.values.site}
                    onChange={(e) => {
                      formik.setFieldValue("parameter", []);
                      changeAsset(e);
                      formik.setFieldTouched("site", true);
                      formik.setFieldValue("site", e);
                    }}
                    onBlur={() => {
                      // formik.handleBlur('site');
                      formik.setFieldTouched("site", true);
                    }}
                  />
                  {formik.errors.site && formik.touched.site && (
                    <div className="error-message text-danger">
                      {formik.errors.site.value}
                    </div>
                  )}
                  {/* </div> */}
                </div>

                {/* from date */}
                <div className="form-group">
                  <div className="row">
                    <div className="col-8">
                      <label>From Date *</label>
                      <DatePicker
                        selected={formik.values.fromDate}
                        onChange={(date) => {
                          if (moment(date, "DD-MM-YYYY", true).isValid()) {
                            formik.setFieldValue("fromDate", date);
                            formik.setFieldTouched("fromDate", true);
                            setFromDate(date);
                            // const lastDate = new Date(moment(date).add(30, 'days'));
                            // formik.setFieldValue('toDate', lastDate);
                            // formik.setFieldTouched('toDate', true);
                            // setToDate(lastDate);
                          }
                        }}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        maxDate={new Date()} // Maximum date as today
                        minDate={new Date(moment().subtract(3, "months"))} // Minimum date 3 months ago
                        customInput={<CustomDateInput />}
                        // onBlur={(e) => {
                        //   if (
                        //     moment(e.target.value, "DD-MM-YYYY", true).isValid()
                        //   ) {
                        //     const date = new Date(
                        //       moment(e.target.value, "DD-MM-YYYY").format(
                        //         "YYYY-MM-DD"
                        //       )
                        //     );
                        //     formik.setFieldValue("fromDate", date);
                        //     formik.setFieldTouched("fromDate", true);
                        //     setFromDate(date);
                        //   }
                        // }}
                      />
                    </div>

                    <div className="col-2">
                      <label>HH</label>
                      <select
                        className="form-control"
                        value={fromHours}
                        onChange={(e) => setFromHours(e.target.value)}
                      >
                        {Array.from({ length: 24 }, (_, i) => {
                          const hour = i.toString().padStart(2, "0");
                          return (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-2">
                      <label>MM</label>
                      <select
                        className="form-control"
                        value={fromMinutes}
                        onChange={(e) => setFromMinutes(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const minute = i.toString().padStart(2, "0");
                          return (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-2" style={{ display: "none" }}>
                      <label>SS</label>
                      <select
                        className="form-control"
                        value={fromSeconds}
                        onChange={(e) => setFromSeconds(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const second = i.toString().padStart(2, "0");
                          return (
                            <option key={second} value={second}>
                              {second}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* {formik.errors.fromDate && formik.touched.fromDate && (
                      <div className="error-message text-danger">
                        {formik.errors.fromDate}
                      </div>
                    )} */}
                  </div>
                </div>
                {/* to date */}
                <div className="form-group">
                  <div className="row">
                    <div className="col-8">
                      <label>To Date *</label>
                      <DatePicker
                        selected={formik.values.toDate}
                        onChange={(date) => {
                          if (moment(date, "DD-MM-YYYY", true).isValid()) {
                            formik.setFieldValue("toDate", date);
                            formik.setFieldTouched("toDate", true);
                            setToDate(date);
                          }

                          // updateDateRange(formik.values.fromDate, date);
                        }}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        includeDateIntervals={[
                          {
                            start: new Date(
                              moment(fromDate).subtract(3, "months")
                            ),
                            end: new Date(moment(fromDate).add(3, "months")),
                          },
                        ]}
                        onBlur={(e) => {
                          try {
                            if (
                              moment(
                                e.target.value,
                                "DD-MM-YYYY",
                                true
                              ).isValid()
                            ) {
                              const date = new Date(
                                moment(e.target.value, "DD-MM-YYYY").format(
                                  "YYYY-MM-DD"
                                )
                              );
                              formik.setFieldValue("toDate", date);
                              formik.setFieldTouched("toDate", true);
                              setToDate(date);
                            }
                          } catch (error) {}
                        }}
                        maxDate={new Date()}
                        // maxDate={new Date(moment(fromDate).add(15, "days"))}
                        minDate={
                          new Date(moment().subtract(3, "months"))
                        }
                        customInput={<CustomDateInput />}
                      />
                    </div>
                    <div className="col-2">
                      <label>HH</label>
                      <select
                        className="form-control"
                        value={toHours}
                        onChange={(e) => setToHours(e.target.value)}
                      >
                        {Array.from({ length: 24 }, (_, i) => {
                          const hour = i.toString().padStart(2, "0");
                          return (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-2">
                      <label>MM</label>
                      <select
                        className="form-control"
                        value={toMinutes}
                        onChange={(e) => setToMinutes(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const minute = i.toString().padStart(2, "0");
                          return (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-2" style={{ display: "none" }}>
                      <label>SS</label>
                      <select
                        className="form-control"
                        value={toSeconds}
                        onChange={(e) => setToSeconds(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const second = i.toString().padStart(2, "0");
                          return (
                            <option key={second} value={second}>
                              {second}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.errors.toDate && formik.touched.toDate && (
    <div className="error-message text-danger text-center">
      {formik.errors.toDate}
    </div>
  )}
                  </div>
                </div>
                <div className="ftrBtn">
                  <div className="form-group">
                    <input
                      type="submit"
                      name=""
                      value="Submit"
                      className="btn btn-primary btn-block"
                      data-disable-with="Submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
