import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindowF,
  Polyline,
} from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import greenPin from "./greenPin_sdw1.png";
import redPin from "./redPin_sdw1.png";
import grayPin from "./grayPin_sdw1.png";
import bluePin from "./bluePin_sdw.png";
import { useNavigate } from "react-router-dom";
import "./Map.css";

const containerStyle = {
  width: "100%",
  height: "250px",
};

const initialCenter = {
  lat: 0,
  lng: 0,
};

let image = "";

function MapLoad({ zoomcntr, gesture, mapType, locations, faults, joints }) {
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [infoWindowCont, setInfoWindowCont] = useState(false);
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBj3vNZTpjBQ_4EMcgJoXLKDKpLQ83aRSw",
  });

  const handleMarkerClick = (marker) => {
    if (infoWindowCont === false) {
      setSelectedMarker(marker);
      setInfoWindowOpen(true);
    }
  };

  const handleMarkerMouseOut = () => {
    if (infoWindowCont === false) {
      setSelectedMarker(null);
      setInfoWindowOpen(false);
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  useEffect(() => {
    if (locations.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
  
      locations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.latitude, location.longitude)
        );
      });
  
      joints.forEach((joint) => {
        bounds.extend(
          new window.google.maps.LatLng(joint.latitude, joint.longitude)
        );
      });
  
      const center = bounds.getCenter();
      map.setCenter(center);
      map.fitBounds(bounds);
    } else if (map) {
      const defaultCenter = { lat: 19.971920981858858, lng: 73.83380818735375 };
      map.setCenter(defaultCenter);
      map.setZoom(10);
    }
  }, [locations, joints, map]);

  const handleInfoWindowClick = (location) => {
    const locationData = {
      value: location.route_id,
      label: location.cable_id,
    };
    const assetData = {
      value: location.route_id,
      label: location.cable_id,
    };

    localStorage.setItem("location", JSON.stringify(locationData));
    localStorage.setItem("asset", JSON.stringify(assetData));

    navigate(`/cable_Trend`);

  };
  const sortedLocations = locations.sort((a, b) => a.sequence - b.sequence);

  const polylinePath = locations.map((location) => ({
    lat: location.latitude,
    lng: location.longitude,
  }));

  const polylinePathJoints = joints.map((joint) => ({
    lat: joint.latitude,
    lng: joint.longitude,
  }));

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={initialCenter}
          style={{ border: "0" }}
          zoom={4}
          onLoad={onLoad}
          allowFullScreen=""
          loading="lazy"
          options={{
            streetViewControl: false,
            mapTypeId: "terrain",
            mapTypeControl: false,
            zoomControl: zoomcntr ? true : false,
            gestureHandling: gesture,
            keyboardShortcuts: false,
          }}
        >
          {locations.map((location, index) => (
            <MarkerF
              key={index}
              position={{ lat: location.latitude, lng: location.longitude }}
              icon={{
                url: grayPin,
                scaledSize: new window.google.maps.Size(30, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 29),
              }}
              onMouseOver={() => {
                setInfoWindowCont(false);
                handleMarkerClick(location);
              }}
              onMouseOut={handleMarkerMouseOut}
              onClick={() => {
                setInfoWindowCont(true);
              }}
            >
              {selectedMarker === location && infoWindowOpen && (
                <InfoWindowF
                  position={location}
                  onCloseClick={() => {
                    setInfoWindowCont(false);
                  }}
                >
                  <div className="toolWpr">
                    <div
                      onClick={() => handleInfoWindowClick(location)}
                      style={{ textDecoration: "none", marginTop: "2px" }}
                    >
                      <p style={{ color: "black", cursor: "pointer" }}>
                        <b>{location.route_id || "-"}</b>
                        &nbsp; - &nbsp;
                        <b>{location.cable_id || "-"}</b>
                      </p>
                    </div>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          ))}

          {faults.map((fault, index) => {
            image = fault.status === "Resolved" ? greenPin : redPin;
            return (
              <MarkerF
                key={`fault-${index}`}
                position={{ lat: fault.latitude, lng: fault.longitude }}
                icon={{
                  url: image,
                  scaledSize: new window.google.maps.Size(30, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 29),
                }}
                onMouseOver={() => {
                  setInfoWindowCont(false);
                  handleMarkerClick(fault);
                }}
                onMouseOut={handleMarkerMouseOut}
                onClick={() => {
                  setInfoWindowCont(true);
                }}
              >
                {selectedMarker === fault && infoWindowOpen && (
                  <InfoWindowF
                    position={fault}
                    onCloseClick={() => {
                      setInfoWindowCont(false);
                    }}
                  >
                    <div className="toolWpr">
                      <div style={{ textDecoration: "none", marginTop: "2px" }}>
                        <p style={{ color: "black" }}>
                          <b>{fault.description || "Fault"}</b>
                          &nbsp; - &nbsp;
                          <b>{fault.status || "Status"}</b>
                        </p>
                      </div>
                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            );
          })}

          {sortedLocations.length > 1 && (
            <Polyline
              path={polylinePath}
              options={{
                strokeColor: "#00FF00",
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: "#00FF00",
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                radius: 30000,
                paths: polylinePath,
                zIndex: 1,
              }}
            />
          )}

          {/* {joints.length > 1 && (
            <Polyline
              path={polylinePathJoints}
              options={{
                strokeColor: "#00FF00", // Blue color for joints polyline
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: "#00FF00",
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                radius: 30000,
                paths: polylinePathJoints,
                zIndex: 1,
              }}
            />
          )} */}

          {joints.map((joint, index) => (
            <MarkerF
              key={`joint-${index}`}
              position={{ lat: joint.latitude, lng: joint.longitude }}
              icon={{
                url: bluePin, // Blue pin for joints
                scaledSize: new window.google.maps.Size(30, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 29),
              }}
              onMouseOver={() => {
                setInfoWindowCont(false);
                handleMarkerClick(joint);
              }}
              onMouseOut={handleMarkerMouseOut}
              onClick={() => {
                setInfoWindowCont(true);
              }}
            >
              {selectedMarker === joint && infoWindowOpen && (
                <InfoWindowF
                  position={joint}
                  onCloseClick={() => {
                    setInfoWindowCont(false);
                  }}
                >
                  <div className="toolWpr">
                    <div style={{ textDecoration: "none", marginTop: "2px" }}>
                      <p style={{ color: "black" }}>
                        <b>{joint.name || "Joint"}</b> {/* Display joint name */}
                      </p>
                    </div>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          ))}

        </GoogleMap>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
}

export default React.memo(MapLoad);
