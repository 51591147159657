import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const DemadGraphSkeleton = () => {
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  {/* Breadcrumb Skeleton */}
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Skeleton variant="text" width={150} height={30} />
                      </li>
                      <li className="breadcrumb-item">
                        <Skeleton variant="text" width={150} height={30} />
                      </li>
                      <li className="breadcrumb-item">
                        <Skeleton variant="text" width={300} height={30} />
                      </li>
                    </ol>
                  </div>

                  <div className="mdlContent card-body">
                    {/* First Chart Skeleton */}
                    <div className="card pr-card mb30">
                      <div className="inrCardHdr">
                        <Skeleton variant="text" width={300} height={30} />
                      </div>
                      <div className="inrCardBody">
                        <Skeleton variant="rectangular" width="100%" height={200} />
                      </div>
                    </div>

                    {/* Second Chart Skeleton */}
                    <div className="card pr-card mb30">
                      <div className="inrCardHdr">
                        <Skeleton variant="text" width={300} height={30} />
                      </div>
                      <div className="inrCardBody">
                        <Skeleton variant="rectangular" width="100%" height={200} />
                      </div>
                    </div>

                    {/* Third Chart Skeleton */}
                    <div className="card pr-card mb30">
                      <div className="inrCardHdr">
                        <Skeleton variant="text" width={300} height={30} />
                      </div>
                      <div className="inrCardBody">
                        <Skeleton variant="rectangular" width="100%" height={200} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DemadGraphSkeleton;
