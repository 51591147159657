import React, { useContext, useEffect } from "react";
import MainContent from "./MainContent/MainContent";
import solutionContext from "../../context/solution/solutionContext";

const Summary = () => {
  useContext(solutionContext);

  useEffect(() => {
  }, [])

  return (
    <>
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <MainContent />
        </div>
      </div>
    </>
  );
};

export default Summary;
