/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


const Table = ({ data }) => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Preprocess the data before applying filtering
  const preprocessData = (data) => {
    return data.map((row) => {
      const processedRow = row.map((item, index) => {
        // Convert date strings to the desired format
        if ((index === 0) && typeof item === "string") {
          return formatDateTime(item);
        }
        return item;
      });
      return processedRow;
    });
  };

  const checkEmpty = (item) => {
    return item
  }

  useEffect(() => {
    const processedData = preprocessData(data);
    const filteredResult = processedData.filter((row) => {
      const rowValues = row.map((item, index) => {

        return typeof item === "string" ? item.toLowerCase() : item.toString()
      }
      );


      const searchTerm = search.toLowerCase();

      return rowValues.some((value) => value.includes(searchTerm));
    });

    setFilteredData(filteredResult);
  }, [data, search]);

  // for date formating
  function formatDateTime(dateTimeString) {
    if (typeof dateTimeString === 'string' && !isNaN(Date.parse(dateTimeString))) {
      const data = new Date(new Date(dateTimeString).toISOString());
      const result = `${String(data.getUTCDate()).padStart(2, '0')}-${String(data.getUTCMonth() + 1).padStart(2, '0')}-${data.getUTCFullYear()} ${String(data.getUTCHours()).padStart(2, '0')}:${String(data.getUTCMinutes()).padStart(2, '0')}:${String(data.getUTCSeconds()).padStart(2, '0')}`;
      return result;
    } else {
      return dateTimeString;
    }
  }



  function removeSlash(dateTimeString) {
    const [day, month, year] = dateTimeString.split('/');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };
  const columns = [
    {
      name: "Last Updated",
      selector: (row) => parseDateString(row[0]), 
      sortable: true,
      // style: {
      //   whiteSpace: "normal",
      //   background: "orange",
      // },
      cell: row => <div className="custom-cell">{row[0]}</div>, // Apply custom cell style     
    },
    { name: "Location", selector: (row) => row[1] || "-", sortable: true, cell: row => <div className="custom-cell">{row[1] || "-"}</div> },
    { name: "Asset", selector: (row) => row[2] || "-", sortable: true, cell: row => <div className="custom-cell">{row[2] || "-"}</div> },
    { name: "Alerts Count", selector: (row) => row[3], sortable: true, cell: row => <div className="custom-cell">{row[3] || "-"}</div> },
    { name: "Make", selector: (row) => row[4] || "-", sortable: true, cell: row => <div className="custom-cell">{row[4] || "-"}</div> },
    {
      name: "Rating kVA",
      selector: (row) => parseFloat(row[5]) || 0,
      sortable: true,
      cell: row => <div className="custom-cell">{row[5]}</div>
    },
    { name: "Serial Number", selector: (row) => row[6] || "-", sortable: true, cell: row => <div className="custom-cell">{row[6] || "-"}</div> },
    { name: "Manufacturing Year", selector: (row) => row[7] || "-", sortable: true, cell: row => <div className="custom-cell">{row[7] || "-"}</div> },
  ];

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const customHeadings = columns.map((column) => column.name);

    const filteredDataExcel = filteredData.map((row) => {
      const filteredItem = {};
      row.forEach((item, index) => {
        const heading = customHeadings[index];
        if (index === 6) {
          filteredItem[heading] = item.split(' ')[0];
        }
        else {
          filteredItem[heading] = item;
        }
        // if(index === 0 || index === 6){
        //   filteredItem[heading] = formatDateTime(item);
        // }
        // else if(index === 3 || index === 4 || index ===5){
        //   filteredItem[heading] =  parseFloat(item.toFixed(3)) || "-";
        // }
        // else{
        //   filteredItem[heading] = item;
        // }

      });
      return filteredItem;
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "SummaryData" + fileExtension);
  };

  // for adding all option inside pagination
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  // no data available columns
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        whiteSpace: 'normal',// Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: 'normal',
      },
    }
  };

  return (
    <div className="card pr-card mb30 tableCard">
      <div className="card-header">
        <div className="row align-items-center">
          {/* <div className="col-md-6 col-lg-6 col-xl-6">
          </div> */}
          <div className="col-md-6 col-lg-6 col-xl-6">
            <div className="dataTables_length" id=""></div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6">
            <div className="form-inline dataTables_filter">
            

              <div className="mr10">
                <label>
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </label>
              </div>

              <div className="input-group pr-form-group" id="">

                <a>
                  {Object.keys(filteredData).length === 0 ? (
                    <a className="" alt="Download XLS" >
                      <i className="fa fa-file-excel-o" ></i>
                    </a>

                  ) : (
                    <a className="" alt="Download XLS" onClick={exportToCSV}>
                      <i className="fa fa-file-excel-o" ></i>
                    </a>

                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            // className="table table-hover dtr-inline"
            fixedHeader
            // fixedHeaderScrollHeight="450px"
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoDataComponent />}
            customStyles={customStyles}
          // customStyles={{
          //   headCells: {
          //     style: {
          //       overflowWrap: "break-word",
          //       backgroundColor: '#FFA500'
          //     },
          //   },
          // }}
          />
        </div>
        <div className="row mt20"></div>
      </div>
    </div>
  );
};

export default Table;
