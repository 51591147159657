/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, useCallback } from "react";
import MapLoad from "./MapLoad";
import Table from "./Table";
import ReactToPrint from "react-to-print";
import Cookies from "js-cookie";
import axios from "axios";
import logo from "./motware_logo.png";

import styles from "./MainContent.css";
import { useReactToPrint } from "react-to-print";

const Summary_PDF = () => {
  const componentToPrintRef = useRef();
  const [tableData, setTableData] = useState({ headers: [], data: [] });
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [locations, setLocations] = useState([]); // State for locations
  const [load, setLoad] = useState(0);


  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId)
    }
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     fetchTableData();
  //   }
  // }, [token]);

  function removeSlash(dateTimeString) {
    const [day, month, year] = dateTimeString.split('/');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function formatDateTime(dateTimeString) {
    let data;
    let result;
    if (isNaN(dateTimeString)) {
      result = "-"
    } else {
      data = new Date(new Date(dateTimeString).toISOString());
      result = `${data.getUTCFullYear()}-${String(data.getUTCMonth() + 1).padStart(2, '0')}-${String(data.getUTCDate()).padStart(2, '0')} ${String(data.getUTCHours()).padStart(2, '0')}:${String(data.getUTCMinutes()).padStart(2, '0')}:${String(data.getUTCSeconds()).padStart(2, '0')}`;

    }
    // const result =
    return result
  }

  const fetchMapData = useCallback(async () => {
    for (let i = 0; i < 3; i++) {
      try {
        if (!token) {
          // Return early if token is not available
          return;
        }
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/summary/summary_map`,
          {
            access_token: token,
            user_log_id: userLogId
          }

        );
        if (response.status === 200 && response.data.success === true) {
          const mapData = response.data;

          if (mapData.success === true) {
            const result = [...mapData.data];
            setLocations(result);
          }
        } else {

          const errorMessage =
            response.data && response.data.message
              ? response.data.message
              : "Something went wrong";

          showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Map API:", error);
        showFlashMessage("Something went wrong");
      }
    }
  }, [token]);

  const fetchTableData = useCallback(async () => {
    for (let i = 0; i < 3; i++) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/summary/summary_table`,
          {
            access_token: token,
            user_log_id: userLogId
          },
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200 && response.data.success) {
          setTableData(response.data.data);
        } else {
          const data = response.data;
        }
      } catch (error) {
        console.error("Table API:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          // await fetchMapData();
          // await fetchTableData();
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };

      const delay = 200;
      const timerId = setTimeout(() => {
        fetchData();
      }, delay);

      return () => clearTimeout(timerId);
    }
  }, [token]);
  const CustomHeader = () => (
    <div className="custom-header">
      <h1>My Custom Header</h1>
    </div>
  );

  // Custom footer component
  const CustomFooter = () => (
    <div className="custom-footer">
      <p>Page Footer</p>
    </div>
  );
  // Define the useReactToPrint hook with your configuration
  // const handlePrint = useReactToPrint({
  //   content: () => componentToPrintRef.current,
  // });

  // very importat for header and footer

  // const PageBreak = () => <div className="page-break"></div>;
  const handlePrint = useReactToPrint({
    content: () => {
      const tableStat = componentToPrintRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");

      // Header HTML
      const header = `<img src="${logo}" alt="" className="watermark"/>`;
      const printContainer = document.createElement("div");
      printContainer.classList.add("print-container");

      // Append the header and content to the container
      printContainer.innerHTML = header;
      printContainer.appendChild(tableStat);

      // Append the container to the PrintElem
      PrintElem.appendChild(printContainer);

      // Append the page break and footer to the PrintElem
      return PrintElem;
    },
  });

  // for loader
  useEffect(() => {
    const interval = setInterval(() => {
      setLoad((prevLoad) => {
        if (prevLoad < 100) {
          return prevLoad + 1;
        } else {
          // Reset load to 0 after reaching 100
          setTimeout(() => {
            setLoad(0);
          }, 2000);
        }
      });
    }, 50);
  }, []);
  // display loader
  if (isLoading) {
    return (
      <div className="loaderWpr">
        <span id="loader">{load} % </span>{" "}
      </div>
    );
  }
  return (
    <div>
      <div>
        <center>
          <button className="btn btn-primary" onClick={handlePrint}>
            Click to Print
          </button>
          {/* <ReactToPrint
        trigger={() => (
          <button className="btn btn-primary">Click to Print</button>
        )}
        content={() => componentToPrintRef.current}
      /> */}
        </center>

        {/* <center>
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-primary">Click to Print</button>
            )}
            content={() => componentToPrintRef.current}
            documentTitle="Summary PDF"
            copyStyles
            removeAfterPrint
            bodyClass="print-body"
            onBeforeGetContent={() => {
              // You can customize styles for printing here
            }}
          />
        </center> */}
      </div>
      <section className="content page-main" ref={componentToPrintRef}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                <div className="mdlContent card-body">
                  <div className="map col-md-12 col-lg-12">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <MapLoad
                          zoomcntr={false}
                          gesture="none"
                          mapType={false}
                          locations={locations}
                        />
                      </div>
                      {/*  end card-body --> */}
                    </div>
                    {/* <!-- end card pr-card --> */}
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          className="table table-hover dtr-inline"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              {tableData.headers.map((header, index) => (
                                <th>{header.split("_").join(" ")}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.data.map((rowData, rowIndex) => (
                              <tr>
                                {rowData.map((cell, cellIndex) => (
                                  <td key={cellIndex}>
                                    {cellIndex === 0 ||
                                      cellIndex === rowData.length - 1
                                      ? cellIndex === 6 ? formatDateTime(new Date(cell)).split(" ")[0] : formatDateTime(new Date(cell))
                                      : ((cellIndex === 3 || cellIndex === 4 || cellIndex === 5) ? ((typeof cell == "string") ? cell : parseFloat(cell.toFixed(3))) : cell)}
                                    {/* ((typeof cell == "string") ? cell : parseFloat(cell.toFixed(3))) */}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Summary_PDF;
