import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css"
import "admin-lte/dist/css/adminlte.css";
import "bootstrap"
import "admin-lte/dist/js/adminlte.js";
import "../src/assets/css/custom.css";
import SolutionState from './components/context/solution/SolutionState';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <SolutionState>
    <App />
    </SolutionState>
    </BrowserRouter>
  </React.StrictMode>
  
);
reportWebVitals();
