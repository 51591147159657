import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from 'react-select';
import {Tooltip } from 'react-tooltip';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const Sidebar = (props) => {
    let { locationData, assetData, changeLocation, changeAsset, handleSubmitData, selectedLocation, selectedAsset, fromDate, toDate, setFromDate, setToDate } = props;
    const [showFirstModal, setShowFirstModal] = useState(false);

    const validationSchema = Yup.object().shape({
        location: Yup.object().shape({
            value: Yup.string().required('Location is required'),
        }),
        site: Yup.object().shape({
            value: Yup.string().required('Site is required'),
        }),
    });

    const formik = useFormik({
        // enableReinitialize: true, // Enable reinitialization when initialValues change
        initialValues: {
            location: selectedLocation,
            site: selectedAsset,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const data = {

                // toDate: new Date(toDate),
                location: selectedLocation,
                site: selectedAsset,
            };
            props.handleSubmitData(data);
            handleCloseFirstModal();


        },
    });
    const handleCloseFirstModal = () => {
        formik.setErrors({
            location: '',
            site: '',
        });
        setShowFirstModal(false)
    };
    const handleShowFirstModal = () => {
        formik.setFieldValue("fromDate", fromDate)
        formik.setFieldValue("location", selectedLocation)
        formik.setFieldValue("site", selectedAsset)

        setShowFirstModal(true);
    }

    return (
        <>
            <div className="sidebarRight">
                <ul>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="modal" href='#' data-bs-target="#alert" data-tooltip-id="tooltip"  
data-tooltip-content="Alert" >
                            <i className="fa fa-bell-o nav-icon"></i>
                        </a>
                        <Tooltip id="tooltip" place="bottom"  type="dark" effect="solid" style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={handleShowFirstModal} data-tooltip-id="tooltip"  
data-tooltip-content="Filter">
                            <i className="fa fa-filter nav-icon"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <Modal show={showFirstModal} onHide={handleCloseFirstModal} className="right">
                <Modal.Header closeButton>
                    <Modal.Title className="h5"><b>Filter</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit} id="energy_consumtion_form">
                        <div className="form-row">
                            <div className="col-md-12">

                                <div className="form-group">
                                    <label>Location *</label>
                                    <Select
                                        options={locationData}
                                        placeholder="Select Location"
                                        onChange={(e) => {
                                            changeLocation(e);
                                            formik.setFieldValue('location', e);
                                            formik.setFieldValue('site', '');
                                            formik.setFieldTouched('location', true);
                                        }}
                                        value={formik.values.location}
                                        onBlur={() => {
                                            // formik.handleBlur('location');
                                            formik.setFieldTouched('location', true);
                                        }}
                                    />
                                    {formik.errors.location && formik.touched.location && (
                                        <div className="error-message text-danger">{formik.errors.location.value}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Asset *</label>
                                    <Select
                                        options={assetData}
                                        placeholder="Select Site"
                                        value={formik.values.site}
                                        onChange={(e) => {
                                            changeAsset(e);
                                            formik.setFieldTouched('site', true);
                                            formik.setFieldValue('site', e);
                                        }}
                                        onBlur={() => {
                                            // formik.handleBlur('site');
                                            formik.setFieldTouched('site', true);
                                        }}
                                    />
                                    {formik.errors.site && formik.touched.site && (
                                        <div className="error-message text-danger">{formik.errors.site.value}</div>
                                    )}
                                </div>
                                <div className="ftrBtn">
                                    <div className="form-group">
                                        <input type="submit" name="" value="Submit" className="btn btn-primary btn-block" data-disable-with="Submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Sidebar