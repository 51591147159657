/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from './Sidebar'
import Single_Phase from './SinglePhase';
import ThreePhase from './ThreePhase';
import UMHC from './Umhc/UMHC';

const SystemBlockDiagram = () => {
    const [loader, setLoader] = useState(false);
    const [token, setToken] = useState("");
    const [userLogId, setUserLogId] = useState("")
    const [locationData, setLocationData] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedAsset, setSelectedAsset] = useState("");
    const [onload, setOnLoad] = useState(false);
    const [onloadLoc, setOnloadLoc] = useState("")
    const [onloadAsset, setOnloadAsset] = useState("")
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
    const [alerts, setAlerts] = React.useState([]);
    // Function to show flash message with a delay
    const showFlashMessage = (message, variant = "danger") => {
      const newAlert = {
        message: message,
        variant: variant,
        id: new Date().getTime(),
      };
  
      setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
    };
  
    useEffect(() => {
      if (alerts.length > 0) {
        const timeoutId = setTimeout(() => {
          setAlerts((prevAlerts) => prevAlerts.slice(1));
        }, 2000);
  
        return () => clearTimeout(timeoutId);
      }
    }, [alerts]);

    const localStorageLocation = () => {
        if (
            localStorage.getItem("location") !== undefined &&
            localStorage.getItem("location") !== "undefined" &&
            localStorage.getItem("location") !== "null" &&
            localStorage.getItem("location") !== null
        ) {
            let location = localStorage.getItem("location");
            location = JSON.parse(location);
            const flag = locationData.some((item) => item.value === location.value);
            if (flag) {
                setSelectedLocation(location);
                setOnloadLoc(location)
                getAsset(location.value);
                // setHeader({ location: location.label });
                return;
            }
        } else {
            localStorage.removeItem("asset");
            if (locationData.length > 0) {
                const new_location = locationData[0];
                // setSelectedLocation(new_location);
                setOnloadLoc(new_location)
                getAsset(new_location.value);
                // setHeader({ location: new_location.label });
                // alert("done")
            }
            else {
                showFlashMessage("Please select Location and Asset to display data.");
            }
        }
    };
    const localStorageAsset = () => {
        if (
            localStorage.getItem("asset") !== undefined &&
            localStorage.getItem("asset") !== "undefined" &&
            localStorage.getItem("asset") !== "null" &&
            localStorage.getItem("asset") !== null
        ) {
            let asset = localStorage.getItem("asset");
            asset = JSON.parse(asset);
            const flag = assetData.some((item) => item.value === asset.value);
            if (flag) {
                setSelectedAsset(asset);
                setOnloadAsset(asset)
                // setHeader({ location: selectedLocation.label, asset: asset.label });
                setOnLoad(true);
            }
        }
        else {
            if (assetData.length > 0) {
                const new_asset = assetData[0];
                setOnloadAsset(new_asset)
                // setSelectedAsset(new_asset);
                // setHeader({ location: selectedLocation.label, asset: new_asset.label });
                setOnLoad(true);
            }
            else {
                showFlashMessage("Please select Location and Asset to display data.");
            }
        }
    };
    const changeLocation = (props) => {
        // localStorage.setItem("location", JSON.stringify(props));
        // setSelectedLocation(props);
        setOnloadLoc(props)
        if (token !== null && token !== undefined && token !== "") {
            getAsset(props.value);
        }
    };
    const changeAsset = (props) => {
        // localStorage.setItem("asset", JSON.stringify(props));
        setOnloadAsset(props)
        if (token !== null && token !== undefined && token !== "") {
            // getAsset(props);
        }
    };

    const getLocation = async () => {
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;

        while (attempts < maxAttempts && !success) {
            try {
                if (token !== null && token !== undefined && token !== '') {
                    const response = await axios.post(`${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`, {
                        "access_token": token,
                        user_log_id: userLogId,
                        iot_sol: "SELCO"
                    });

                    if (response.status === 200 && response.data.success === true) {
                        const data = response.data.data.map((item) => ({
                            value: item._id,
                            label: item.name,
                        }));

                        if (response.data.data.length === 0) {
                            // showFlashMessage("Locations are Not Found");
                        }
                        setLocationData(data);
                        success = true;
                    }
                    else {
                        // showFlashMessage("Locations are Not Found");
                    }

                }
            } catch (error) {
                console.log(error);
            }
            attempts++;
        }
        if (!success) {
            showFlashMessage("Locations are Not Found");
        }
    };
    const getAsset = async (props) => {
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;

        while (attempts < maxAttempts && !success) {
            try {
                if (token !== null && token !== undefined && token !== "") {
                    const response = await axios.post(
                        `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
                        {
                            access_token: token,
                            location_id: props,
                            user_log_id: userLogId,
                            iot_sol: "SELCO"
                        }
                    );

                    if (response.status === 200 && response.data.success === true) {
                        const data = response.data.data.map((item) => ({
                            value: item._id,
                            label: item.name,
                        }));
                        setAssetData(data);
                        success = true;
                        if (response.data.data.length === 0) {
                            // setAssetData([]);
                            // showFlashMessage("Assets are Not Found");
                        }
                    }
                    else {
                        setAssetData([]);
                        // showFlashMessage("Assets are Not Found");
                    }
                    // else  if (response.status === 200 && response.data.success === false) {
                    //   showFlashMessage(response.data.msg);
                    // }
                }
            } catch (error) {
                console.log(error);
            }
            attempts++;
        }
        if (!success) {
            setAssetData([]);
            showFlashMessage("Assets are Not Found");
        }
    };

    useEffect(() => {
        if (onload === true) {
            try {
                const data = {
                    location: onloadLoc,
                    site: onloadAsset,
                };
                handleSubmitData(data);
            } catch (error) {
            }
        }
    }, [onload]);

    // System Block Diagram
    const [SinglePhase, setSinglePhase] = useState([]);

    const systemData = async (props) => {
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;

        while (attempts < maxAttempts && !success) {

            setLoader(true);
            const data = props;
            try {
                if (!token) {
                    return;
                }
                const response = await axios.post(
                    `${process.env.REACT_APP_VARIABLE}/iotrms/system_block_diagram/phase_diagram`
                    , data
                );
                if (response.data.data.length === 0) {
                    setLoader(false);
                    // showFlashMessage("Data Not Available", "danger");
                }
                setSinglePhase(response.data.data);
                success = true;
                setLoader(false)

            } catch (error) {
                setLoader(false)
                console.error('Error fetching data:', error);
            }
            attempts++;
        }
        if (!success) {
            showFlashMessage("Data Not Available", "danger");
        }
    };

    const handleSubmitData = (props) => {
        const { location, site } = props;
        localStorage.setItem("location", JSON.stringify(onloadLoc));
        localStorage.setItem("asset", JSON.stringify(onloadAsset));

        const storedLocation = JSON.parse(localStorage.getItem("location"));
        const updatedLocation = storedLocation || location;

        const storedSite = JSON.parse(localStorage.getItem("asset"));
        const updatedSite = storedSite || site;

        setSelectedLocation(updatedLocation);
        setSelectedAsset(updatedSite);

        setOnloadLoc(location)
        setOnloadAsset(site)

        if (token !== null && token !== undefined && token !== "") {
            const data = {
                access_token: token,
                user_log_id: userLogId,
                location_id: onloadLoc.value,
                assset_id: onloadAsset.value,
            };
            systemData(data);
            try {

            } catch (error) {
                console.error("Error making API calls:", error);
            }
        }
    }

    const subCentreData = SinglePhase.find((data) => data.type_of_site === "Sub Centre");
    const primaryHealthCentreData = SinglePhase.find((data) => data.type_of_site === "Primary Health Centre");
    const umhcData = SinglePhase.find((data) => ["Community Health Centre", "Urban Maternity Hospital"].includes(data.type_of_site));


    useEffect(() => {
        const tkData = Cookies.get("tkData");

        if (tkData) {
            const tokenData = JSON.parse(tkData);
            setToken(tokenData.token);
            setUserLogId(tokenData.userLogId)
        }
    }, []);

    useEffect(() => {
        if (token !== null && token !== undefined && token !== "") {
            getLocation();
        }
    }, [token]);

    useEffect(() => {
        if (token !== null && token !== undefined && token !== "") {
            localStorageLocation();
        }
    }, [locationData]);

    useEffect(() => {
        if (token !== null && token !== undefined && token !== "") {
            localStorageAsset();
        }
    }, [assetData]);

    function formatDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }


    return (
        <>
            <div className="wrapper">
                {loader && (
                    <div className='loaderWpr'>
                        <div className="loader"></div>
                    </div>
                )}
               
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mdlContent card pr-card mb30">

                                        <div className="breadcrumbHdr card-header">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    {/* <a >{asset.label}</a> */}
                                                    <a>{selectedAsset.label}</a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <a>{selectedLocation.label}</a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <a >

                                                        {SinglePhase[0]?.sensor_data?.datetime ? formatDate(SinglePhase[0]?.sensor_data?.datetime) : "-"}
                                                        {/* 2 Aug 2023 | 7:15:39 PM */}
                                                    </a>
                                                </li>
                                            </ol>
                                        </div>
                                        {/* <div className="mdlContent card-body"> */}

                                        <div className="mdlContent card-body">
                                            {/* Conditional rendering based on type_of_site */}
                                            {subCentreData && subCentreData.type_of_site === "Sub Centre" && <Single_Phase data={subCentreData} selectedLocation={selectedLocation} selectedAsset={selectedAsset} />}
                                            {primaryHealthCentreData && primaryHealthCentreData.type_of_site === "Primary Health Centre" && <ThreePhase data={primaryHealthCentreData} selectedLocation={selectedLocation} selectedAsset={selectedAsset} />}
                                            {umhcData && (umhcData.type_of_site === "Community Health Centre" || umhcData.type_of_site === "Urban Maternity Hospital") && <UMHC data={umhcData} selectedLocation={selectedLocation} selectedAsset={selectedAsset} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Sidebar
                locationData={locationData}
                assetData={assetData}
                changeLocation={changeLocation}
                changeAsset={changeAsset}
                selectedLocation={selectedLocation}
                selectedAsset={selectedAsset}
                handleSubmitData={handleSubmitData}
            />
        </>
    )
}

export default SystemBlockDiagram