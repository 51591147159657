import React, { useState, useEffect } from "react";
import Sidebar from './Sidebar';
import Tree from "react-d3-tree";
import { Alert } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import { useCenteredTree } from "./helpers";
import "./style.css";
import { zoom as d3zoom, zoomIdentity } from 'd3-zoom';

function Sld() {
    const [token, setToken] = useState("");
    const [userLogId, setUserLogId] = useState("");
    const [onload, setOnLoad] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [onloadLoc, setOnloadLoc] = useState("")

    const [sldData, setSldData] = useState({});
    const [sldDate, setSldDate] = useState(" ")
    const [alerts, setAlerts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [nodeSize, setNodeSize] = useState({ x: 120, y: 120 }); // Initial node size

    const [dimensions, translate, containerRef] = useCenteredTree();
    const [orientation, setOrientation] = useState("vertical");

    const [allCheckboxChecked, setAllCheckboxChecked] = useState(false);
    const [criticalCheckboxChecked, setCriticalCheckboxChecked] = useState(true);
    const [prevCheckboxState, setPrevCheckboxState] = useState(true); // Track the previous state
    const [hoveredNode, setHoveredNode] = useState(null); // Track hovered node

    useEffect(() => {
        if (token !== null && token !== undefined && token !== "") {
            getLocation();
        }
    }, [token]);

    useEffect(() => {
        if (token !== null && token !== undefined && token !== "") {
            localStorageLocation();
        }
    }, [locationData]);

    useEffect(() => {
        if (onload === true) {
            try {
                const data = {
                    location: onloadLoc,
                };
                // handleSubmitSld(data);
                handleSubmitCritical(data)
            } catch (error) {

            }
        }
    }, [onload]);

    // getting Token
    useEffect(() => {
        const tkData = Cookies.get("tkData");
        if (tkData) {
            const tokenData = JSON.parse(tkData);
            setToken(tokenData.token);
            setUserLogId(tokenData.userLogId)
        }
    }, []);

    const getLocation = async () => {
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;

        while (attempts < maxAttempts && !success) {
            try {
                if (token !== null && token !== undefined && token !== "") {
                    const response = await axios.post(
                        `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
                        {
                            access_token: token,
                            user_log_id: userLogId,
                            iot_sol: "EMS"
                        }
                    );

                    if (response.status === 200 && response.data.success === true) {
                        const data = response.data.data
                            .map((item) => ({
                                value: item._id,
                                label: item.name
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name
                        success = true;
                        if (response.data.data.length === 0) {
                            setLocationData(data);
                            // showFlashMessage("Locations are Not Found");
                        }
                        setLocationData(data);
                    }
                    else {
                        setLocationData(response.data.data)
                        // showFlashMessage("Locations are Not Found");
                    }

                }
            } catch (error) {
                console.log(error);
            }
            attempts++;
        }
        if (!success) {
            showFlashMessage("Locations are Not Found", "danger");
            setIsLoading(false);
        }
    };

    const localStorageLocation = () => {
        setOnLoad(true);
        if (
            localStorage.getItem("location") !== undefined &&
            localStorage.getItem("location") !== "undefined" &&
            localStorage.getItem("location") !== "null" &&
            localStorage.getItem("location") !== null
        ) {
            let location = localStorage.getItem("location");
            location = JSON.parse(location);
            const flag = locationData.some((item) => item.value === location.value);
            if (flag) {
                setSelectedLocation(location);
                setOnloadLoc(location)
                setOnLoad(true);
                return;
            }
        } else {
            // localStorage.removeItem("asset");
            if (locationData.length > 0) {
                const new_location = locationData[0];
                setOnloadLoc(new_location)
                setOnLoad(true);
            }
            else {
                // showFlashMessage("Please select Location to display data.");
            }
        }
    };

    const changeLocation = (props) => {
        setOnloadLoc(props)
    };

    // truncate to two decimal limt
    const truncateToTwoDigits = (value) => {
        if (!isNaN(value)) {
            return Math.trunc(value * 100) / 100;
        }
        return value;
    };

    const getSld = async (props) => {
        const data = props;
        setIsLoading(true);
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;
        let responseData = null; // Local variable to store response data

        while (attempts < maxAttempts && !success) {
            try {
                if (token !== null && token !== undefined && token !== "") {
                    const response = await axios.post(
                        `${process.env.REACT_APP_VARIABLE}/iotrms/sld/sld_hierarchy`,
                        data
                    );
                    const lastDatetime = response.data?.['Last Datetime'];
                    if (lastDatetime && lastDatetime.includes('-')) {
                        setSldDate(lastDatetime);
                    } else if (lastDatetime) {
                        setSldDate(formatDate(lastDatetime));
                    } else {
                        console.error("Last Datetime is undefined in the response data");
                    }
                    responseData = response.data.data;
                    // [
                    //     {
                    //         "name": "JSW Steel Ltd",
                    //         "icon":"no",
                    //         "arrow":"no",
                    //         "children": [
                                
                    //             {
                    //                 "name": "LTSS-1",
                    //                 "icon":"no",
                    //                 "attributes": {
                    //                     "kWh": 2505084.16,
                    //                     "R_Current": 223.02,
                    //                     "Y_Current": 220.56,
                    //                     "B_Current": 222.18,
                    //                     "Avg. Current": 221.92,
                    //                     "Y_Curren": 220.56,
                    //                     "B_Curren": 222.18,
                    //                     "Avg. Curren": 221.92,
                    //                     "status": "Green"
                    //                 },
                    //                 "children": [
                    //                     {
                    //                         "name": "LTSS-2 INCOMER-1",
                    //                         "attributes": {
                    //                             "kWh": 2179370.75,
                    //                             "R_Current": 0,
                    //                             "Y_Current": 0,
                    //                             "B_Current": 0,
                    //                             "Avg. Current": 0,
                    //                             "Y_Curren": 220.56,
                    //                     "B_Curren": 222.18,
                    //                     "Avg. Curren": 221.92,
                    //                             "status": "Green"
                    //                         },
                    //                         "children": [   
                    //                                     {
                    //                                         "name": "AMC-4",
                    //                                         "attributes": {
                    //                                             "kWh": 2179370.75,
                    //                                             "R_Current": 0,
                    //                                             "Y_Current": 0,
                    //                                             "B_Current": 0,
                    //                                             "Avg. Current": 0,
                    //                                             "status": "Green"
                    //                                         },
                    //                                         "children": []
                    //                                     },
                    //                                     {
                    //                                         "name": "AMC-2",
                    //                                         "attributes": {
                    //                                             "kWh": 3272667.25,
                    //                                             "R_Current": 41.04,
                    //                                             "Y_Current": 40.29,
                    //                                             "B_Current": 40.59,
                    //                                             "Avg. Current": 40.64,
                    //                                             "status": "Green"
                    //                                         },
                    //                                         "children": []
                    //                                     }
                    //                         ]
                    //                     },
                    //                     {
                    //                         "name": "AMC-2",
                    //                         "attributes": {
                    //                             "kWh": 3272667.25,
                    //                             "R_Current": 41.04,
                    //                             "Y_Current": 40.29,
                    //                             "B_Current": 40.59,
                    //                             "Avg. Current": 40.64,
                    //                             "status": "Green"
                    //                         },
                    //                         "children": [
                                                  
                    //                                 {
                    //                                     "name": "AMC-4",
                    //                                     "attributes": {
                    //                                         "kWh": 2179370.75,
                    //                                         "R_Current": 0,
                    //                                         "Y_Current": 0,
                    //                                         "B_Current": 0,
                    //                                         "Avg. Current": 0,
                    //                                         "status": "Green"
                    //                                     },
                    //                                     "children": []
                    //                                 },
                    //                                 {
                    //                                     "name": "AMC-2",
                    //                                     "attributes": {
                    //                                         "kWh": 3272667.25,
                    //                                         "R_Current": 41.04,
                    //                                         "Y_Current": 40.29,
                    //                                         "B_Current": 40.59,
                    //                                         "Avg. Current": 40.64,
                    //                                         "status": "Green"
                    //                                     },
                    //                                     "children": []
                    //                                 }
                    //                         ]
                    //                     }
                    //                 ]
                    //             },
                    //             {
                    //                 "name": "LTSS-2",
                    //                 "icon":"no",
                    //                 "attributes": {
                    //                     "kWh": 2505084.16,
                    //                     "R_Current": 223.02,
                    //                     "Y_Current": 220.56,
                    //                     "B_Current": 222.18,
                    //                     "Avg. Current": 221.92,
                    //                     "status": "Green"
                    //                 },
                    //                 "children": [
                    //                     {
                    //                         "name": "AMC-4",
                    //                         "attributes": {
                    //                             "kWh": 2179370.75,
                    //                             "R_Current": 0,
                    //                             "Y_Current": 0,
                    //                             "B_Current": 0,
                    //                             "Avg. Current": 0,
                    //                             "status": "Green"
                    //                         },
                    //                         "children": [
                                                  
                    //                             {
                    //                                 "name": "AMC-4",
                    //                                 "attributes": {
                    //                                     "kWh": 2179370.75,
                    //                                     "R_Current": 0,
                    //                                     "Y_Current": 0,
                    //                                     "B_Current": 0,
                    //                                     "Avg. Current": 0,
                    //                                     "status": "Green"
                    //                                 },
                    //                                 "children": []
                    //                             },
                    //                             {
                    //                                 "name": "AMC-2",
                    //                                 "attributes": {
                    //                                     "kWh": 3272667.25,
                    //                                     "R_Current": 41.04,
                    //                                     "Y_Current": 40.29,
                    //                                     "B_Current": 40.59,
                    //                                     "Avg. Current": 40.64,
                    //                                     "status": "Green"
                    //                                 },
                    //                                 "children": []
                    //                             }
                    //                     ]
                    //                     },
                    //                     {
                    //                         "name": "AMC-2",
                    //                         "attributes": {
                    //                             "kWh": 3272667.25,
                    //                             "R_Current": 41.04,
                    //                             "Y_Current": 40.29,
                    //                             "B_Current": 40.59,
                    //                             "Avg. Current": 40.64,
                    //                             "status": "Green"
                    //                         },
                    //                         "children": [
                                                  
                    //                             {
                    //                                 "name": "AMC-4",
                    //                                 "attributes": {
                    //                                     "kWh": 2179370.75,
                    //                                     "R_Current": 0,
                    //                                     "Y_Current": 0,
                    //                                     "B_Current": 0,
                    //                                     "Avg. Current": 0,
                    //                                     "status": "Green"
                    //                                 },
                    //                                 "children": []
                    //                             },
                    //                             {
                    //                                 "name": "AMC-2",
                    //                                 "attributes": {
                    //                                     "kWh": 3272667.25,
                    //                                     "R_Current": 41.04,
                    //                                     "Y_Current": 40.29,
                    //                                     "B_Current": 40.59,
                    //                                     "Avg. Current": 40.64,
                    //                                     "status": "Green"
                    //                                 },
                    //                                 "children": []
                    //                             }
                    //                     ]
                    //                     }
                    //                 ]
                    //             }
                    //         ]
                    //     }
                    // ]
                    if (response.status === 200 && response.data.success === true) {
                        setIsLoading(false);
                        setSldData(responseData);
                        success = true;
                    } else {
                        setIsLoading(false);
                        setSldData(responseData);
                        // showFlashMessage("Data Not Available For Sld");
                    }
                }
            } catch (error) {
                console.log(error);
            }
            attempts++;
        }

        if (!success && responseData) {
            setSldData(responseData);
            showFlashMessage("Data Not Available For Sld");
        }
    };

    const handleSubmitSld = async (props) => {
        const { location } = props;

        setSelectedLocation(location);

        setOnloadLoc(location)

        if (token !== null && token !== undefined && token !== "") {
            const data = {
                access_token: token,
                user_log_id: userLogId,
                iot_sol: "EMS"
            };
            try {
                await getSld(data);
            } catch (error) {
                console.error("Error making API calls:", error);
            }
        }
        setCriticalCheckboxChecked(false)
    }

    const SubmitDataWithLoc = async (props) => {
        const { location } = props;
        localStorage.setItem("location", JSON.stringify(onloadLoc));

        const storedLocation = JSON.parse(localStorage.getItem("location"));
        const updatedLocation = storedLocation || location;

        setSelectedLocation(updatedLocation);

        if (token !== null && token !== undefined && token !== "") {
            const data = {
                access_token: token,
                user_log_id: userLogId,
                location_id: onloadLoc.value,
                iot_sol: "EMS"
            };
            try {
                await getSld(data);
            } catch (error) {
                console.error("Error making API calls:", error);
            }
        }
        setAllCheckboxChecked(false);
        setCriticalCheckboxChecked(false)
    }
    const handleSubmitCritical = async (props) => {
        // const { location } = props;

        // setSelectedLocation(location);

        // setOnloadLoc(location)

        if (token !== null && token !== undefined && token !== "") {
            const data = {
                access_token: token,
                user_log_id: userLogId,
                iot_sol: "EMS",
                "critical_asset": 1
            };
            try {
                await getSld(data);
            } catch (error) {
                console.error("Error making API calls:", error);
            }
        }
    }
    // Function to show flash message with a delay
    const showFlashMessage = (message, variant = "danger") => {
      const newAlert = {
        message: message,
        variant: variant,
        id: new Date().getTime(),
      };
  
      setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
    };
  
    useEffect(() => {
      if (alerts.length > 0) {
        const timeoutId = setTimeout(() => {
          setAlerts((prevAlerts) => prevAlerts.slice(1));
        }, 2000);
  
        return () => clearTimeout(timeoutId);
      }
    }, [alerts]);
    const [maxTextLength, setMaxTextLength] = useState(0);
    const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
        let color;
        if (nodeDatum.attributes && nodeDatum.attributes.status === "Grey") {
            color = "#657790";
        } else if (nodeDatum.attributes && nodeDatum.attributes.status === "Red") {
            color = "#FF0000";
        } else if (nodeDatum.attributes && nodeDatum.attributes.status === "Green") {
            color = "#92D050";
        }

        const splitTextIntoLines = (text, maxLength) => {
            if (text === undefined || text === null) return [];

            const strText = text.toString();
            const lines = [];

            for (let i = 0; i < strText.length; i += maxLength) {
                lines.push(strText.substring(i, i + maxLength));
            }

            return lines;
        };

        const truncateToTwoDigits = (num) => {
            const truncatedNum = Math.round(num * 100) / 100;
            return isNaN(truncatedNum) ? "-" : truncatedNum;
        };
        const nameLines = splitTextIntoLines(nodeDatum.name, 18);
        // const kWhLines = nodeDatum?.attributes !== undefined ? splitTextIntoLines(`${truncateToTwoDigits(nodeDatum?.attributes)}`, 20) : [];
        // const rCurrentLines = nodeDatum.attributes?.R_Current !== undefined ? splitTextIntoLines(`${truncateToTwoDigits(nodeDatum.attributes.R_Current)}`, 20) : [];
        // const yCurrentLines = nodeDatum.attributes?.Y_Current !== undefined ? splitTextIntoLines(`${truncateToTwoDigits(nodeDatum.attributes.Y_Current)}`, 20) : [];
        // const bCurrentLines = nodeDatum.attributes?.B_Current !== undefined ? splitTextIntoLines(`${truncateToTwoDigits(nodeDatum.attributes.B_Current)}`, 20) : [];
        // const avgCurrentLines = nodeDatum.attributes?.['Avg. Current'] !== undefined ? splitTextIntoLines(`${truncateToTwoDigits(nodeDatum.attributes['Avg. Current'])}`, 20) : [];

        // const parameters = [
        // { name: kWhLines, value: kWhLines },
        // { name: "R_Current", value: rCurrentLines },
        // { name: "Y_Current", value: yCurrentLines },
        // { name: "B_Current", value: bCurrentLines },
        // { name: "Avg. Current", value: avgCurrentLines }
        // ].filter(param => param.value.length > 0);
        const attributes = nodeDatum?.attributes || {};
        const parameters = Object.entries(attributes).filter(([key]) => key !== 'status').map(([key, value]) => {
            const processedValue = truncateToTwoDigits(value);
            const valueLines = splitTextIntoLines(`${processedValue}`, 20);
            return { name: key, value: valueLines };
        }).filter(param => param.value.length > 0);

        const textLines = [
            ...nameLines.map(line => ({ text: line, className: "treeHead" })),
        ];

        const allTextLines = [...textLines];
        const padding = 16;
        const textWidth = maxTextLength + padding + 125
        const textHeight = maxTextLength + padding
        const rectX = -textWidth / 2;
        const rectY = -20

        const hideIcon = nodeDatum.icon === "no";
const hiddedArrow = nodeDatum.arrow === "no";

const tableHeight = parameters.length * 25 + 30; // Adjust the height calculation for the table
const iconHeight = 100;
const tableOffset = tableHeight 
const dynamicYPosition = -(iconHeight + tableOffset); 
        return (
            <g
                onClick={toggleNode}
                onMouseEnter={() => setHoveredNode(nodeDatum)}
                onMouseLeave={() => setHoveredNode(null)}
            >
                {!hideIcon && (
                <foreignObject x="-25" y="-75" width="50" height="50">
                    <i className="d-block d3Ems"></i>
                </foreignObject>
            )}
            {!hiddedArrow && (
                <path d="M -7 0 L 0 7 L 7 0 Z" fill="black" transform="translate(0, -30)" />
            )}
           
                <rect
                    x={rectX}
                    y={rectY}
                    width={textWidth}
                    height={textHeight + 20}
                    strokeWidth="4"
                    fill="#6BC4EC"
                    stroke={color}
                    rx={8}
                    ry={8}
                />
                {allTextLines.map((line, index) => (
                    <text
                        key={index}
                        className={line.className}
                        strokeWidth="1.5"
                        x="0"
                        y={rectY + 25 + index * 20}
                        textAnchor="middle"
                        alignmentBaseline="central"
                        fill="black"
                        stroke="black"
                    >
                        {line.text}
                    </text>
                ))}
                {nodeDatum.attributes && (
                    <>
                        {hoveredNode === nodeDatum && (
                            <foreignObject x={-textWidth / 2} y={dynamicYPosition} width="100%" height="100%">
                            <div className="hover-table">
                                    <table>
                                        {/* <thead>
                                    <tr>
                                        <th style={{ "border-bottom": "1px solid #ccc", padding: "5px", backgroundColor: "#e0e4e9" }}>{nameLines}</th>
                                        <th style={{ "border-bottom": "1px solid #ccc", padding: "5px", backgroundColor: "#e0e4e9" }}></th>
                                    </tr>
                                </thead> */}
                                        <tbody>
                                            {parameters.map((param, index) => (
                                                param.value.map((line, lineIndex) => (
                                                    <tr key={`${index}-${lineIndex}`}>
                                                        <td style={{ "border-right": "1px solid #000", "border-bottom": "1px solid #000", padding: "5px", fontWeight: "bold", textAlign: "right" }}>{lineIndex === 0 ? param.name : ''}</td>
                                                        <td style={{ "font-size": "17px", "border-bottom": "1px solid #000", padding: "5px","font-weight": "900" }}>{line}</td>
                                                    </tr>
                                                ))
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </foreignObject>
                        )}
                    </>
                )}
            </g>
        );
    };

    useEffect(() => {
        if (sldData && sldData.length > 0) {
            const maxTextLength = getMaxTextLength(sldData);
            setMaxTextLength(maxTextLength);
            const additionalSize = maxTextLength + 26
            const additionalSizeBelow = maxTextLength > 10 ? (maxTextLength - 10) * 1 : 0;
            setNodeSize({ x: 120 + additionalSize, y: 170 });
        }
    }, [sldData]);

    const getMaxTextLength = (nodes) => {
        let maxTextLength = 0;
        // 
        const traverse = (node) => {
            if (node.name && node.name.length > maxTextLength) {
                maxTextLength = node.name.length;
            }
            if (node.attributes?.kWh && node.attributes?.kWh.toString().length > maxTextLength) {
                maxTextLength = node.attributes?.kWh.toString().length;
            }
            if (node.attributes?.['Avg. Current'] && node.attributes?.['Avg. Current'].toString().length > maxTextLength) {
                maxTextLength = node.attributes?.['Avg. Current'].toString().length;
            }

            if (node.children) {
                node.children.forEach((child) => {
                    traverse(child);
                });
            }
        };
        nodes.forEach((node) => {
            traverse(node);
        });

        return maxTextLength;
    };
 
    const getZoomLevel = () => {
        const width = window.screen.width;
        const height = window.screen.height;
        if (width === 1366 && height === 768) {
            return 0.65
        } else if (width === 1536 && height === 864) {
            return 0.75;
        } else if (width === 1920 && height === 1080) {
            return 1;
        }
     else if (width === 3840 && height === 2160) {
        return 5;
    }
        else {
            return 1
        }
    };
    const [zoom, setZoom] = useState(getZoomLevel());

    useEffect(() => {
        const handleResize = () => {
            setZoom(getZoomLevel());
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChangeOrientation = (event) => {
        setOrientation(orientation === "vertical" ? "horizontal" : "vertical");
    };

    const containerStyles = {
        width: "100%",
        height: "100vh",
    };

    function formatDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    const handleCheckboxChange = () => {
        // Check if the checkbox is being unchecked
        if (prevCheckboxState && !allCheckboxChecked) {
            // Call handleSubmitSld only when the checkbox is checked
            handleSubmitSld("");
        }
        // Toggle the checkbox state
        setAllCheckboxChecked(!allCheckboxChecked)
        setAllCheckboxChecked(true)
    };

    const handleCriticalBox = async () => {
        if (!criticalCheckboxChecked && allCheckboxChecked) {
            setAllCheckboxChecked(false);
            setCriticalCheckboxChecked(true)
            handleSubmitCritical();
        }
        if (criticalCheckboxChecked && !allCheckboxChecked) {
            setAllCheckboxChecked(true);
            const data = {
                location: selectedLocation,
            };
            await handleSubmitSld(data);
        }
        else if (!criticalCheckboxChecked && !allCheckboxChecked) {
            setCriticalCheckboxChecked(!criticalCheckboxChecked);
            handleSubmitCritical();
        }
    };

    return (
        <>
            {isLoading && (
                <div className="loaderWpr">
                    <div className="loader"></div>
                </div>
            )}
            <div className="wrapper">
                
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mdlContent card pr-card mb30">
                                        <div className="breadcrumbHdr card-header">
                                            <ol className="breadcrumb float-left">
                                                <li className="breadcrumb-item">
                                                    {/* <a href="#">
                                                        {sldDate}
                                                    </a> */}
                                                    <a>Single Line Diagram</a>
                                                </li>
                                            </ol>
                                            <div className="float-right">
                                                <div className="form-inline dataTables_filter">
                                                    <label className="mr10">
                                                        <input type="checkbox"
                                                            name=""
                                                            checked={allCheckboxChecked}
                                                            onChange={handleCheckboxChange}
                                                        /> All
                                                    </label>
                                                    <label className="mr10">
                                                        <input type="checkbox"
                                                            name=""
                                                            checked={criticalCheckboxChecked}
                                                            onChange={handleCriticalBox}
                                                        />
                                                        Critical
                                                    </label>
                                                    <div className=" sldSwitchWpr switch float-right  form-check form-switch">
                                                        <label className="form-check-label" for="flexSwitchCheckDefault">
                                                            <input className="form-check-input" type="checkbox"
                                                                role="switch" id="flexSwitchCheckDefault" onClick={handleChangeOrientation} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sld_legend">
                                            <ul className="">
                                                <li><span className="green"></span> Active</li>
                                                <li><span className="red"></span> Inactive</li>
                                            </ul>
                                        </div>
                                        <div style={containerStyles} ref={containerRef}>
                                            <Tree
                                                // draggable={false}
                                                dimensions={dimensions}
                                                pathFunc='step'
                                                data={sldData}
                                                translate={translate}
                                                nodeSize={nodeSize}
                                                renderCustomNodeElement={renderRectSvgNode}
                                                orientation={orientation}
                                                initialDepth={criticalCheckboxChecked ? undefined : 1} 
                                                scaleExtent={{ min: 0.6, max: 2 }}
                                                zoom={zoom}
                                                pathClassFunc={() => 'bold-link'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Sidebar
                locationData={locationData}
                changeLocation={changeLocation}
                handleSubmitData={SubmitDataWithLoc}
                selectedLocation={selectedLocation}
            />
        </>
    );
}

export default Sld;
