import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const PHSkeleton = () => {
  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                {/* Breadcrumb Skeleton */}
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={150} height={30} />
                    </li>
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={150} height={30} />
                    </li>
                    <li className="breadcrumb-item">
                      <Skeleton variant="text" width={300} height={30} />
                    </li>
                  </ol>
                </div>

                <div className="mdlContent card-body">
                  <div className="row">
                    {/* Last pH recorded Skeleton */}
                    <div className="ph_gauge col-md-2 col-lg-2">
                      <div className="card pr-card mb30">
                        <div className="inrCardHdr">
                          <Skeleton variant="text" width={200} height={30} />
                        </div>
                        <div className="inrCardBody text-center">
                          <Skeleton variant="text" width={100} height={50} />
                          <Skeleton variant="text" width={150} height={20} />
                        </div>
                      </div>
                    </div>

                    {/* Summary Skeleton */}
                    <div className="ph_bar col-md-10 col-lg-10">
                      <div className="card pr-card mb30">
                        <div className="inrCardHdr">
                          <Skeleton variant="text" width={200} height={30} />
                        </div>
                        <div className="inrCardBody">
                          <div className="table-responsive">
                            <table className="table phTable table-bordered">
                              <thead>
                                <tr>
                                  <th>
                                    <Skeleton variant="text" width={80} height={30} />
                                  </th>
                                  {[...Array(7)].map((_, index) => (
                                    <th key={index}>
                                      <Skeleton variant="text" width={50} height={30} />
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {['pH Violations', 'Min pH', 'Max pH'].map((title, index) => (
                                  <tr key={index}>
                                    <th>
                                      <Skeleton variant="text" width={100} height={30} />
                                    </th>
                                    {[...Array(7)].map((_, idx) => (
                                      <td key={idx}>
                                        <Skeleton variant="text" width={50} height={30} />
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* pH Status (Last 2 Hours) Skeleton */}
                  <div className="card pr-card mb30">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={200} height={30} />
                    </div>
                    <div className="inrCardBody">
                      <div className="barChartWpr">
                        <Skeleton variant="rectangular" width="100%" height={300} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PHSkeleton;
