import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import solutionContext from "../context/solution/solutionContext";
import AWS from "aws-sdk";

// Configure the AWS SDK with your credentials and region
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

function VideoPlayer() {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { solution_id, update_solution } = useContext(solutionContext);
  const [solution, setSolution_id] = useState(solution_id);
  const [defaultDate, setDefaultDate] = useState(getCurrentDate());
  const [alerts, setAlerts] = useState([]);
  const [filteredDataTableData, setFilteredDataTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [originalDataTableData, setOriginalDataTableData] = useState([]);
  const [signedUrls, setSignedUrls] = useState([]);
  const [error, setError] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [textContent, setTextContent] = useState("");

  const videoRef = useRef(null);

  useEffect(() => {
    setDefaultDate(getCurrentDate());
  }, []);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }

    if (!solution) {
      const storedSolution = localStorage.getItem("solution");
      if (storedSolution) {
        setSolution_id(storedSolution);
        update_solution(storedSolution);
      }
    }
  }, [solution, update_solution]);

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const fetchTableData = useCallback(async () => {
    if (!token) {
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    setFilteredDataTableData([]);
    setOriginalDataTableData([]);
    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/van_details/vehicles_table`,
          {
            access_token: token,
            user_log_id: userLogId,
          }
        );

        if (response.status === 200 && response.data.success) {
          setFilteredDataTableData(response.data.data?.data || []);
          setOriginalDataTableData(response.data.data?.data || []);
          success = true;
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Something went wrong";
          console.log("Error fetching table data:", errorMessage);
          showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Table API:", error);
        showFlashMessage("Something went wrong");
      }

      attempts += 1;
      if (!success) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
      }
    }

    setIsLoading(false); // Ensure loading state is updated after fetch attempts
  }, [token, userLogId]);
  const selectedVehicle = localStorage.getItem("selectedVehicle");

  const generateSignedUrls = async () => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Prefix: `${selectedVehicle}/`,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      const files = data.Contents.filter(
        (obj) => obj.Key.endsWith(".mp4") || obj.Key.endsWith(".txt")
      );

      const urls = await Promise.all(
        files.map(async (obj) => {
          const signedUrlParams = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            Key: obj.Key,
          };
          const url = await s3.getSignedUrlPromise(
            "getObject",
            signedUrlParams
          );
          return {
            key: obj.Key,
            url,
            lastModified: new Date(obj.LastModified),
          };
        })
      );

      urls.sort((a, b) => b.lastModified - a.lastModified); // Sort by upload time (latest first)
      setSignedUrls(urls);
    } catch (err) {
      console.error("Error listing or generating signed URLs:", err);
      setError(err);
    } finally {
      setIsLoading(false); // Ensure loading state is updated after URL generation
    }
  };

  useEffect(() => {
    // Function to start the interval
    const intervalId = setInterval(generateSignedUrls, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const isVideoRecent = (videoTime) => {
    const currentTime = new Date();
    const timeDifference = (currentTime - videoTime) / 1000; // difference in seconds
    return timeDifference <= 30;
  };

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % signedUrls.length;
      if (videoRef.current) {
        videoRef.current.load(); // reload video element with new source
      }
      return newIndex;
    });
  };

  const fetchTextFileContent = async (url) => {
    try {
      const response = await fetch(url);
      const text = await response.text();
      setTextContent(text);
    } catch (err) {
      console.error("Error fetching text file content:", err);
    }
  };

  useEffect(() => {
    if (signedUrls.length > 0 && signedUrls[0].key.endsWith(".txt")) {
      fetchTextFileContent(signedUrls[0].url);
    }
  }, [signedUrls]);

  return (
    <>
      <div className="content-wrapper">
        {isLoading && (
          <div className="loaderWpr">
            <div className="loader"></div>
          </div>
        )}
       
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item ">
                        <a>
                          Live-Streaming {""} {selectedVehicle}
                        </a>
                      </li>
                    </ol>
                  </div>

                  <div className="mdlContent card-body">
                    <div className="col-md-12 col-lg-12">
                      <div className="card pr-card mb30">
                        <div className="card-body">
                          {signedUrls.length > 0 ? (
                            <div>
                              <ul>
                                {signedUrls.map((item, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      fontWeight:
                                        index === currentVideoIndex
                                          ? "bold"
                                          : "normal",
                                    }}
                                  ></li>
                                ))}
                              </ul>
                              {signedUrls[0].key.endsWith(".mp4") ? (
                                isVideoRecent(signedUrls[0].lastModified) ? (
                                  <video
                                    ref={videoRef}
                                    width="100%" // Adjust width as per your requirement
                                    style={{ display: "block", margin: "auto" }} // Center the video horizontally
                                    controls
                                    autoPlay
                                    onEnded={handleVideoEnd}
                                  >
                                    <source
                                      src={signedUrls[0].url}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <div>No live streaming available</div>
                                )
                              ) : (
                                <div>{textContent}</div>
                              )}
                            </div>
                          ) : (
                            <div>No videos or text files available</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default VideoPlayer;
