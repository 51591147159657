import React, { useEffect, useState, useRef, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Summary_PDF from "../Selco/Summary/MainContent/Summary_PDF";
import solutionContext from "../context/solution/solutionContext";
import { Tooltip } from "react-tooltip";
import "../../App.css";
import { useResetAxes } from '../context/ReactAxesContext';
function Sidebar( props ) {
  const { handleResetAxes } = useResetAxes();
  
 // Use `onResetAxes` function where necessary, e.g., in an event handler
 const handleButtonClick = () => {
  handleResetAxes(); // Call the function from context
  console.log("called from sidebar",handleResetAxes);
  
};

  const handleDownloadPdf = () => {
    props.exportToPDF();
  };
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  Cookies.get("tkData");
  const [userName, setuserName] = useState(null);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [sidebar, setSidebar] = useState([]);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const { solution_id, update_solution } = useContext(solutionContext);
  const handleCloseFirstModal = () => setShowFirstModal(false);
  const handleShowFirstModal = () => setShowFirstModal(true);
  const handleCloseSecondModal = () => setShowSecondModal(false);
  const handleShowSecondModal = () => setShowSecondModal(true);

  const [refreshInterval, setRefreshInterval] = useState(null);
  const [refreshStatus, setRefreshStatus] = useState(null);

  // username
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setuserName(tokenData.name);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    } else {
    }
    // eslint-disable-next-line
  }, []);
  //static log
  useEffect(() => {
    // this code for dynamically add and remove the css classes
    const classNamesToAdd = [
      "hold-transition",
      "sidebar-mini",
      "layout-fixed",
      // "sidebar-collapse",
    ];
    document.body.classList.add(...classNamesToAdd);
    return () => {
      document.body.classList.remove(...classNamesToAdd);
    };
    // eslint-disable-next-line
  }, []);
  //  update logout time api
  const UpLogTime = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_VARIABLE}/iotrms/logout/update_logout_time`,
          {
            user_log_id: userLogId,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
        success = true;
      } catch (error) {
        console.error("Error fetching gateway data:", error);
      }
      attempts++;
    }
  };

  // Remove label and value from local storage
  const removeLocStor = () => {
    localStorage.removeItem("asset");
    localStorage.removeItem("parameter");
    localStorage.removeItem("location");
  };

  // logout
  function logout() {
    UpLogTime();
    if (window.confirm("Do you really want to logout")) {
      removeLocStor();
      Cookies.remove("tkData");
      Cookies.remove("islogin");
      Cookies.remove("welcomeMessageShown");
      setIsLoggedIn(false);
      showFlashMessage("User has been logout.", "danger");
      window.location = "/";
    } else {
    }
  }

  //alert message
  const showFlashMessage = (message, variant) => {
    setErrorMessage(message);
    setShowError(true);
    setFlashMessageVariant(variant);
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  };
  // sidebar
  const getSidebarMenu = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/filter/sidebar_menu`,
          {
            access_token: token,
            user_log_id: userLogId,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
        setSidebar(response.data.data);
        success = true;
        // localStorage.setItem('iot_solution_master_id', response.data.data[0].reports[0].iot_solution_master_id)
        // showFlashMessage("Welcome to Motware", "success");
        setLoader(false);
      } catch (error) {
        console.error("Error fetching sidebar data:", error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available");
    }
  };
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getSidebarMenu();
    }
  }, [token]);
  const isMenuItemPresent = (menuItemName) => {
    return sidebar.some((item) => item.name === menuItemName);
  };
  const [selectedIotSol, setSelectedIotSol] = useState(null);
  const [selectedReports, setSelectedReports] = useState([]);
  const [clickedItem, setClickedItem] = useState(null);

  // Function to handle iot_sol item clicks
  const handleIotSolClick = (iot_sol) => {
    setClickedItem(iot_sol?.iot_sol);
    setSelectedIotSol(iot_sol?.iot_sol);

    // Find and set the reports for the selected iot_sol
    const selectedIotSolData = sidebar.find(
      (item) => item?.iot_sol === iot_sol?.iot_sol
    );
    if (selectedIotSolData) {
      setSelectedReports(selectedIotSolData.reports);
      // update_solution(selectedIotSol)
    } else {
      setSelectedReports([]);
    }
  };
  const localStorageSolution = (props) => {
    localStorage.setItem("solution", props);
  };
  useEffect(() => {
    const defaultIotSol = sidebar.find((item) =>
      item.reports.some((report) => report.cust_report_status === "enable")
    );
    // onload default sholutions should be selected
    if (defaultIotSol !== undefined) {
      handleIotSolClick(defaultIotSol);
      setSelectedIotSol(defaultIotSol?.iot_sol);
    }
    const path = location.pathname
      .replace(/%20/g, " ")
      .replace(/["\\/]/g, "")
      .split(" ");
    const solution = path[0];
    const report = path[1];

    if (
      defaultIotSol !== undefined &&
      solution !== undefined &&
      report !== undefined
    ) {
      const filter_sol = sidebar.filter((item) => item.iot_sol === solution)[0];
      handleIotSolClick(filter_sol);
      setSelectedIotSol(filter_sol?.iot_sol);
    }
  }, [sidebar]);
  useEffect(() => {
    // Parse the URL path to get solution and report names
    const path = location.pathname
      .replace(/%20/g, " ")
      .replace(/["\\/]/g, "")
      .split(" ");

    // Insert a dash after the first word
    const formatted = path.reduce((acc, part, index) => {
      if (index === 1) {
        return `${acc} - ${part}`;
      }
      return `${acc} ${part}`;
    });

    document.title = formatted;

    const solutionName = path[0];
    const reportName = path
      .slice(1)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(" ");
    // const solutionName = path[0];
    // const reportName = path[1];

    if (solutionName && reportName) {
      const selectedSolution = sidebar.find(
        (item) => item.iot_sol === solutionName
      );
      if (selectedSolution) {
        // Find the report based on reportName
        const selectedReport = selectedSolution.reports.find(
          (report) => report.name === reportName
        );

        if (selectedReport) {
          // Update state with the selected report details
          // setSelectedReport(selectedReport);
          setRefreshInterval(selectedReport.refresh_interval_min * 60 * 1000);
          setRefreshStatus(selectedReport.refresh);
        } else {
          // console.error(`Report with name ${reportName} not found in solution ${solutionName}`);
        }
      } else {
        // console.error(`Solution with name ${solutionName} not found`);
      }
    }
  }, [sidebar, location.pathname]);

  const [selectedReport, setSelectedReport] = useState(null);
  // Function to handle report item clicks
  const handleReportClick = (report, sol) => {
    let updatedSol = sol;

    // Check if the solution is "TX-IQ" and update it to "Transformer"
    if (sol === "TX-IQ") {
      updatedSol = "Transformer";
    }

    // Check if the solution is "SL-IQ" and update it to "SELCO"
    if (sol === "SL-IQ") {
      updatedSol = "SELCO";
    }
    // document.title = [updatedSol + " "+ report.name]
    if (report.cust_report_status === "enable") {
      setSelectedReport(report);
      setSelectedIotSol(updatedSol);
      setClickedItem(updatedSol);
      getSidebarLogs(
        report.iot_solution_master_id,
        report.iot_solution_master_reports_id,
        report.cust_report_status
      );
      localStorageSolution(report.iot_solution_master_id);
      update_solution(report.iot_solution_master_id);
      navigate(`/${updatedSol} ${report.name}`);
      setRefreshInterval(report.refresh_interval_min * 60 * 1000); // Convert minutes to milliseconds
      setRefreshStatus(report.refresh);
    } else {
      getSidebarLogs(
        report.iot_solution_master_id,
        report.iot_solution_master_reports_id,
        report.cust_report_status
      );
      showFlashMessage("This report is not available", "danger");
    }
  };

  // log api for sidebar
  const getSidebarLogs = async (
    iot_solution_master_id,
    iot_solution_master_reports_id,
    status
  ) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/login/user_activity_logs`,
          {
            access_token: token,
            report_status: status,
            iot_solution_master_id: iot_solution_master_id,
            iot_solution_master_reports_id: iot_solution_master_reports_id,
            user_log_id: userLogId,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
        success = true;
      } catch (error) {
        console.error("Error fetching gateway data:", error);
      }
      attempts++;
    }
  };
  // New Alert
  // alerts
  const [alerts, setAlerts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const handleLoadMoreClick = () => {
    setLoader(true);
    try {
      getAlerts(true);
    } catch (error) {
      console.error("Error fetching alerts data:", error);
      setLoader(false);
    }
  };
  const getAlerts = async (loadMore = false) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        setLoader(true);
        const requestData = {
          access_token: token,
          page_no: pageNo,
          user_log_id: userLogId,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/alerts/alerts_data`,
          requestData,
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );

        if (loadMore) {
          setAlerts((prevAlerts) => [...prevAlerts, ...response.data.data]);
          success = true;
        } else {
          setAlerts([...alerts, ...response.data.data]);
          success = true;
        }

        if (response.data.data) {
          // removed .length > 0
          setPageNo(pageNo + 1);
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching alerts data:", error);
      }
      attempts++;
    }
  };
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getAlerts();
    }
  }, [token]);
  //readable date format
  function formatDateTime(dateTimeString) {
    const data = new Date(new Date(dateTimeString).toISOString());
    const result = `${String(data.getUTCDate()).padStart(2, "0")}-${String(
      data.getUTCMonth() + 1
    ).padStart(2, "0")}-${data.getUTCFullYear()} ${String(
      data.getUTCHours()
    ).padStart(2, "0")}:${String(data.getUTCMinutes()).padStart(
      2,
      "0"
    )}:${String(data.getUTCSeconds()).padStart(2, "0")}`;
    return result;
  }
  //handle search
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };
  const filteredAlerts = alerts.filter((alert) => {
    const formattedDate = formatDateTime(alert.datetime).toLowerCase();
    const parameterValueString = String(alert.parameter_value).toLowerCase();

    return (
      alert.message.toLowerCase().includes(searchQuery) ||
      alert.assset.toLowerCase().includes(searchQuery) ||
      alert.parameter_name.toLowerCase().includes(searchQuery) ||
      parameterValueString.includes(searchQuery) ||
      formattedDate.includes(searchQuery)
    );
  });
  //download xls
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const downloadAlertData = () => {
    if (filteredAlerts.length === 0) {
      showFlashMessage("No data to download.", "danger");
      return;
    }

    const dataForExport = filteredAlerts.map((alert) => ({
      "Date Time": formatDateTime(alert.datetime),
      Message: alert.message,
      Asset: alert.assset,
      "Parameter Name": alert.parameter_name,
      Value: alert.parameter_value,
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Alert Data");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "Alerts" + fileExtension);
    // showFlashMessage("No data to download.", "danger");
  };
  // disable icon if there is no data
  const isXlsIconDisabled = filteredAlerts.length === 0;
  // Function to check if a menu item should be active
  const isMenuItemActive = (menuItemName, iot_sol) => {
    const path = "/" + iot_sol + " " + menuItemName.name;
    if (location.pathname.startsWith(path)) {
      localStorageSolution(menuItemName.iot_solution_master_id);
      // update_solution(menuItemName.iot_solution_master_id);  //this line give the error sometime
    }
    return location.pathname.startsWith(path);
  };
  const isSolItemActive = (iot_sol) => {
    const path = location.pathname.split(" ")[0];
    if ("/" + iot_sol === path) {
      return path;
    } else {
      return path;
    }
    // if (location.pathname.startsWith(path)){
    // update_solution(menuItemName.iot_solution_master_id);
    // }
    // return location.pathname.startsWith(path);
  };

  // refresh page
  useEffect(() => {
    if (refreshStatus === "enable" && refreshInterval) {
      const refreshPage = () => {
        window.location.reload();
      };
      const timeoutId = setTimeout(refreshPage, refreshInterval);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [refreshInterval, refreshStatus]);

  // left side on click icon background color changed
  const [selectedNames, setSelectedNames] = useState([]);
  const handleIotSolClic = (iotSolName) => {
    // Toggle the selection of the name
    if (selectedNames.includes(iotSolName)) {
      setSelectedNames(selectedNames.filter((name) => name !== iotSolName));
    } else {
      setSelectedNames([...selectedNames, iotSolName]);
    }
    setSelectedIotSol(iotSolName);
  };
  // const [selectedIotSol, setSelectedIotSol] = useState(null);
  // const handleIotSolClick = (iotSolName) => {
  //   setSelectedIotSol(iotSolName);
  // };

  return (
    <>
      {/* <!-- Navbar --> */}

      <nav className="main-header navbar navbar-expand navbar-light fixed-top">
        {/* <!-- Left navbar links --> */}
        {/* <!-- Brand Logo --> */}
        <a className="brand-link">
          <img
            src="assets/images/motware_logo2.png"
            alt="motwareLogo"
            className="brand-image"
          />
          <span className="brand-text font-weight-medium">Motware</span>
        </a>
        {/* <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" role="button">
              <i className="fa fa-bars"></i>
            </a>
          </li>
        </ul> */}
        {showError && (
          <Alert
            variant={flashMessageVariant === "success" ? "success" : "danger"}
            onClose={() => setShowError(false)}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}

        {/* <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto">
          <li className="custNameBtn nav-item dropdown">
            <a
              className="btn btn-secondary dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              {userName}
            </a>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <a href="#" className="dropdown-item" onClickCapture={logout}>
                <i className="fa fa-lock mr-2"></i>
                <span className="text-muted text-sm">Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {/* <!-- /.navbar --> */}
      <div className="sidebarLeft ">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" role="button" onClick={handleButtonClick}>
              <i className="fa fa-bars"></i>
            </a>
          </li>
        </ul>

        <ul>
          {sidebar.map((item) => {
            const isClicked = clickedItem === item.iot_sol;
            const updatedIotSol =
              item.iot_sol === "Transformer"
                ? "TX-IQ"
                : item.iot_sol === "SELCO"
                ? "SL-IQ"
                : item.iot_sol;

            return (
              <li
                className={`nav-item dropdown dropright ${
                  isSolItemActive(updatedIotSol) ? "show" : ""
                }`}
                key={updatedIotSol}
              >
                <a
                  className={`nav-link dropdown-toggle ${
                    isSolItemActive(updatedIotSol) ? "active" : ""
                  }`}
                  onClick={() => handleIotSolClick(item)}
                >
                  <p
                    onClick={removeLocStor}
                    className={`fa d-block ${isClicked ? "clicked" : ""}`}
                  >
                    <i className={`d-block ${item?.iot_sol.toLowerCase()}`}></i>
                  </p>
                  <span>{updatedIotSol}</span>
                </a>
                {!isClicked && (
                  <ul className="dropdown-menu">
                    {item.reports.map((report) => (
                      <li
                        key={report.iot_solution_master_reports_id}
                        onClick={removeLocStor}
                      >
                        <a
                          className={`dropdown-item ${
                            isMenuItemActive(report, updatedIotSol)
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleReportClick(report, updatedIotSol, isClicked)
                          }
                        >
                          <span>{report.alias_name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {/* <!-- Main Sidebar Container --> */}
      <aside className="main-sidebar">
        {/* <!-- Sidebar --> */}
        <div div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {selectedReports.map((report) => (
                <li
                  key={report.iot_solution_master_reports_id}
                  className="nav-item"
                >
                  <NavLink
                    to={`${selectedIotSol} ${report.name}`}
                    className={`nav-link ${
                      // Add 'active' class to the selected report
                      isMenuItemActive(report, selectedIotSol) ? "active" : ""
                    }`}
                    onClick={() => handleReportClick(report, selectedIotSol)}
                  >
                    <p>{report.alias_name}</p>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>

        {/* <!-- /.sidebar --> */}
      </aside>

      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#alert"
              data-tooltip-id="tooltip"
              data-tooltip-content="Alert"
            >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
            <Tooltip
              id="tooltip"
              place="bottom"
              type="dark"
              effect="solid"
              style={{
                position: "absolute",
                marginLeft: "-10px",
                padding: "10% 20% 10% 20%",
              }}
            />
          </li>
          {location.pathname === "/Trend" && (
            <li className="nav-item">
              <a className="nav-link" onClick={handleShowFirstModal}>
                <i className="fa fa-filter nav-icon"></i>
              </a>
            </li>
          )}
          {/* {location.pathname === "/Summary" && (
            <li className="nav-item">
              <Link
                to="/pdf"
                className="nav-link"
                target="_blank"
                id="summary_pdf_icon"
              >
                <i className="fa fa-file-pdf-o nav-icon"></i>
              </Link>
            </li>
          )} */}
          {location.pathname === "/Summary" && (
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="modal"
                data-bs-target="#pdf-modal"
                // onClick={handleDownloadPdf}
                title="Download Pdf"
              >
                <i className="fa fa-file-pdf-o nav-icon"></i>
              </a>
            </li>
          )}
          {/* {location.pathname === "/trend" && (
            <li className="nav-item">
              <Link
                to="/trend-pdf"
                className="nav-link"
                target="_blank"
                id="trend_pdf_icon"
              >
                <i className="fa fa-file-pdf-o nav-icon"></i>
              </Link>
            </li>
          )} */}

          {/* <li className="nav-item">
            <a className="nav-link" data-bs-toggle="modal" data-bs-target="#alert" >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
          </li> */}
        </ul>
      </div>

      {/* <!-- Modal Alert start--> */}

      <div
        className="modal right fade alertModal"
        id="alert"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="alert"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="">
                <b> Alerts</b>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row form-inline mb10">
                <div id="" className="col-md-10 col-lg-10 col-xl-10">
                  <label className="float-left">
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Search..."
                      aria-controls=""
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </label>
                </div>

                {isXlsIconDisabled ? (
                  <a
                    className="col-md-2 col-lg-2 col-xl-2"
                    alt="Download XLS"
                    onClick={downloadAlertData}
                    style={{ pointerEvents: "none", opacity: 0.5 }}
                  >
                    <i className="fa fa-file-excel-o float-right"></i>
                  </a>
                ) : (
                  <a
                    className="col-md-2 col-lg-2 col-xl-2"
                    alt="Download XLS"
                    onClick={downloadAlertData}
                  >
                    <i className="fa fa-file-excel-o float-right"></i>
                  </a>
                )}
              </div>
              <div
                className={loader ? "loader" : ""}
                style={{ position: "sticky", left: "40%" }}
              ></div>
              {filteredAlerts.length === 0 ? (
                <div className="text-center">
                  <p>Data not available...</p>
                </div>
              ) : (
                filteredAlerts.map((alert, index) => (
                  <div key={index} className="card pr-card mb30">
                    <div className="card-body">
                      <p className="">
                        <b>{formatDateTime(alert.datetime)}</b>
                      </p>
                      <hr></hr>
                      <p>
                        {alert.location} - {alert.assset}
                      </p>
                      <p>{alert.message}</p>
                      {alert.parameter_name &&
                        (alert.parameter_value ||
                          alert.parameter_value === 0) &&
                        alert.parameter_name !== "-" && (
                          <p>
                            {alert.parameter_name} - {alert.parameter_value}
                          </p>
                        )}
                    </div>
                  </div>
                ))
              )}
              <div id="" className="text-center">
                <div className="ftrBtn">
                  <div className="form-group">
                    <button
                      type=""
                      name=""
                      value="Load More"
                      className="btn btn-primary btn-block"
                      data-disable-with=""
                      onClick={handleLoadMoreClick}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for pdf */}
      <div
        className="modal"
        id="pdf-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="pdf-modal"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Summary_PDF />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
