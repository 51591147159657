/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import MainContent from "./MainContent/MainContent";
import { Link } from "react-router-dom";

const Summary = () => {


  return (
    <>
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <MainContent />
        </div>
      </div>
    </>
  );
};

export default Summary;
