import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const EnergyConsumptionSkeleton = () => {
  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="mdlContent card pr-card mb30">
            {/* Breadcrumb Skeleton */}
            <div className="breadcrumbHdr card-header">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={150} height={30} />
                </li>
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={150} height={30} />
                </li>
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={300} height={30} />
                </li>
              </ol>
            </div>

            <div className="mdlContent card-body">
              <div className="col-md-12 col-lg-12">
                <div className="row">
                  {/* Grid/Solar Utilization (Pie Chart) Skeleton */}
                  <div className="paramGraph col-md-6 col-lg-6">
                    <div className="chartWpr card pr-card mb30">
                      <div className="card-body">
                        <p></p>
                        <Skeleton variant="rectangular" width="100%" height={400} />
                      </div>
                    </div>
                  </div>

                  {/* Energy Consumption Parameters Skeleton */}
                  <div className="paramWpr col-md-6 col-lg-6">
                    <div className="row">
                      {[...Array(10)].map((_, index) => (
                        <div className="paramBox col-md-6 col-lg-6" key={index}>
                          <div className="card pr-card mb30">
                            <div className="card-body">
                              <p></p>
                              <Skeleton variant="text" width={100} height={63} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EnergyConsumptionSkeleton;
