/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import axios from "axios"
import "./Diagram.css"
import Cookies from 'js-cookie';


function SinglePhase(props) {

  const { data, selectedLocation, selectedAsset } = props;
  const location = JSON.parse(localStorage.getItem('location'));
  const asset = JSON.parse(localStorage.getItem('asset'));

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timePeriod = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour format to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the time as hh:mm AM/PM
    const timeString = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${timePeriod}`;

    return `${day} ${month} ${year} ${timeString}`;
  }

  const truncateToThreeDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = parseFloat(Number(value).toFixed(3));
      return isNaN(truncatedValue) ? '-' : truncatedValue;
    }
    return '';
  };

  // date format for breadcrums
  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  return (
    <>
      {/* <div className="wrapper"> */}
      {/* <!-- Content Wrapper. Contains page content --> */}
      {/* <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="mdlContent card pr-card mb30"> */}
      {/* <div className="breadcrumbHdr card-header">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"> */}
      {/* <a >{asset.label}</a> */}
      {/* <a>{selectedLocation.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a>{selectedAsset.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a >
                            {formatDate(data.sensor_data.datetime)} */}
      {/* 2 Aug 2023 | 7:15:39 PM */}
      {/* </a>
                        </li>
                      </ol>
                    </div> */}
      {/* <div className="mdlContent card-body"> */}
      <h4 className='text-center mb20 boldText'>
        {/* Single Phase */}
      </h4>
      <div className="tree">
        <ul>
          <li>
            <span className='arrow2'></span>
            <span className='arrow3'></span>
            <span className='arrow4'></span>
            <span className='arrow5'></span>
            <span className='arrow6'></span>
            <span className='arrow7'></span>
            <span className='arrow8'></span>
            <span className='arrow9'></span>
            <span className='arrow10'></span>
            <span className='arrow11'></span>
            <span className='arrow12'></span>
            <span className='arrow13'></span>
            <span className='righttext'>{truncateToThreeDigits(data?.sensor_data['Global_Horizontal_Solar_Irradiance_Watt/sq_meter'] ?? "-")} W/sq.m</span>
            <span className='righttext2'>{truncateToThreeDigits(data?.sensor_data.PV_Panel_Voltage_V ?? "-")} V</span>
            <span className='righttext3'>{truncateToThreeDigits(data?.sensor_data.Inverter_Input_Voltage_V ?? "-")}V</span>
            <span className='righttext4'>{truncateToThreeDigits(data?.sensor_data.Grid_Input_Voltage_V ?? "-")}V</span>
            <div className='mainTitle'><a href="#">Battery</a> </div>

            <ul className='line'>

              <li>

                <ul>

                  <span className='stext'>Charging {truncateToThreeDigits(data?.sensor_data.Battery_Charging_Voltage_V ?? "-")}</span> <a className='leftTitle downLine dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Battery_Charging_Power_kW ?? "-")}</a>
                  &nbsp;&nbsp;&nbsp;
                  <a className='rightTitle downLine dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Battery_Discharging_Power_kW ?? "-")}</a> <span className='stext'>Discharging {truncateToThreeDigits(data?.sensor_data.Battery_Discharging_Voltage_V ?? "-")}</span>
                  <ul>
                    <a className='rightLine downLine' href="#" >Solar PCU</a>
                    <div className='leftLine arrow dataWidth'>{truncateToThreeDigits(data?.sensor_data.Inverter_Input_Active_Power_kW ?? "-")}</div>
                    <div className='leftLine2 dataWidth'>{truncateToThreeDigits(data?.sensor_data.Grid_Input_Active_Power_kW ?? "-")}</div>
                    <div className='leftupN1' >Main Control Switch</div>
                    <div className='leftupR' >Grid<br />Input</div>
                    <div className='leftupL'>Other<br />Input</div>
                    <div className='leftupL2 dataWidth'> {truncateToThreeDigits(data?.sensor_data.DG_Input_Active_Power_kW ?? "-")} </div>
                    <div className='leftupL3'>  </div>
                    <div className='leftupL4'>  </div>
                    <div className='leftLine3 dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Total_Non_Solar_load_Active_Power_kW ?? "-")}</div>
                    <div className='leftLine4' href="#">Distribution Box</div>
                    <div className='leftLine5 dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Total_Non_Solar_load_Active_Power_kW ?? "-")}</div>
                    <div className='leftLine6' href="#">Non<br />Solar<br />Loads</div>
                    <div className='rightbox' href="#">Solar Panel</div>
                    <a className='rightbox2 dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.PV_Panel_Power_kW ?? "-")}</a>
                    <div className='rightbox3 dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Potential_Solar_Generation_kW ?? "-")}</div>
                    <ul>
                      <a className='m1 downLine dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Inverter_Output_Active_Power_kW ?? "-")}</a><span className='stext mspace'> {truncateToThreeDigits(data?.sensor_data.Inverter_Output_Voltage_V ?? "-")}v</span>

                      <ul>
                        <a className='m2 downLine downLine' href="#">Changeover Switch</a>
                        <ul>

                          <a className='m1 downLine dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Change_Over_Output_Active_Power_kW ?? "-")}</a><span className='stext mspace'> {truncateToThreeDigits(data?.sensor_data.Change_Over_Output_Voltage_V ?? "-")}v</span>
                          <ul>

                            <a className='m2 downLine' href="#">Distribution Box</a>
                            <ul>

                              <a className='m4 downLine dataWidth' href="#">{truncateToThreeDigits(data?.sensor_data.Change_Over_Output_Active_Power_kW ?? "-")}</a>
                            </ul>


                            <a className='m3' href="#">Solar <br />Loads</a>


                          </ul>
                        </ul>

                      </ul>

                    </ul>

                  </ul>
                </ul>
              </li>

            </ul>
          </li>
        </ul>
      </div>
      {/* </div> */}
      {/* </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>

  )
}

export default SinglePhase