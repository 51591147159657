import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const DailySummarySkeleton = () => {
  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="mdlContent card pr-card mb30">
            {/* Breadcrumb Skeleton */}
            <div className="breadcrumbHdr card-header">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={100} height={30} />
                </li>
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={100} height={30} />
                </li>
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={200} height={30} />
                </li>
              </ol>
            </div>

            <div className="dlySmryPg mdlContent card-body">
              {/* Energy Consumption Skeleton */}
              <div className="row">
                <div className="col-md-12 col-lg-9">
                  <div className="energyWpr card pr-card mb30">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={200} height={30} />
                    </div>
                    <div className="inrCardBody">
                      <div className="row">
                        <div className="col-md-3 col-lg-3">
                          <Skeleton variant="text" width={150} height={30} />
                          <Skeleton variant="rectangular" width="100%" height={50} />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <Skeleton variant="text" width={150} height={30} />
                          <Skeleton variant="rectangular" width="100%" height={50} />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <Skeleton variant="text" width={150} height={30} />
                          <Skeleton variant="rectangular" width="100%" height={50} />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <Skeleton variant="text" width={150} height={30} />
                          <Skeleton variant="rectangular" width="100%" height={50} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Power Consumption Skeleton */}
                  <div className="powerWpr card pr-card mb30">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={200} height={30} />
                    </div>
                    <div className="inrCardBody">
                      <div className="row">
                        {Array.from(new Array(6)).map((_, index) => (
                          <div className="col-md-2 col-lg-2" key={index}>
                            <Skeleton variant="text" width={150} height={30} />
                            <Skeleton variant="rectangular" width="100%" height={50} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Relay Status Skeleton */}
                <div className="col-md-12 col-lg-3">
                  <div className="card pr-card mb30">
                    <div className="relayTbl">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={150} height={30} />
                      </div>
                      <div className="inrCardBody">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <Skeleton variant="text" width={100} height={20} />
                              <Skeleton variant="text" width={100} height={20} />
                            </tr>
                          </thead>
                          <tbody>
                            {Array.from(new Array(5)).map((_, index) => (
                              <tr key={index}>
                                <td><Skeleton variant="text" width={100} height={20} /></td>
                                <td><Skeleton variant="text" width={100} height={20} /></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Voltage (V) Skeleton */}
              <div className='row'>
                <div className="col-md-12 col-lg-4">
                  <div className="voltageCard card pr-card mb30">
                  <div className="inrCardHdr">
                    <Skeleton variant="text" width={150} height={30} />
                    </div>
                    <div className="inrCardBody">
                      <Skeleton variant="rectangular" width="100%" height={270} />
                      
                    </div>
                  </div>
                </div>

                {/* Current (A) Skeleton */}
                <div className="col-md-12 col-lg-4">
                  <div className="currentCard card pr-card mb30">
                  <div className="inrCardHdr">
                    <Skeleton variant="text" width={150} height={30} />
                    </div>
                    <div className="inrCardBody">
                      <Skeleton variant="rectangular" width="100%" height={270} />
                    </div>
                  </div>
                </div>

                {/* Temperature Skeleton */}
                <div className="col-md-12 col-lg-4">
                  <div className="tmpTbl card pr-card">
                  <div className="inrCardHdr">
                    <Skeleton variant="text" width={150} height={30} />
                    </div>
                    <div className="inrCardBody">
                      <Skeleton variant="rectangular" width="100%" height={150} />
                    </div>
                  </div>
                   {/* Power Factor Skeleton */}
              <div className="pFactor card pr-card mb30">
              <div className="inrCardHdr">
                <Skeleton variant="text" width={150} height={30} />
                </div>
                <div className="inrCardBody">
                  <Skeleton variant="rectangular" width="100%" height={120} />
                </div>
              </div>
                </div>
                
              </div>

             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DailySummarySkeleton;
