import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ExcelJS from 'exceljs';
import Sidebar from './Sidebar';
import moment from 'moment';
import { Alert } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import EnergySummarySkeleton from './EnergySummarySkeleton';

function EnergySummary() {
    const [fromDate, setFromDate] = useState(new Date(moment(new Date()).subtract(1, 'days')));
    const [toDate, setToDate] = useState(new Date(moment(new Date())));
    const [resultant, setResultant] = useState({ columns: [], data: [] });
    const [filteredData, setFilteredData] = useState({ columns: [], data: [] });
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
    const [userLogId, setUserLogId] = useState("")
    const [token, setToken] = useState("");
    const [newStartDate, setNewStartDate] = useState()
    const [newEndDate, setNewEndDate] = useState()
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // getting Token
    useEffect(() => {
        const tkData = Cookies.get("tkData");
        if (tkData) {
            const tokenData = JSON.parse(tkData);
            setToken(tokenData.token);
            setUserLogId(tokenData.userLogId)
        }

    }, []);

    useEffect(() => {
        setIsLoading(true)
        // Define a function to fetch data from the API
        const fetchData = async () => {
            if (!token) {
                // Return early if token is not available
                return;
            }

            let attempts = 0;
            const maxAttempts = 3;
            let success = false;

            while (attempts < maxAttempts && !success) {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/energy_summary_table`,
                        {
                            access_token: token,
                            user_log_id: userLogId,
                            iot_sol: "EMS"
                        }
                    );

                    // Check if the request was successful
                    if (response.data.success) {
                        // const responseData = { "success": true, "data": { "columns": ["Date", "A 03"], "rows": [["2024-03-20", ""], ["2024-03-19", 117374976.12345], ["2024-03-18", ""], ["2024-03-16", ""], ["2024-03-15", ""], ["2024-03-14", ""]] } }

                        setResultant(transformData(response.data))
                        success = true;
                        setNewStartDate(breadcrum_date_converter(response.data.start_date));
                        setNewEndDate(breadcrum_date_converter(response.data.end_date));
                        // showFlashMessage("Data Successfully Updated", "success");
                    }
                    else if (response.data.success === false) {
                        // showFlashMessage("Data Not Available");
                        setResultant(transformData(response.data))
                        // showFlashMessage("Data Successfully Updated", "success");
                    }
                    else {
                        // showFlashMessage("Data Not Available");
                        console.error('Failed to fetch data');
                    }
                    setIsLoading(false)
                } catch (error) {
                    // Handle any network errors
                    // showFlashMessage("Data Not Available");
                    console.error('Network error:', error);
                    setIsLoading(false)
                }
                attempts++;
            }
            if (!success) {
                showFlashMessage("Data Not Available");
            }
        };

        // Call the fetchData function when the component mounts
        if (token !== null && token !== undefined && token !== "") {
            fetchData();
        }

    }, [token]); // this effect runs only once

    useEffect(() => {
        setFilteredData(resultant)
        return () => {
        }
    }, [resultant])


    useEffect(() => {
        searchTable();
    }, [searchText]);

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    const breadcrum_date_converter = (date) => {
        let dateString = date;
        let [datePart, timePart] = dateString.split(' ');
        let [day, month, year] = datePart.split('-');
        let [hours, minutes] = timePart.split(':');
        let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
        return myDate;
    }

    const breadcrum_date_converter_after_submit = (date) => {
        let dateString = date;
        let [datePart, timePart] = dateString.split(' ');
        let [year, month, day] = datePart.split('-');
        let [hours, minutes] = timePart.split(':');
        let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
        return myDate;
    }



    const searchTable = () => {
        if (searchText === undefined || searchText === null || searchText === "" || !searchText) {
            setFilteredData(resultant);
        } else if (resultant && resultant.rows) {
            const filteredResult = resultant.rows.filter((row) => {
                row = Object.values(row);
                const rowValues = row.map((item) =>
                    typeof item === "string" ? item.toLowerCase() : item.toString()
                );
                const searchTerm = searchText.toLowerCase();
                return rowValues.some((value) => value.includes(searchTerm));
            });
            setFilteredData((prevOptions) => ({
                ...prevOptions,
                rows: filteredResult,
            }));
        }
    };

    const exportToCSV = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('data');
        const customHeadings = filteredData.columns.map((column) => column.name);

        worksheet.addRow(customHeadings);

        filteredData.rows.forEach((row) => {
            const rowData = customHeadings.map((heading) => {
                const value = row[heading.toLowerCase()];
                return typeof value === 'number' ? truncateToTwoDigits(value) : value;
            });
            worksheet.addRow(rowData);
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const dataBlob = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(dataBlob, "Ems Energy Summary" + fileExtension);
        });
    };
    // const exportToCSV = () => {
    //     // Step 1: Make headers unique
    //     const customHeadings = filteredData.columns.map((column) => column.name);
    //     // Step 2: Prepare the data for Excel export
    //     const filteredDataExcel = filteredData.rows.map((row) => {
    //         row = Object.values(row);
    //         const filteredItem = {};
    //         row.forEach((item, index) => {
    //             const heading = customHeadings[index];
    //             filteredItem[heading] = typeof item === "number" ? truncateToTwoDigits(item) : item;
    //         });
    //         return filteredItem;
    //     });
    //     // Step 3: Create the worksheet and workbook
    //     const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //     const dataBlob = new Blob([excelBuffer], { type: fileType });

    //     // Step 4: Save the file
    //     FileSaver.saveAs(dataBlob, "Ems Energy Summary" + fileExtension);
    // };


    const [alerts, setAlerts] = React.useState([]);
    // Function to show flash message with a delay
    const showFlashMessage = (message, variant = "danger") => {
      const newAlert = {
        message: message,
        variant: variant,
        id: new Date().getTime(),
      };
  
      setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
    };
  
    useEffect(() => {
      if (alerts.length > 0) {
        const timeoutId = setTimeout(() => {
          setAlerts((prevAlerts) => prevAlerts.slice(1));
        }, 2000);
  
        return () => clearTimeout(timeoutId);
      }
    }, [alerts]);

    // ToolTip component with style and value props
    const ToolTip = ({ value, style }) => (
        <div style={style} title={value}>{value}</div>
    );

    // truncate to two decimal limt
    const truncateToTwoDigits = (value) => {
        if (!isNaN(value)) {
            return Math.trunc(value * 100) / 100;
        }
        return value;
    };
    const transformData = (resp) => {
        let rows = resp.data.rows;
        let columns = resp.data.columns;

        // Ensure unique column names
        const uniqueColumns = columns.map((col, index) => {
            const duplicates = columns.filter(c => c === col).length > 1;
            return duplicates ? `${col}` : col;
        });

        const finalColumns = uniqueColumns.map((value) => {
            return {
                name: value,
                selector: (row) => row[value.toLowerCase().replace(/_/g, " ")],
                sortable: true,
                cell: row => <div className="custom-cell">{row[value.toLowerCase()] !== "" ? truncateToTwoDigits(row[value.toLowerCase()]) : 0}</div>
            };
        });

        const finalRows = rows.map(row => {
            const rowData = {};
            uniqueColumns.forEach((columnName, index) => {
                rowData[columnName.toLowerCase().replace(/_/g, " ")] = row[index] !== "" ? row[index] : 0;
            });
            return rowData;
        });

        return { columns: finalColumns, rows: finalRows };
    };


    const convertDateCloneApi = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
        const day = String(inputDate.getDate()).padStart(2, "0");
        const hours = String(inputDate.getHours()).padStart(2, "0");
        const minutes = String(inputDate.getMinutes()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        return formattedDate;
    };

    const fetchDataWithDate = async (props) => {
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;

        while (attempts < maxAttempts && !success) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/energy_summary_table`,
                    props
                );
                // Check if the request was successful
                if (response.data.success) {
                    setResultant(transformData(response.data))
                    success = true;
                    // setNewStartDate(breadcrum_date_converter(response.data.start_date));
                    // setNewEndDate(breadcrum_date_converter(response.data.end_date));
                    // showFlashMessage("Data Successfully Updated", "success");
                }
                else if (response.data.success === false) {
                    // showFlashMessage("Data Not Available");
                    setResultant(transformData(response.data))
                    // showFlashMessage("Data Successfully Updated", "success");
                }
                else {
                    // showFlashMessage("Data Not Available");
                    console.error('Failed to fetch data');
                }
                setIsLoading(false)
            } catch (error) {
                // Handle any network errors
                // showFlashMessage("Data Not Available");
                console.error('Network error:', error);
            }
            attempts++;
        }
        if (!success) {
            showFlashMessage("Data Not Available");
        }
    };

    const updateFromDate = (newFromDate) => {
        setFromDate(newFromDate);
        setNewStartDate("");
    };
    const updateToDate = (newToDate) => {
        setToDate(newToDate);
        setNewEndDate("");
    };

    // Custom styles for DataTable
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                whiteSpace: 'normal',// Custom font weight for the table header
            },
        },
        headRows: {
            style: {
                whiteSpace: 'normal',
            },
        }
    };

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL",
    };

    const handleSubmitData = (props) => {
        let data = {
            access_token: token,
            user_log_id: userLogId,
            start_date: convertDateCloneApi(props.fromDate),
            end_date: convertDateCloneApi(props.toDate),
            iot_sol: "EMS"
        }

        setNewStartDate(breadcrum_date_converter_after_submit(convertDateCloneApi(fromDate)))
        setNewEndDate(breadcrum_date_converter_after_submit(convertDateCloneApi(toDate)))
        fetchDataWithDate(data);
    }

    const NoDataComponent = () => (
        <>
            <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        {filteredData.columns.map((column) => (
                            <th key={column.name}>{column.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={filteredData.columns.length}>
                            <div className="text-center">
                                <p>No Data Available</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );


    return (
        <>
            {isLoading && <EnergySummarySkeleton/>}
            
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <div className="mdlContent card pr-card mb30">
                                    <div className="breadcrumbHdr card-header">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="#">
                                                    {" "}
                                                    {newStartDate ? (
                                                        <a href="#">{newStartDate}</a>
                                                    ) : (
                                                        <a href="#">{formatDate(fromDate)}</a>
                                                    )}
                                                    &nbsp;-&nbsp;
                                                    {newEndDate ? (
                                                        <a href="#">{newEndDate}</a>
                                                    ) : (
                                                        <a href="#">{formatDate(toDate)}</a>
                                                    )}
                                                </a>
                                            </li>
                                        </ol>
                                    </div>

                                    <div className="mdlContent card-body col-md-12 col-lg-12 mt-2">
                                        <div className="card pr-card mt-2">
                                            <div className="card-header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                                        <div className="form-inline dataTables_filter">
                                                            <div id="" className="mr10">
                                                                <label>
                                                                    <input
                                                                        type="search"
                                                                        // value={searchText}
                                                                        className="form-control form-control-sm"
                                                                        placeholder="Search..."
                                                                        onChange={(e) => {
                                                                            setSearchText(e.target.value);
                                                                        }}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="input-group pr-form-group" >
                                                                <a className="" alt="Download XLS" onClick={exportToCSV}>
                                                                    <i className="fa fa-file-excel-o" ></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <DataTable
                                                    columns={filteredData.columns}
                                                    data={filteredData.rows}
                                                    pagination
                                                    fixedHeader
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    noDataComponent={<NoDataComponent />}
                                                    customStyles={customStyles}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Sidebar
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                handleSubmitData={handleSubmitData}
                updateFromDate={updateFromDate}
                updateToDate={updateToDate}
            />
        </>
    );
}

export default EnergySummary;
