/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Link, useLocation } from "react-router-dom";
import MapLoad from "./MapLoad";
import Table from "./Table";
import React, { useEffect, useState, useCallback, useContext } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import solutionContext from "../../../context/solution/solutionContext";
import TxiqSkeleton from "../../../Transformer/Summary/MainContent/TxiqSkeleton";

const MainContent = () => {
  // const { state } = useLocation();
  // Flash msg
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [locations, setLocations] = useState([]); // State for locations
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(0);
  const [device_count, setDeviceCount] = useState({ grey: "-", red: "-", green: "-" });
  const { solution_id, update_solution } = useContext(solutionContext)
  const [solution, setSolution] = useState(solution_id);



  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId)
    }

    if (solution === null) {
      setSolution(localStorage.getItem("solution"));
      update_solution(localStorage.getItem("solution"));
    }

  }, []);

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const fetchMapData = useCallback(async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/solution_summary/solution_wise_summary_map`,
          {
            access_token: token,
            iot_sol: "AQIQ",
            user_log_id: userLogId
          }
        );
        if (response.status === 200 && response.data.success === true) {
          const mapData = response.data;

          if (mapData.success === true) {
            const result = [...mapData.data];

            const grey = result.filter((item) => item.status === "Grey").length;
            const green = result.filter((item) => item.status === "Green").length;
            const red = result.filter((item) => item.status === "Red").length;
            const data = { "grey": grey, "green": green, "red": red }
            setDeviceCount(data);
            setLocations(result);
            success = true;
          }
        } else {

          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Something went wrong";
          // showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Map API:", error);
        showFlashMessage("Something went wrong");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Something went wrong");
      setIsLoading(false);
    }
  }, [token]);



  const fetchTableData = useCallback(async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          // `${process.env.REACT_APP_VARIABLE}/iotrms/summary/summary_table`
          `${process.env.REACT_APP_VARIABLE}/iotrms/solution_summary/solution_wise_summary_table`
          ,
          {
            access_token: token,
            iot_sol: "AQIQ",
            user_log_id: userLogId

          }
        );

        if (response.status === 200 && response.data.success) {
          setData(response.data.data?.data || []);
          success = true;
          // setData([]);
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Something went wrong";
          // showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Table API:", error);
        showFlashMessage("Something went wrong");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Something went wrong");
      setIsLoading(false);
    }
  }, [token]); // Run once on component mount

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await fetchMapData();
          await fetchTableData();
          setIsLoading(false);
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };

      const delay = 200;

      const timerId = setTimeout(() => {
        fetchData();
      }, delay);

      // for enable disable of the pdf icon
      // Check if both locations and data are blank

      // Cleanup function to clear the timer in case the component unmounts or the token changes
      return () => clearTimeout(timerId);
    }
  }, [token]);

  const isDataAbsent = locations.length === 0 && data.length === 0;
  if (isDataAbsent) {
    // Disable the link using its ID
    const pdfLink = document.getElementById("summary_pdf_icon");
    if (pdfLink) {
      pdfLink.style.pointerEvents = "none";
    }
  } else {
    const pdfLink = document.getElementById("summary_pdf_icon");
    if (pdfLink) {
      pdfLink.style.pointerEvents = "";
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setLoad((prevLoad) => {
        if (prevLoad < 100) {
          return prevLoad + 1;
        } else {
          // Reset load to 0 after reaching 100
          setTimeout(() => {
            setLoad(0);
          }, 2000);
        }
      });
    }, 50);
  }, []);
  // display loader
  // if (isLoading) {
  //   return (
  //     <div className='loaderWpr'>
  //       <div className="loader"></div>
  //     </div>
  //   );
  // }



  return (
    <>
      {" "}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {isLoading && (
              <TxiqSkeleton/>
            )}
           
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                <div className="mdlContent card-body">
                  <div className="col-md-12 col-lg-12">
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <div className="info-box">
                          <span className="info-box-icon bg-greenPin elevation-1"></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Active</span>
                          </div>
                          <div className="info-box-number">
                            <span className="info-box-text">{device_count.green}</span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-lg-4">
                        <div className="info-box">
                          <span className="info-box-icon bg-redPin elevation-1"></span>
                          <div className="info-box-content">
                            <span className="info-box-text">System Not Working</span>
                          </div>
                          <div className="info-box-number">
                            <span className="info-box-text">{device_count.red}</span>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-6 col-lg-6">
                        <div className="info-box">
                          <span className="info-box-icon bg-grayPin elevation-1"></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Inactive</span>
                          </div>
                          <div className="info-box-number">
                            <span className="info-box-text">{device_count.grey}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="map col-md-12 col-lg-12">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <MapLoad
                          zoomcntr={true}
                          gesture=""
                          mapType={true}
                          locations={locations}
                        />
                      </div>
                      {/*  end card-body --> */}
                    </div>
                    {/* <!-- end card pr-card --> */}
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <Table data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MainContent;
