/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./MainContent.css";

const Table = ({ data }) => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Preprocess the data before applying filtering
  const preprocessData = (data) => {
    return data.map((row) => {
      const processedRow = row.map((item, index) => {
        // Convert date strings to the desired format
        if (index === 0 && typeof item === "string") {
          return formatDateTime(item);
        }
        return item;
      });
      return processedRow;
    });
  };

  useEffect(() => {
    const processedData = preprocessData(data);
    const filteredResult = processedData.filter((row) => {
      const rowValues = row.map((item) =>
        typeof item === "string" ? item.toLowerCase() : item.toString()
      );

      const searchTerm = search.toLowerCase();

      return rowValues.some((value) => value.includes(searchTerm));
    });

    setFilteredData(filteredResult);
  }, [data, search]);

  const truncateToThreeDigits = (value) => {
    if (value !== undefined && value !== "") {
      const truncatedValue = parseFloat(Number(value).toFixed(3));
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "-";
  };

  // for date formating
  function formatDateTime(dateTimeString) {
    if (typeof dateTimeString === "string") {
      const data = new Date(new Date(dateTimeString).toISOString());
      const result = `${String(data.getUTCDate()).padStart(2, '0')}-${String(data.getUTCMonth() + 1).padStart(2, '0')}-${data.getUTCFullYear()} ${String(data.getUTCHours()).padStart(2, '0')}:${String(data.getUTCMinutes()).padStart(2, '0')}:${String(data.getUTCSeconds()).padStart(2, '0')}`;
      // const result =
      return result; //dateTime.toLocaleString("en-IN", options);
    } else {
      console("Invalid", dateTimeString);
      return dateTimeString;
    }
  }

  function removeSlash(dateTimeString) {
    const [day, month, year] = dateTimeString.split("/");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const getStatusColorClass = (status) => {
    switch (status.toLowerCase()) {
      case "good":
        return "good";
      case "moderate":
        return "moderate";
      case "poor":
        return "poor";
      case "satisfactory":
        return "satisfactory";
      case "severe":
        return "severe";
      case "very_poor":
        return "very_poor";
      default:
        return "";
    }
  };
  
  const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };
  const columns = [
    {
      name: "Last Updated",
      selector: (row) => parseDateString(row[0]),
      sortable: true,
      // style: {
      //   whiteSpace: "normal",
      //   background: "orange",
      // },
      cell: (row) => <div className="custom-cell">{row[0]}</div>, // Apply custom cell style
    },
    {
      name: "Location",
      selector: (row) => row[1] || "-",
      sortable: true,
      cell: (row) => <div className="custom-cell">{row[1] || "-"}</div>,
    },
    {
      name: "AQI",
      selector: (row) => row[2],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[2])}
        </div>
      ),
    },
    // {
    //   name: "Device Id",
    //   selector: (row) => row[3] || "-",
    //   sortable: true,
    //   cell: (row) => <div className="custom-cell">{row[3] || "-"}</div>,
    // },
    {
      name: "Status",
      selector: (row) => row[4] || "-",
      sortable: true,
      cell: (row) => (
        <div className={`custom-cell ${getStatusColorClass(row[4])}`}>
          {row[4] || "-"}
        </div>
      ),
    },
    {
      name: "Pollutant",
      selector: (row) => row[5] || "-",
      sortable: true,
      cell: (row) => <div className="custom-cell">{row[5] || "-"}</div>,
    },
    {
      name: "PM 2.5 (mcg/m3)",
      selector: (row) => row[6],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[6])}
        </div>
      ),
    },
    {
      name: "PM 10 (mcg/m3)",
      selector: (row) => row[7],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[7])}
        </div>
      ),
    },
    {
      name: "CO (mg/m3)",
      selector: (row) => row[8],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[8])}
        </div>
      ),
    },
    {
      name: "SO2 (mcg/m3)",
      selector: (row) => row[9],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[9])}
        </div>
      ),
    },
    {
      name: "O3 (mcg/m3)",
      selector: (row) => row[10],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[10])}
        </div>
      ),
    },
    {
      name: "NO2 (mcg/m3)",
      selector: (row) => row[11],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[11])}
        </div>
      ),
    },
    {
      name: "NH3 (mcg/m3)",
      selector: (row) => row[12],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[12])}
        </div>
      ),
    },
    {
      name: "H2S (mcg/m3)",
      selector: (row) => row[13],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[13])}
        </div>
      ),
    },
    {
      name: "CO2 (mg/m3)",
      selector: (row) => row[14],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[14])}
        </div>
      ),
    },
    {
      name: "Noise (dB)",
      selector: (row) => row[15],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[15])}
        </div>
      ),
    },
    {
      name: "UV Intensity (mW/cm2)",
      selector: (row) => row[16],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[16])}
        </div>
      ),
    },
    {
      name: "UV Index (NA)",
      selector: (row) => row[17],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[17])}
        </div>
      ),
    },
    {
      name: "Light (lux)",
      selector: (row) => row[18],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[18])}
        </div>
      ),
    },
    {
      name: "Humidity (%)",
      selector: (row) => row[19],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[19])}
        </div>
      ),
    },
    {
      name: "Temp (°C)",
      selector: (row) => row[20],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {truncateToThreeDigits(row[20])}
        </div>
      ),
    },
  ];
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const customHeadings = columns.map((column) => column.name);

    const filteredDataExcel = filteredData.map((row) => {
      const filteredItem = {};
      row.forEach((item, index) => {
        const heading = customHeadings[index];
        if (index === 6) {
          filteredItem[heading] = item;
        } else {
          filteredItem[heading] = item;
        }
        // if(index === 0 || index === 6){
        //   filteredItem[heading] = formatDateTime(item);
        // }
        // else if(index === 3 || index === 4 || index ===5){
        //   filteredItem[heading] =  parseFloat(item.toFixed(3)) || "-";
        // }
        // else{
        //   filteredItem[heading] = item;
        // }
      });
      return filteredItem;
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "SummaryData" + fileExtension);
  };

  // for adding all option inside pagination
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  // no data available columns
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal", // Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  return (
    <div className="card pr-card mb30 tableCard">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-6 col-xl-6">
            <div className="dataTables_length" id=""></div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6">
            <div className="form-inline dataTables_filter">
              <div className="mr10">
                <label>
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </label>
              </div>

              <div className="input-group pr-form-group"></div>

              <div className="input-group pr-form-group" id="">
                <a>
                  {Object.keys(filteredData).length === 0 ? (
                    <a className="" alt="Download XLS">
                      <i className="fa fa-file-excel-o"></i>
                    </a>
                  ) : (
                    <a className="" alt="Download XLS" onClick={exportToCSV}>
                      <i className="fa fa-file-excel-o"></i>
                    </a>
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            // className="table table-hover dtr-inline"
            fixedHeader
            // fixedHeaderScrollHeight="450px"
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoDataComponent />}
            customStyles={customStyles}
          // customStyles={{
          //   headCells: {
          //     style: {
          //       overflowWrap: "break-word",
          //       backgroundColor: '#FFA500'
          //     },
          //   },
          // }}
          />
        </div>
        <div className="row mt20"></div>
      </div>
    </div>
  );
};

export default Table;
