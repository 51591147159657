/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, InfoWindowF } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
// import greenPin from "../../../assets/images/newPins/greenPin.png";
// import redPin from "../../../assets/images/newPins/redPin.png";
// import grayPin from "../../../assets/images/newPins/googlePin_gray.png";
import greenPin from "./greenPin_sdw.png";
import redPin from "./redPin_sdw.png";
import grayPin from "./grayPin_sdw.png";
import { Link } from "react-router-dom";
import "./Map.css";
import { useNavigate } from "react-router-dom";

const containerStyle = {
  width: "100%",
  height: "250px",
};

const initialCenter = {
  lat: 0,
  lng: 0,
};

let image = "";

function MyComponent({ zoomcntr, gesture, mapType, locations }) {
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapLocations, setMapLocations] = useState(locations); // State for locations
  const [infoWindowOpen, setInfoWindowOpen] = useState(false); //New state for InfoWindow visibility
  const [infoWindowCont, setInfoWindowCont] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Update the locations state when the prop changes
    setMapLocations(locations);
  }, [locations]);
  // const [access_token, setAccessToken] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBj3vNZTpjBQ_4EMcgJoXLKDKpLQ83aRSw",//process.env.REACT_APP_MAP_API_KEY,
  });

  const handleMarkerClick = (marker) => {
    if (infoWindowCont === false) {
      setSelectedMarker(marker);
      setInfoWindowOpen(true);
    }
  };

  const handleMarkerMouseOut = () => {
    if (infoWindowCont === false) {
      setSelectedMarker(null);
      setInfoWindowOpen(false);
    } // Clear the selectedMarker when hovering out
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  useEffect(() => {
    if (locations.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();

      locations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.latitude, location.longitude)
        );
      });

      // Calculate the center of the bounds
      const center = bounds.getCenter();

      // Set the center and fit the bounds
      if (map.setCenter) {
        map.setCenter(center);
      }
      if (map.fitBounds) {
        map.fitBounds(bounds);
      }
    } else if (map) {
      // If no locations available, set a default center and zoom
      const defaultCenter = { lat: 19.971920981858858, lng: 73.83380818735375 };
      if (map.setCenter) {
        map.setCenter(defaultCenter);
      }
      if (map.setZoom) {
        map.setZoom(10); // Adjust the zoom level as needed
      }
    }
  }, [locations, map]);

  // storing the location and asset to show in trend page
  function handleInfoWindowClick(location) {
    const locationData = {
      value: location.location_id,
      label: location.iot_location,
    };
    const assetData = {
      value: location.assset_id,
      label: location.iot_asset,
    };

    localStorage.setItem("location", JSON.stringify(locationData));
    localStorage.setItem("asset", JSON.stringify(assetData));

    navigate(`/Motor Trend`);
  }

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={initialCenter}
          style={{ border: "0" }}
          zoom={4}
          onLoad={onLoad}
          allowFullScreen=""
          loading="lazy"
          options={{
            streetViewControl: false,
            mapTypeId: 'terrain',
            mapTypeControl: false,
            zoomControl: zoomcntr ? true : false,
            gestureHandling: gesture, // Disable scroll wheel
            keyboardShortcuts: false,
            // mapTypeControl: mapType, // Disable map type control
          }}
        >
          {locations.map((location, index) => {
            if (location.status === "Green") {
              image = greenPin;

              // } else if (location.status === "Red") {
              //   image = redPin;
            } else if (location.status === "Grey") {
              image = grayPin;
            }
            return (
              <MarkerF
                key={index}
                position={{ lat: location.latitude, lng: location.longitude }}
                icon={{
                  url: image,
                  scaledSize: new window.google.maps.Size(20, 20),
                  origin: new window.google.maps.Point(0, 0), // origin
                  anchor: new window.google.maps.Point(0, 0)
                }}
                onMouseOver={() => {
                  setInfoWindowCont(false);
                  handleMarkerClick(location)
                }
                }
                onMouseOut={handleMarkerMouseOut}
                onClick={() => {
                  setInfoWindowCont(true);
                }}
              >
                {selectedMarker === location && infoWindowOpen && (
                  <InfoWindowF position={location} onCloseClick={() => {
                    setInfoWindowCont(false);
                  }}>
                    <div className="toolWpr">
                      <div onClick={() => handleInfoWindowClick(location)} style={{ textDecoration: 'none' }}>
                        <p style={{ color: 'black', cursor: 'pointer' }}>
                          <b> {location.iot_location || "-"}</b>
                          &nbsp; - &nbsp;
                          <b>{location.iot_asset || "-"}</b>
                        </p>
                      </div>

                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            );
          })}
        </GoogleMap>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
}

export default React.memo(MyComponent);
