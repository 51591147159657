import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import solutionContext from "../../context/solution/solutionContext";
import { useContext } from "react";
import Chart from "react-apexcharts";
import { Alert } from "react-bootstrap";
import Plot from "react-plotly.js";
import BusbarSkelton from "./BusbarSkelton";

function Busbar(props) {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [onload, setOnLoad] = useState(false);

  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();

  const [heatDates, setHeatDates] = useState();
  const [heatValues, setHeatValues] = useState();
  const [onloadStartDate, setOnloadStartDate] = useState("");
  const [onloadEndDate, setOnloadEndDate] = useState("");
  const [isChartDataAvailableTwo, setIsChartDataAvailableTwo] = useState(true);
  const [busbarParametersLastValue, setBusbarParametersLastValue] = useState(
    {}
  );
  const [busbarParametersTable, setBusbarParametersTable] = useState([]);
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });
  const [heatmapDates, setHeatmapDates] = useState([]);
  const [heatmapValues, setHeatmapValues] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);
  const emptyChartData = {
    options: {
      xaxis: {
        title: "Datetime",
        tickmode: "array",
        zeroline: false,
        style: {
          fontFamily: "Gotham",
        },
        tickvals: [],
      },
      yaxis: {
        style: {
          fontFamily: "Gotham",
        },
        automargin: true,
      },
      dragmode: false,
      hovermode: false,
      showlegend: false,
      autosize: true,
      responsive: true,
    },
    series: [
      {
        x: [{}, {}, {}, {}, {}, {}],
        showscale: false,
        y: heatmapValues.slice().reverse(),
      },
    ],
  };

  function formatDate(date) {
    // Subtract 5 hours and 30 minutes
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const BusbarformatDate = (dateString) => {
    if (isDateString(dateString)) {
      const [year, month, day] = dateString.split("-");
      return `${day}-${month}-${year}`;
    }
    return dateString;
  };
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) { }
    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location);
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];

        // setSelectedAsset(new_asset);
        setOnloadAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props);
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // if (isLoading) {
  //   return (
  //     <div className="loaderWpr">
  //       <div className="loader"></div>
  //     </div>
  //   );
  // }

  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  const truncateToOneDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = Math.trunc(value * 10) / 10;
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "";
  };

  const Heatmap = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      const data = props;
      // setIsLoading(true);
      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/busbar_parameters_heatmap`,
          data
        );
        // if (response.data.data.length === 0) {
        //   setChartData();
        //   setIsChartDataAvailableTwo(false);
        //   // showFlashMessage(
        //   //   "Data Not Available for Maximum value recorded",
        //   //   "danger"
        //   // );
        //   setIsLoading(false);
        //   return;
        // }
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          const data = response.data;
          // {
          //     "success": true,
          //     "data": [
          //         {
          //             "heatmap_values": [
          //                 {
          //                     "name": "Incomer R",
          //                     "values": [
          //                         "-",
          //                         "-",
          //                         "-"
          //                     ],
          //                     "min": "-",
          //                     "max": "-"
          //                 },
          //                 {
          //                     "name": "Incomer Y",
          //                     "values": [
          //                         "-",
          //                         "-",
          //                         "-"
          //                     ],
          //                     "min": "-",
          //                     "max": "-"
          //                 },
          //                 {
          //                     "name": "Incomer B",
          //                     "values": [
          //                         "-",
          //                         "-",
          //                         "-"
          //                     ],
          //                     "min": "-",
          //                     "max": "-"
          //                 },
          //                 {
          //                     "name": "Outgoing Feeder R",
          //                     "values": [
          //                         "-",
          //                         "-",
          //                         "-"
          //                     ],
          //                     "min": "-",
          //                     "max": "-"
          //                 },
          //                 {
          //                     "name": "Outgoing Feeder Y",
          //                     "values": [
          //                         "-",
          //                         "-",
          //                         "-"
          //                     ],
          //                     "min": "-",
          //                     "max": "-"
          //                 },
          //                 {
          //                     "name": "Outgoing Feeder B",
          //                     "values": [
          //                         3.167,
          //                         "1.245",
          //                         "2.346"
          //                     ],
          //                     "min": "3",
          //                     "max": "4"
          //                 }
          //             ],
          //             "heatmap_dates": {
          //                 "created_at": [
          //                     "03-05-2024",
          //                     "04-05-2024",
          //                     "05-05-2024"
          //                 ]
          //             }
          //         }
          //     ],
          //     "start_date": "03-05-2024 00:00",
          //     "end_date": "03-05-2024 23:59"
          // }

          const heatmapData = data.data;
          setHeatmapData(heatmapData);
          success = true;

          const formattedDates = heatmapData[0].heatmap_dates.created_at.map(
            (date) => BusbarformatDate(date)
          );

          setHeatmapDates(formattedDates);
          setHeatmapValues(
            heatmapData[0].heatmap_values.map((val) => val.name)
          );

          if (heatmapData[0].heatmap_values && heatmapData[0].heatmap_dates) {
            const { heatmap_values, heatmap_dates } = heatmapData[0];
            const reversedValues = heatmap_values.reverse();
            const xValues = formattedDates;
            const yValues = heatmap_values.map((val) => val.name);
            const zValues = heatmap_values.map((val) =>
              val.values.map((value) => {
                if (value === "-") {
                  return 0.5; //value which are dash show 0.5
                } else if (value < val.min || value > val.max) {
                  return 0; // Value outside range, use 0 for red color
                } else {
                  return 1; // Value within range, use 1 for green color
                }
              })
            );


            const title = heatmap_dates.created_at.some(
              (timestamp) => typeof timestamp === "string"
            )
              ? "Datetime"
              : "Time";
            // const title = "name"
            const zValue = heatmap_values.map((val) =>
              val.values.map((value) => truncateToOneDigits(value))
            );
            const data = [
              {
                showscale: false, // Hide the color scale
                x: xValues,
                y: yValues,
                z: zValues,
                xgap: 1,
                ygap: 1,
                customdata: zValue, // Flatten zValues for custom data
                hovertemplate: `<b>%{y}</b><br>${isDateString(xValues[0]) ? "Datetime" : "Time"
                  }: %{x}<br>Value: %{customdata}<extra></extra>`,
                type: "heatmap",
                colorscale: [
                  [0, "#FF6873"], // Use red for values outside the range
                  [0.5, "#C1DEB8"], // Use light green for null values
                  [1, "#C1DEB8"], // Use green for values within the range
                ],
                zmin: 0, // Min value for the color scale
                zmax: 1, // Max value for the color scale
              },
            ];

            const layout = {
              modebardisplay: false,
              margin: {
                l: 10, 
                r: 10, 
                b: 0,
                t: 35,
              },
              xaxis: {
                automargin: true,
                title: title,
                tickmode: "array",
                tickvals: xValues,
                ticktext: xValues,
                zeroline: false,
                style: {
                  fontFamily: "Gotham",
                },
              },
              yaxis: {
                style: {
                  fontFamily: "Gotham",
                },

                // fixedrange: true,
                showticklabels: true, // Show tick labels
                automargin: true, // Automatically adjust margins
              },
              dragmode: false, // Disable panning
              hovermode: "closest", // Display hover info for closest point
              showlegend: false, // Hide legend
              autosize: true,
              responsive: true,
            };

            setChartData({
              series: data,
              options: layout,
            });
            setIsChartDataAvailableTwo(true);
            if (response.data.start_date && response.data.end_date) {
              setNewStartDate(response.data.start_date);
              setNewEndDate(response.data.end_date);
            }
          } else {
            setIsChartDataAvailableTwo(false);
            // showFlashMessage(
            //   "Data Not Available for Maximum value recorded",
            //   "danger"
            // );
            setChartData({ series: [], options: {} });
          }
        } else {
          setIsChartDataAvailableTwo(false);
          setChartData({ series: [], options: {} });
          // showFlashMessage(
          //   "Data Not Available for Maximum value recorded",
          //   "danger"
          // );
        }
      } catch (error) {
        setIsChartDataAvailableTwo(false);
        setChartData({ series: [], options: {} });
        console.error("Error fetching heatmap data:", error);
      }
      attempts++;
      // setIsLoading(false);
    }
    if (!success) {
      setChartData();
      setIsChartDataAvailableTwo(false);
      showFlashMessage(
        "Data Not Available for Maximum value recorded",
        "danger"
      );
      // setIsLoading(false);
    }
  };
  const isDateString = (str) => {
    // Check if a string matches a date pattern
    return /\d{4}-\d{2}-\d{2}/.test(str);
  };
  const excelIcon = {};

  if (heatmapDates.length > 0 && heatmapValues.length > 0) {
    const generateCSVContent = () => {
      // Create header row for CSV
      const title = heatmapData[0].heatmap_dates.created_at.some(
        (timestamp) =>
          typeof timestamp === "string" || typeof timestamp === "number"
      )
        ? "Date Time, "
        : "Time, ";
      let csvContent = title + heatmapValues.join(",") + "\n";

      // Create rows for each date with corresponding values
      for (let i = 0; i < heatmapDates.length; i++) {
        const reversedDates = heatmapDates.slice().reverse();

        const date = reversedDates[i];
        const values = heatmapData[0].heatmap_values.map((val) => {
          const value = val.values.slice().reverse()[i];
          const truncatedValue =
            typeof value === "number" || typeof value === "string"
              ? truncateToOneDigits(value)
              : value;
          return truncatedValue ? `"${truncatedValue}"` : truncatedValue;
        });

        csvContent += `${date},${values.reverse().join(",")}\n`;
      }

      return csvContent;
    };

    excelIcon.name = "excel-icon";
    excelIcon.icon = {
      width: 400,
      height: 500,
      path: "M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z",
    };

    excelIcon.click = function (gd) {
      const csvContent = generateCSVContent();
      const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Heatmap.csv");
      document.body.appendChild(link);
      link.click();
    };

    excelIcon.title = "Export to Excel";
  }

  const handleSubmitData = (props) => {
    setBusbarParametersLastValue({});
    setBusbarParametersTable([]);
    const { fromDate, toDate, location, site, parameter, group } = props;
    // setHeader({location: location.label, asset: site.label});
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));
    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    setOnloadLoc(location);
    setOnloadAsset(site);

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      Heatmap(data);
      if (fromDate) {
        data.start_date = convertDateCloneApi(fromDate);
      }

      if (toDate) {
        data.end_date = convertDateCloneApi(toDate);
      }
      try {
        data = Object.assign(
          {},
          data
          // { sensor_parameters: selectedParameter.map((d) => d.value), }
        );
        getBusbarParametersData(data);
        getBusbarParametersTable(data);
        // getDatableData(data);
      } catch (error) { }
      // data = (isParam === false) ? Object.assign({}, data, { sensor_parameters: parameter }) : data;
      // alert("done");
    }
  };

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      try {
        Heatmap(data);
        getBusbarParametersData(data);
        getBusbarParametersTable(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };
  const BusbarParametersData = async (url, setData, requestData, errMSG) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      // setIsLoading(true);
      if (requestData.start_date && requestData.end_date) {
        setOnloadStartDate(breadcrum_date_converter(requestData.start_date));
        setOnloadEndDate(breadcrum_date_converter(requestData.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/${url}`,
          requestData
        );
        if (response.data.success === true) {
          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          }

          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          } else {
            setOnloadStartDate("");
            setOnloadEndDate("");
          }
          setData(response.data.data[0].busbar_parameters);
          success = true;
        } else {
          // setIsLoading(false);
          setBusbarParametersLastValue(response.data.data[0].busbar_parameters);
          // showFlashMessage(`Data Not Available For ${errMSG}`);
        }
      } catch (error) {
        // alert(error);
        // setIsLoading(false);
        setBusbarParametersLastValue({});
        // showFlashMessage(`Data Not Available For ${errMSG}`);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(`Data Not Available For ${errMSG}`);
    }
  };
  const getBusbarParametersData = async (props) => {
    const requestData = props;
    setIsLoading(true);
    if (token !== null && token !== undefined && token !== "") {
      await BusbarParametersData(
        "iotrms/busbar/busbar_parameters_last_value",
        setBusbarParametersLastValue,
        requestData,
        "Last Value Recorded"
      );
      // setIsLoading(false);
    }
  };

  const fetchData = async (url, setData, requestData, errMSG) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      // setIsLoading(true);
      if (requestData.start_date && requestData.end_date) {
        setOnloadStartDate(breadcrum_date_converter(requestData.start_date));
        setOnloadEndDate(breadcrum_date_converter(requestData.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/${url}`,
          requestData
        );
        if (response.data.success === true) {
          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          }

          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          } else {
            setOnloadStartDate("");
            setOnloadEndDate("");
          }
          setData(response.data.data[0]);
          success = true;
        } else {
          setIsLoading(false);
          setBusbarParametersTable([]);
          // showFlashMessage(`Data Not Available For ${errMSG}`);
        }
      } catch (error) {
        // alert(error);
        setIsLoading(false);
        setBusbarParametersTable([]);
        // showFlashMessage(`Data Not Available For ${errMSG}`);
      }
      attempts++;
    }
    if (!success) {
      setBusbarParametersTable([]);
      showFlashMessage(`Data Not Available For ${errMSG}`);
    }
  };

  const getBusbarParametersTable = async (props) => {
    const requestData = props;
    setIsLoading(true);
    if (token !== null && token !== undefined && token !== "") {
      await fetchData(
        "iotrms/busbar/busbar_parameters_table",
        setBusbarParametersTable,
        requestData,
        "Summary"
      );
      setIsLoading(false);
    }
  };
  const firstMinObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Min &&
    busbarParametersTable.Incomer.Min[0][0];
  const firstMinKey = firstMinObject ? Object.keys(firstMinObject)[0] : null;
  const firstMinValue = firstMinObject ? firstMinObject[firstMinKey] : "-";

  const secondMinObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Min &&
    busbarParametersTable.Incomer.Min[0][1];
  const secondMinKey = secondMinObject ? Object.keys(secondMinObject)[0] : null;
  const secondMinValue = secondMinObject ? secondMinObject[secondMinKey] : "-";
  const thirdMinObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Min &&
    busbarParametersTable.Incomer.Min[0][2];
  const thirdMinKey = thirdMinObject ? Object.keys(thirdMinObject)[0] : null;
  const thirdMinValue = thirdMinObject ? thirdMinObject[thirdMinKey] : "-";
  const firstMaxObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Max &&
    busbarParametersTable.Incomer.Max[0][0];
  const firstMaxKey = firstMaxObject ? Object.keys(firstMaxObject)[0] : null;
  const firstMaxValue = firstMaxObject ? firstMaxObject[firstMaxKey] : "-";
  const secondMaxObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Max &&
    busbarParametersTable.Incomer.Max[0][1];
  const secondMaxKey = secondMaxObject ? Object.keys(secondMaxObject)[0] : null;
  const secondMaxValue = secondMaxObject ? secondMaxObject[secondMaxKey] : "-";
  const thirdMaxObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Max &&
    busbarParametersTable.Incomer.Max[0][2];
  const thirdMaxKey = thirdMaxObject ? Object.keys(thirdMaxObject)[0] : null;
  const thirdMaxValue = thirdMaxObject ? thirdMaxObject[thirdMaxKey] : "-";
  const firstAvgObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Avg &&
    busbarParametersTable.Incomer.Avg[0][0];
  const firstAvgKey = firstAvgObject ? Object.keys(firstAvgObject)[0] : null;
  const firstAvgValue = firstAvgObject ? firstAvgObject[firstAvgKey] : "-";
  const secondAvgObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Avg &&
    busbarParametersTable.Incomer.Avg[0][1];
  const secondAvgKey = secondAvgObject ? Object.keys(secondAvgObject)[0] : null;
  const secondAvgValue = secondAvgObject ? secondAvgObject[secondAvgKey] : "-";
  const thirdAvgObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Avg &&
    busbarParametersTable.Incomer.Avg[0][2];
  const thirdAvgKey = thirdAvgObject ? Object.keys(thirdAvgObject)[0] : null;
  const thirdAvgValue = thirdAvgObject ? thirdAvgObject[thirdAvgKey] : "-";
  const timestampfirstMinObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Min &&
    busbarParametersTable.Incomer.Min[1][0];
  const timestampfirstMinKey = timestampfirstMinObject
    ? Object.keys(timestampfirstMinObject)[0]
    : null;
  const timestampfirstMinValue = timestampfirstMinObject
    ? timestampfirstMinObject[timestampfirstMinKey]
    : "-";
  const firstMinformattedDate = new Date(timestampfirstMinValue);
  const firstMinDate = formatDate(firstMinformattedDate);
  const timestampsecondMinObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Min &&
    busbarParametersTable.Incomer.Min[1][1];
  const timestampsecondMinKey = timestampsecondMinObject
    ? Object.keys(timestampsecondMinObject)[0]
    : null;
  const timestampsecondMinValue = timestampsecondMinObject
    ? timestampsecondMinObject[timestampsecondMinKey]
    : "-";
  const secondMinformattedDate = new Date(timestampsecondMinValue);
  const secondMinDate = formatDate(secondMinformattedDate);
  const timestampthirdMinObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Min &&
    busbarParametersTable.Incomer.Min[1][2];
  const timestampthirdMinKey = timestampthirdMinObject
    ? Object.keys(timestampthirdMinObject)[0]
    : null;
  const timestampthirdMinValue = timestampthirdMinObject
    ? timestampthirdMinObject[timestampthirdMinKey]
    : "-";
  const thirdMinformattedDate = new Date(timestampthirdMinValue);
  const thirdMinDate = formatDate(thirdMinformattedDate);
  const timestampfirstMaxObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Max &&
    busbarParametersTable.Incomer.Max[1][0];
  const timestampfirstMaxKey = timestampfirstMaxObject
    ? Object.keys(timestampfirstMaxObject)[0]
    : null;
  const timestampfirstMaxValue = timestampfirstMaxObject
    ? timestampfirstMaxObject[timestampfirstMaxKey]
    : "-";
  const firstMaxformattedDate = new Date(timestampfirstMaxValue);
  const firstMaxDate = formatDate(firstMaxformattedDate);
  const timestampsecondMaxObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Max &&
    busbarParametersTable.Incomer.Max[1][1];
  const timestampsecondMaxKey = timestampsecondMaxObject
    ? Object.keys(timestampsecondMaxObject)[0]
    : null;
  const timestampsecondMaxValue = timestampsecondMaxObject
    ? timestampsecondMaxObject[timestampsecondMaxKey]
    : "-";
  const secondMaxformattedDate = new Date(timestampsecondMaxValue);
  const secondMaxDate = formatDate(secondMaxformattedDate);
  const timestampthirdMaxObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Max &&
    busbarParametersTable.Incomer.Max[1][2];
  const timestampthirdMaxKey = timestampthirdMaxObject
    ? Object.keys(timestampthirdMaxObject)[0]
    : null;
  const timestampthirdMaxValue = timestampthirdMaxObject
    ? timestampthirdMaxObject[timestampthirdMaxKey]
    : "-";
  const thirdMaxformattedDate = new Date(timestampthirdMaxValue);
  const thirdMaxDate = formatDate(thirdMaxformattedDate);
  const timestampfirstAvgObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Avg &&
    busbarParametersTable.Incomer.Avg[1] &&
    busbarParametersTable.Incomer.Avg[1][0];
  const timestampfirstAvgKey = timestampfirstAvgObject
    ? Object.keys(timestampfirstAvgObject)[0]
    : null;
  const timestampfirstAvgValue = timestampfirstAvgObject
    ? timestampfirstAvgObject[timestampfirstAvgKey]
    : "-";
  const firstAvgformattedDate = new Date(timestampfirstAvgValue);
  const firstAvgDate = formatDate(firstAvgformattedDate);
  const timestampsecondAvgObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Avg &&
    busbarParametersTable.Incomer.Avg[1] &&
    busbarParametersTable.Incomer.Avg[1][1];
  const timestampsecondAvgKey = timestampsecondAvgObject
    ? Object.keys(timestampsecondAvgObject)[0]
    : null;
  const timestampsecondAvgValue = timestampsecondAvgObject
    ? timestampsecondAvgObject[timestampsecondAvgKey]
    : "-";
  const secondAvgformattedDate = new Date(timestampsecondAvgValue);
  const secondAvgDate = formatDate(secondAvgformattedDate);
  const timestampthirdAvgObject =
    busbarParametersTable.Incomer &&
    busbarParametersTable.Incomer.Avg &&
    busbarParametersTable.Incomer.Avg[1] &&
    busbarParametersTable.Incomer.Avg[1][2];
  const timestampthirdAvgKey = timestampthirdAvgObject
    ? Object.keys(timestampthirdAvgObject)[0]
    : null;
  const timestampthirdAvgValue = timestampthirdAvgObject
    ? timestampthirdAvgObject[timestampthirdAvgKey]
    : "-";
  const thirdAvgformattedDate = new Date(timestampthirdAvgValue);
  const thirdAvgDate = formatDate(thirdAvgformattedDate);
  const oFeederfirstMinObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Min &&
    busbarParametersTable["Out Feeder"].Min[0][0];
  const oFeederfirstMinKey = oFeederfirstMinObject
    ? Object.keys(oFeederfirstMinObject)[0]
    : null;
  const oFeederfirstMinValue = oFeederfirstMinObject
    ? oFeederfirstMinObject[oFeederfirstMinKey]
    : "-";
  const oFeedersecondMinObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Min &&
    busbarParametersTable["Out Feeder"].Min[0][1];
  const oFeedersecondMinKey = oFeedersecondMinObject
    ? Object.keys(oFeedersecondMinObject)[0]
    : null;
  const oFeedersecondMinValue = oFeedersecondMinObject
    ? oFeedersecondMinObject[oFeedersecondMinKey]
    : "-";
  const oFeederthirdMinObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Min &&
    busbarParametersTable["Out Feeder"].Min[0][2];
  const oFeederthirdMinKey = oFeederthirdMinObject
    ? Object.keys(oFeederthirdMinObject)[0]
    : null;
  const oFeederthirdMinValue = oFeederthirdMinObject
    ? oFeederthirdMinObject[oFeederthirdMinKey]
    : "-";
  const oFeederfirstMaxObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Max &&
    busbarParametersTable["Out Feeder"].Max[0][0];
  const oFeederfirstMaxKey = oFeederfirstMaxObject
    ? Object.keys(oFeederfirstMaxObject)[0]
    : null;
  const oFeederfirstMaxValue = oFeederfirstMaxObject
    ? oFeederfirstMaxObject[oFeederfirstMaxKey]
    : "-";
  const oFeedersecondMaxObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Max &&
    busbarParametersTable["Out Feeder"].Max[0][1];
  const oFeedersecondMaxKey = oFeedersecondMaxObject
    ? Object.keys(oFeedersecondMaxObject)[0]
    : null;
  const oFeedersecondMaxValue = oFeedersecondMaxObject
    ? oFeedersecondMaxObject[oFeedersecondMaxKey]
    : "-";
  const oFeederthirdMaxObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Max &&
    busbarParametersTable["Out Feeder"].Max[0][2];
  const oFeederthirdMaxKey = oFeederthirdMaxObject
    ? Object.keys(oFeederthirdMaxObject)[0]
    : null;
  const oFeederthirdMaxValue = oFeederthirdMaxObject
    ? oFeederthirdMaxObject[oFeederthirdMaxKey]
    : "-";
  const oFeederfirstAvgObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Avg &&
    busbarParametersTable["Out Feeder"].Avg[0][0];
  const oFeederfirstAvgKey = oFeederfirstAvgObject
    ? Object.keys(oFeederfirstAvgObject)[0]
    : null;
  const oFeederfirstAvgValue = oFeederfirstAvgObject
    ? oFeederfirstAvgObject[oFeederfirstAvgKey]
    : "-";
  const oFeedersecondAvgObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Avg &&
    busbarParametersTable["Out Feeder"].Avg[0][1];
  const oFeedersecondAvgKey = oFeedersecondAvgObject
    ? Object.keys(oFeedersecondAvgObject)[0]
    : null;
  const oFeedersecondAvgValue = oFeedersecondAvgObject
    ? oFeedersecondAvgObject[oFeedersecondAvgKey]
    : "-";
  const oFeederthirdAvgObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Avg &&
    busbarParametersTable["Out Feeder"].Avg[0][2];
  const oFeederthirdAvgKey = oFeederthirdAvgObject
    ? Object.keys(oFeederthirdAvgObject)[0]
    : null;
  const oFeederthirdAvgValue = oFeederthirdAvgObject
    ? oFeederthirdAvgObject[oFeederthirdAvgKey]
    : "-";
  const oFeedertimestampfirstMinObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Min &&
    busbarParametersTable["Out Feeder"].Min[1][0];
  const oFeedertimestampfirstMinKey = oFeedertimestampfirstMinObject
    ? Object.keys(oFeedertimestampfirstMinObject)[0]
    : null;
  const oFeedertimestampfirstMinValue = oFeedertimestampfirstMinObject
    ? oFeedertimestampfirstMinObject[oFeedertimestampfirstMinKey]
    : "-";
  const oFeederfirstMinformattedDate = new Date(oFeedertimestampfirstMinValue);
  const oFeederfirstMinDate = formatDate(oFeederfirstMinformattedDate);
  const oFeedertimestampsecondMinObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Min &&
    busbarParametersTable["Out Feeder"].Min[1][1];
  const oFeedertimestampsecondMinKey = oFeedertimestampsecondMinObject
    ? Object.keys(oFeedertimestampsecondMinObject)[0]
    : null;
  const oFeedertimestampsecondMinValue = oFeedertimestampsecondMinObject
    ? oFeedertimestampsecondMinObject[oFeedertimestampsecondMinKey]
    : "-";
  const oFeedersecondMinformattedDate = new Date(
    oFeedertimestampsecondMinValue
  );
  const oFeedersecondMinDate = formatDate(oFeedersecondMinformattedDate);
  const oFeedertimestampthirdMinObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Min &&
    busbarParametersTable["Out Feeder"].Min[1][2];
  const oFeedertimestampthirdMinKey = oFeedertimestampthirdMinObject
    ? Object.keys(oFeedertimestampthirdMinObject)[0]
    : null;
  const oFeedertimestampthirdMinValue = oFeedertimestampthirdMinObject
    ? oFeedertimestampthirdMinObject[oFeedertimestampthirdMinKey]
    : "-";
  const oFeederthirdMinformattedDate = new Date(oFeedertimestampthirdMinValue);
  const timestamp = oFeederthirdMinformattedDate.getTime();

  const oFeederthirdMinDate = formatDate(oFeederthirdMinformattedDate);
  const oFeedertimestampfirstMaxObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Max &&
    busbarParametersTable["Out Feeder"].Max[1][0];
  const oFeedertimestampfirstMaxKey = oFeedertimestampfirstMaxObject
    ? Object.keys(oFeedertimestampfirstMaxObject)[0]
    : null;
  const oFeedertimestampfirstMaxValue = oFeedertimestampfirstMaxObject
    ? oFeedertimestampfirstMaxObject[oFeedertimestampfirstMaxKey]
    : "-";
  const oFeederfirstMaxformattedDate = new Date(oFeedertimestampfirstMaxValue);
  const oFeederfirstMaxDate = formatDate(oFeederfirstMaxformattedDate);
  const oFeedertimestampsecondMaxObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Max &&
    busbarParametersTable["Out Feeder"].Max[1][1];
  const oFeedertimestampsecondMaxKey = oFeedertimestampsecondMaxObject
    ? Object.keys(oFeedertimestampsecondMaxObject)[0]
    : null;
  const oFeedertimestampsecondMaxValue = oFeedertimestampsecondMaxObject
    ? oFeedertimestampsecondMaxObject[oFeedertimestampsecondMaxKey]
    : "-";
  const oFeedersecondMaxformattedDate = new Date(
    oFeedertimestampsecondMaxValue
  );
  const oFeedersecondMaxDate = formatDate(oFeedersecondMaxformattedDate);
  const oFeedertimestampthirdMaxObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Max &&
    busbarParametersTable["Out Feeder"].Max[1][2];
  const oFeedertimestampthirdMaxKey = oFeedertimestampthirdMaxObject
    ? Object.keys(oFeedertimestampthirdMaxObject)[0]
    : null;
  const oFeedertimestampthirdMaxValue = oFeedertimestampthirdMaxObject
    ? oFeedertimestampthirdMaxObject[oFeedertimestampthirdMaxKey]
    : "-";
  const oFeederthirdMaxformattedDate = new Date(oFeedertimestampthirdMaxValue);
  const oFeederthirdMaxDate = formatDate(oFeederthirdMaxformattedDate);
  const oFeedertimestampfirstAvgObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Avg &&
    busbarParametersTable["Out Feeder"].Avg[1] &&
    busbarParametersTable["Out Feeder"].Avg[1][0];
  const oFeedertimestampfirstAvgKey = oFeedertimestampfirstAvgObject
    ? Object.keys(oFeedertimestampfirstAvgObject)[0]
    : null;
  const oFeedertimestampfirstAvgValue = oFeedertimestampfirstAvgObject
    ? oFeedertimestampfirstAvgObject[oFeedertimestampfirstAvgKey]
    : "-";
  const oFeederfirstAvgformattedDate = new Date(oFeedertimestampfirstAvgValue);
  const oFeederfirstAvgDate = formatDate(oFeederfirstAvgformattedDate);
  const oFeedertimestampsecondAvgObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Avg &&
    busbarParametersTable["Out Feeder"].Avg[1] &&
    busbarParametersTable["Out Feeder"].Avg[1][1];
  const oFeedertimestampsecondAvgKey = oFeedertimestampsecondAvgObject
    ? Object.keys(oFeedertimestampsecondAvgObject)[0]
    : null;
  const oFeedertimestampsecondAvgValue = oFeedertimestampsecondAvgObject
    ? oFeedertimestampsecondAvgObject[oFeedertimestampsecondAvgKey]
    : "-";
  const oFeedersecondAvgformattedDate = new Date(
    oFeedertimestampsecondAvgValue
  );
  const oFeedersecondAvgDate = formatDate(oFeedersecondAvgformattedDate);
  const oFeedertimestampthirdAvgObject =
    busbarParametersTable["Out Feeder"] &&
    busbarParametersTable["Out Feeder"].Avg &&
    busbarParametersTable["Out Feeder"].Avg[1] &&
    busbarParametersTable["Out Feeder"].Avg[1][2];
  const oFeedertimestampthirdAvgKey = oFeedertimestampthirdAvgObject
    ? Object.keys(oFeedertimestampthirdAvgObject)[0]
    : null;
  const oFeedertimestampthirdAvgValue = oFeedertimestampthirdAvgObject
    ? oFeedertimestampthirdAvgObject[oFeedertimestampthirdAvgKey]
    : "-";
  const oFeederthirdAvgformattedDate = new Date(oFeedertimestampthirdAvgValue);
  const oFeederthirdAvgDate = formatDate(oFeederthirdAvgformattedDate);

  const backgroundColour = (val, min, max) => {
    let backgrdColur;
    if (val === "-") {
      backgrdColur = "#C1DEB8";
    } else if (val < parseInt(min) || val > parseInt(max)) {
      backgrdColur = "#FF6873";
    } else {
      backgrdColur = "#C1DEB8";
    }

    return backgrdColur;
  };
  console.log(oFeederthirdAvgObject);
  return (
    <>
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper">
        {isLoading && (
          <BusbarSkelton/>
        )}
        
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
        {/* <!-- Main content --> */}
        <section className="content">
          <div className="container-fluid">
          
            <div className="mdlContent card pr-card mb30">
              <div className="breadcrumbHdr card-header">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">{selectedAsset.label}</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">{selectedLocation.label}</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">
                      {" "}
                      {newStartDate ? (
                        <a href="#">{newStartDate}</a>
                      ) : (
                        <a href="#">{formatDate(fromDate)}</a>
                      )}
                      &nbsp;-&nbsp;
                      {newEndDate ? (
                        <a href="#">{newEndDate}</a>
                      ) : (
                        <a href="#">{formatDate(toDate)}</a>
                      )}
                    </a>
                  </li>
                </ol>
              </div>
              <div className="mdlContent card-body mt20">
                <div className="card pr-card">
                  <div className="inrCardHdr">
                    <h5>Last value recorded</h5>
                  </div>
                  <div className="inrCardBody">
                    <div className="row">
                      <div className="col-md-2 col-lg-2">
                        <div className="busbarCard card pr-card">
                          <div className="inrCardHdr">
                            <h5>Incomer R</h5>
                          </div>
                          <div
                            className="inrCardBody text-center"
                            style={{
                              background: backgroundColour(
                                busbarParametersLastValue.busbar_temp_incomer_r
                                  ?.value,
                                busbarParametersLastValue.busbar_temp_incomer_r
                                  ?.min,
                                busbarParametersLastValue.busbar_temp_incomer_r
                                  ?.max
                              ),
                            }}
                          >
                            <h3 className="mb0">
                              {
                                busbarParametersLastValue.busbar_temp_incomer_r
                                  ?.value
                              }
                              <sup>o</sup>C
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="busbarCard card pr-card">
                          <div className="inrCardHdr">
                            <h5>Incomer Y</h5>
                          </div>
                          <div
                            className="inrCardBody text-center"
                            style={{
                              background: backgroundColour(
                                busbarParametersLastValue.busbar_temp_incomer_y
                                  ?.value,
                                busbarParametersLastValue.busbar_temp_incomer_y
                                  ?.min,
                                busbarParametersLastValue.busbar_temp_incomer_y
                                  ?.max
                              ),
                            }}
                          >
                            <h3 className="mb0">
                              {
                                busbarParametersLastValue.busbar_temp_incomer_y
                                  ?.value
                              }
                              <sup>o</sup>C
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="busbarCard card pr-card">
                          <div className="inrCardHdr">
                            <h5>Incomer B</h5>
                          </div>
                          <div
                            className="inrCardBody text-center"
                            style={{
                              background: backgroundColour(
                                busbarParametersLastValue.busbar_temp_incomer_b
                                  ?.value,
                                busbarParametersLastValue.busbar_temp_incomer_b
                                  ?.min,
                                busbarParametersLastValue.busbar_temp_incomer_b
                                  ?.max
                              ),
                            }}
                          >
                            <h3 className="mb0">
                              {
                                busbarParametersLastValue.busbar_temp_incomer_b
                                  ?.value
                              }
                              <sup>o</sup>C
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="busbarCard card pr-card">
                          <div className="inrCardHdr">
                            <h5>Outgoing Feeder R</h5>
                          </div>
                          <div
                            className="inrCardBody text-center"
                            style={{
                              background: backgroundColour(
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_r?.value,
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_r?.min,
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_r?.max
                              ),
                            }}
                          >
                            <h3 className="mb0">
                              {
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_r?.value
                              }
                              <sup>o</sup>C
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="busbarCard card pr-card">
                          <div className="inrCardHdr">
                            <h5>Outgoing Feeder Y</h5>
                          </div>
                          <div
                            className="inrCardBody text-center"
                            style={{
                              background: backgroundColour(
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_y?.value,
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_y?.min,
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_y?.max
                              ),
                            }}
                          >
                            <h3 className="mb0">
                              {
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_y?.value
                              }
                              <sup>o</sup>C
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="busbarCard card pr-card">
                          <div className="inrCardHdr">
                            <h5>Outgoing Feeder B</h5>
                          </div>
                          <div
                            className="inrCardBody text-center"
                            style={{
                              background: backgroundColour(
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_b?.value,
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_b?.min,
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_b?.max
                              ),
                            }}
                          >
                            <h3 className="mb0">
                              {
                                busbarParametersLastValue
                                  .busbar_temp_out_feeder_b?.value
                              }
                              <sup>o</sup>C
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="summTbl card pr-card">
                  <div className="inrCardHdr">
                    <h5>Summary</h5>
                  </div>
                  <div className="inrCardBody">
                    <table className="table table-bordered">
                      <thead>
                        <th rowSpan="2"></th>
                        <th className="rBg text-center" colSpan={3}>
                          R
                        </th>
                        <th className="yBg text-center" colSpan={3}>
                          Y
                        </th>
                        <th className="bBg text-center" colSpan={3}>
                          B
                        </th>
                      </thead>
                      <thead>
                        <th></th>
                        <th className="text-center">Min</th>
                        <th className="text-center">Max</th>
                        <th className="text-center">Avg</th>
                        <th className="text-center">Min</th>
                        <th className="text-center">Max</th>
                        <th className="text-center">Avg</th>
                        <th className="text-center">Min</th>
                        <th className="text-center">Max</th>
                        <th className="text-center">Avg</th>
                      </thead>
                      <tbody>
                        <tr>
                          <th rowSpan="2">Incomer</th>
                          <td>
                            {firstMinValue !== "-"
                              ? Number(firstMinValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {firstMaxValue !== "-"
                              ? Number(firstMaxValue).toFixed(1)
                              : "-"}
                          </td>
                          <td rowSpan="2">
                            {firstAvgValue !== "-"
                              ? Number(firstAvgValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {secondMinValue !== "-"
                              ? Number(secondMinValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {secondMaxValue !== "-"
                              ? Number(secondMaxValue).toFixed(1)
                              : "-"}
                          </td>
                          <td rowSpan="2">
                            {secondAvgValue !== "-"
                              ? Number(secondAvgValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {thirdMinValue !== "-"
                              ? Number(thirdMinValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {thirdMaxValue !== "-"
                              ? Number(thirdMaxValue).toFixed(1)
                              : "-"}
                          </td>
                          <td rowSpan="2">
                            {thirdAvgValue !== "-"
                              ? Number(thirdAvgValue).toFixed(1)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="tdtimeBg">
                            {timestampfirstMinValue === "-"
                              ? "-"
                              : firstMinDate}
                          </td>
                          <td className="tdtimeBg">
                            {timestampfirstMaxValue !== "-"
                              ? firstMaxDate
                              : "-"}
                          </td>
                          {/* <td className="tdtimeBg">
                            {timestampfirstAvgValue !== "-"
                              ? firstAvgDate
                              : "-"}
                          </td> */}
                          <td className="tdtimeBg">
                            {timestampsecondMinValue !== "-"
                              ? secondMinDate
                              : "-"}
                          </td>
                          <td className="tdtimeBg">
                            {timestampsecondMaxValue !== "-"
                              ? secondMaxDate
                              : "-"}
                          </td>
                          {/* <td className="tdtimeBg">
                            {timestampsecondAvgValue !== "-"
                              ? secondAvgDate
                              : "-"}
                          </td> */}
                          <td className="tdtimeBg">
                            {timestampthirdMinValue !== "-"
                              ? thirdMinDate
                              : "-"}
                          </td>
                          <td className="tdtimeBg">
                            {timestampthirdMaxValue !== "-"
                              ? thirdMaxDate
                              : "-"}
                          </td>
                          {/* <td className="tdtimeBg">
                            {timestampthirdAvgValue !== "-"
                              ? thirdAvgDate
                              : "-"}
                          </td> */}
                        </tr>
                        <tr>
                          <th rowSpan="2">Out Feeder</th>
                          <td>
                            {oFeederfirstMinValue !== "-"
                              ? Number(oFeederfirstMinValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {oFeederfirstMaxValue !== "-"
                              ? Number(oFeederfirstMaxValue).toFixed(1)
                              : "-"}
                          </td>
                          <td rowSpan="2">
                            {oFeederfirstAvgValue !== "-"
                              ? Number(oFeederfirstAvgValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {oFeedersecondMinValue !== "-"
                              ? Number(oFeedersecondMinValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {oFeedersecondMaxValue !== "-"
                              ? Number(oFeedersecondMaxValue).toFixed(1)
                              : "-"}
                          </td>
                          <td rowSpan="2">
                            {oFeedersecondAvgValue !== "-"
                              ? Number(oFeedersecondAvgValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {oFeederthirdMinValue !== "-"
                              ? Number(oFeederthirdMinValue).toFixed(1)
                              : "-"}
                          </td>
                          <td>
                            {oFeederthirdMaxValue !== "-"
                              ? Number(oFeederthirdMaxValue).toFixed(1)
                              : "-"}
                          </td>
                          <td rowSpan="2">
                            {oFeederthirdAvgValue !== "-"
                              ? Number(oFeederthirdAvgValue).toFixed(1)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="tdtimeBg">
                            {oFeedertimestampfirstMinValue !== "-"
                              ? oFeederfirstMinDate
                              : "-"}
                          </td>
                          <td className="tdtimeBg">
                            {oFeedertimestampfirstMaxValue !== "-"
                              ? oFeederfirstMaxDate
                              : "-"}
                          </td>
                          {/* <td className="tdtimeBg">
                            {oFeedertimestampfirstAvgValue !== "-"
                              ? oFeederfirstAvgDate
                              : "-"}
                          </td> */}
                          <td className="tdtimeBg">
                            {oFeedertimestampsecondMinValue !== "-"
                              ? oFeedersecondMinDate
                              : "-"}
                          </td>
                          <td className="tdtimeBg">
                            {oFeedertimestampsecondMaxValue !== "-"
                              ? oFeedersecondMaxDate
                              : "-"}
                          </td>
                          {/* <td className="tdtimeBg">
                            {oFeedertimestampsecondAvgValue !== "-"
                              ? oFeedersecondAvgDate
                              : "-"}
                          </td> */}
                          <td className="tdtimeBg">
                            {oFeedertimestampthirdMinValue !== "-"
                              ? oFeederthirdMinDate
                              : "-"}
                          </td>
                          <td className="tdtimeBg">
                            {oFeedertimestampthirdMaxValue !== "-"
                              ? oFeederthirdMaxDate
                              : "-"}
                          </td>
                          {/* <td className="tdtimeBg">
                            {oFeedertimestampthirdAvgValue !== "-"
                              ? oFeederthirdAvgDate
                              : "-"}
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="hotspotChart card pr-card">
                  <div className="inrCardHdr">
                    <h5>Maximum value recorded</h5>
                  </div>
                  <div className="inrCardBody">
                    {isChartDataAvailableTwo ? (
                      <Plot
                        theme="streamlit"
                        data={chartData.series}
                        layout={chartData.options}
                        config={{
                          displayModeBar: true,
                          displaylogo: false,
                          responsive: true,
                          toImageButtonOptions: {
                            filename: "Busbar",
                          },
                          modeBarButtonsToAdd: [excelIcon],

                          modeBarButtonsToRemove: [
                            "select2d",
                            "lasso2d",
                            "autoscale",
                            "zoom2d",
                            "pan2d",
                            "zoomIn2d",
                            "zoomOut2d",
                            "autoScale2d",
                            "resetScale2d",
                            "hoverClosestCartesian",
                            "hoverCompareCartesian",
                          ],
                        }}
                        style={{ width: "100%", height: 350 }} // Adjust style based on your requirements
                      />
                    ) : (
                      <>
                        <Plot
                          data={emptyChartData.series}
                          layout={emptyChartData.options}
                          config={{
                            displayModeBar: true,
                            displaylogo: false,
                            responsive: true,
                            toImageButtonOptions: {
                              filename: "Busbar",
                            },
                            // modeBarButtonsToAdd: [excelIcon],

                            modeBarButtonsToRemove: [
                              "toImage",
                              "select2d",
                              "lasso2d",
                              "autoscale",
                              "zoom2d",
                              "pan2d",
                              "zoomIn2d",
                              "zoomOut2d",
                              "autoScale2d",
                              "resetScale2d",
                              "hoverClosestCartesian",
                              "hoverCompareCartesian",
                            ],
                          }}
                          type="heatmap"
                          style={{ width: "100%", height: 350 }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default Busbar;
