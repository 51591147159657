/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import Select from 'react-select';
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Modal,Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './style.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Alert } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as ExcelJS from 'exceljs';
import {Tooltip } from 'react-tooltip';


const Sidebar = (props) => {
  let { locationData, assetData, parameterData, changeLocation, changeAsset, changeParameter, handleSubmitData, selectedLocation, selectedAsset, selectedParameter, fromDate, toDate, setFromDate, setToDate, isParam, changeIsParam, groupData, handleAddGroup, getGroupName, } = props;
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [fromTime, setFromTime] = useState("00:00");
  const [fromHours, setFromHours] = useState(fromDate.getHours().toString().padStart(2, '0'));
  const [fromMinutes, setFromMinutes] = useState(fromDate.getMinutes().toString().padStart(2, '0'));
  const [fromSeconds, setFromSeconds] = useState('00');
  const [toTime, setToTime] = useState("00:00");
  const [toHours, setToHours] = useState(toDate.getHours().toString().padStart(2, '0'));
  const [toMinutes, setToMinutes] = useState(toDate.getMinutes().toString().padStart(2, '0'));
  const [toSeconds, setToSeconds] = useState('00');

  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("")
  const [subdomain,setSubdomain]= useState("")
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");

  const [showProdModal, setShowProdModal] = useState(false)
  const [prodDate, setProdDate] = useState(new Date(moment().subtract(1, 'days').toDate()))
  const [prodHours, setProdHours] = useState(prodDate.getHours().toString().padStart(2, '0'));
  const [prodMinutes, setProdMinutes] = useState(prodDate.getMinutes().toString().padStart(2, '0'));
  const [prodSeconds, setProdSeconds] = useState(prodDate.getSeconds().toString().padStart(2, '0'));
  const [inputs, setInputs] = useState([
    { label: 'TOTAL CONVERTER HEATS', value: '' },
    { label: 'LHF-1 (HEATS)', value: '' },
    { label: 'LHF-2 (HEATS)', value: '' },
    { label: 'LHF-3 (HEATS)', value: '' },
    { label: 'BOF PRODUCTION (TON)', value: '' },
    { label: 'LHF-1 PRODUCTION (TON)', value: '' },
    { label: 'LHF-2 PRODUCTION (TON)', value: '' },
    { label: 'LHF-3 PRODUCTION (TON)', value: '' },
    { label: 'CCP-1 PRODUCTION (TON)', value: '' },
    { label: 'CCP-2 PRODUCTION (TON)', value: '' },
    { label: 'CCP-3 PRODUCTION (TON)', value: '' },
    { label: 'CCP-4 PRODUCTION (TON)', value: '' },
    { label: 'CONVERTER-1 PRODUCTION (TON)', value: '' },
    { label: 'CONVERTER-2 PRODUCTION (TON)', value: '' },
    { label: 'CONVERTER-3 PRODUCTION (TON)', value: '' },
    { label: 'NUMBER OF HEATS IN CONVERTER-1', value: '' },
    { label: 'NUMBER OF HEATS IN CONVERTER-2', value: '' },
    { label: 'NUMBER OF HEATS IN CONVERTER-3', value: '' },
    { label: 'LHF 1 SUM OF ARC TIME IN MIN', value: '' },
    { label: 'LHF 1 AVERAGE ARC TIME >20 MIN', value: '' },
    { label: 'LHF 1 NUMBER OF HEAT ARC >20 MIN', value: '' },
    { label: 'LHF 2 SUM OF ARC TIME IN MIN', value: '' },
    { label: 'LHF 2 AVERAGE ARC TIME >20 MIN', value: '' },
    { label: 'LHF 2 NUMBER OF HEAT ARC >20 MIN', value: '' },
    { label: 'LHF 3 SUM OF ARC TIME IN MIN', value: '' },
    { label: 'LHF 3 AVERAGE ARC TIME >20 MIN', value: '' },
    { label: 'LHF 3 NUMBER OF HEAT ARC >20 MIN', value: '' }
  ]);
  const [showConfirmation, setShowConfirmation] = useState(false); // State to control confirmation modal


  const [excelDate, setExcelDate] = useState(new Date(moment().subtract(1, 'days').toDate()));
  const [excelHours, setExcelHours] = useState(prodDate.getHours().toString().padStart(2, '0'));
  const [excelMinutes, setExcelMinutes] = useState(prodDate.getMinutes().toString().padStart(2, '0'));
  const [excelSeconds, setExcelSeconds] = useState(prodDate.getSeconds().toString().padStart(2, '0'));
  const [excelToDate, setExcelToDate] = useState(new Date());
  const [excelToHours, setExcelToHours] = useState(prodDate.getHours().toString().padStart(2, '0'));
  const [excelToMinutes, setExcelToMinutes] = useState(prodDate.getMinutes().toString().padStart(2, '0'));
  const [excelToSeconds, setExcelToSeconds] = useState(prodDate.getSeconds().toString().padStart(2, '0'));
  const [showExcel, setShowExcel] = useState(false);
  const [reportList,setReportList]=useState([])
  const [selectedReportName, setSelectedReportName] = useState("");
  const [selectedReportId, setSelectedReportId] = useState("");
  const[selectedReportLink,setSelectedReportLink]= useState("")
  const [reportExcel,setReportExcel] = useState({ columns: [], data: [] })



    // Custom input component to disable manual typing
    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <input
        className="form-control"
        value={value}
        onClick={onClick}
        ref={ref}
        readOnly
      />
    ));

  const validationSchema = Yup.object().shape({
    // fromDate: Yup.date()
    //   .required('From Date is required')
    //   .max(new Date(moment(toDate).add(1, 'days')), 'From Date should be less than or equal to To Date')
    //   .min(new Date(moment(fromDate).subtract(30, 'days')), 'From Date is exceeding the minimum From Date'),
    // .max(new Date(), 'From Date should be less than or equal to the current date'),
    // toDate: Yup.date()
    //   .required('To Date is required')
    //   .min(new Date(moment(fromDate).subtract(1, 'days')), 'To Date should be greater than or equal to From Date')
    //   .max(new Date(moment(fromDate).add(30, 'days')), 'To Date is exceeding the maximum To Date')
    // ,

    location: Yup.object().shape({
      value: Yup.string().required('Location is required'),
    }),

    site: Yup.object().shape({
      value: Yup.string().required('Site is required'),
    }),

    isParam: Yup.boolean().notRequired(),

    parameter: Yup.array()
      .when('isParam', {
        is: false,
        then: (validationSchema) => validationSchema
          .required('Parameter is required')
          .min(1, 'At least one parameter must be selected'),
        otherwise: (validationSchema) => validationSchema.notRequired(),
      })
      .nullable(),

    group: Yup.object().shape({})
      // 
      .when('isParam', {
        is: true,
        then: (validationSchema) => validationSchema.required('Group is required'),
        otherwise: (validationSchema) => validationSchema.notRequired(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      // fromDate: fromDate,
      toDate: toDate,
      location: locationData.length > 0 ? locationData[0] : null,
      site: assetData.length > 0 ? assetData[0] : null,
      parameter: parameterData.length > 0 ? [parameterData[0]] : [],
      isParam: isParam,
      group: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const fromDateWithHours = new Date(values.fromDate);
      fromDateWithHours.setHours(fromHours, fromMinutes, fromSeconds);

      const toDateWithHours = new Date(values.toDate);
      toDateWithHours.setHours(toHours, toMinutes, toSeconds);

    // Check if To Date is less than From Date
    if (toDateWithHours < fromDateWithHours) {
      formik.setFieldError('toDate', 'To Date cannot be less than From Date.');
      return;
    }

    // Check the difference between fromDate and toDate
    const diffInDays = moment(toDateWithHours).diff(moment(fromDateWithHours), 'days');
    
    // If difference exceeds 30 days, show error message
    if (diffInDays > 30) {
      formik.setFieldError('toDate', 'Maximum allowed date range is 30 days');
    } else {

      const data = {
        fromDate: fromDateWithHours,
        toDate: toDateWithHours,
        // toDate: new Date(toDate),
        location: selectedLocation,
        site: selectedAsset,
        parameter: selectedParameter,
        group: values.group,
      };
      props.handleSubmitData(data);
      handleCloseFirstModal();

      props.updateFromDate(fromDateWithHours);
      props.updateToDate(toDateWithHours)
    }
    },
  });

  const groupFormik = useFormik({
    // enableReinitialize: true, // Enable reinitialization when initialValues change
    initialValues: {
      group_name: '',
    },
    validationSchema: Yup.object().shape({
      group_name: Yup.string()
        .max(25, 'Title must be equal to or less than 25 characters')
        .required('Title is required'),
    }),
    onSubmit: (values) => {
      handleAddGroup(values.group_name);
      handleCloseSecondModal();
    },
  });

  const handleCloseFirstModal = () => {

    setShowFirstModal(false)
  };
  const handleShowFirstModal = () => {
    formik.setErrors({
      // fromDate: '',
      toDate: '',
      location: '',
      site: '',
      parameter: '',
    });
    formik.setFieldValue("fromDate", fromDate)
    formik.setFieldValue('location', selectedLocation)
    formik.setFieldValue('site', selectedAsset)
    formik.setFieldValue('parameter', selectedParameter || '')
    setShowFirstModal(true);
  }

  const handleCloseSecondModal = () => { setShowSecondModal(false); }
  const handleShowSecondModal = () => { setShowSecondModal(true); }

  const handleCloseTitleGroupForm = () => {
    setShowSecondModal(false);
  };

  const handleLocationChange = (selectedLocation) => {
    localStorage.removeItem("asset");
    props.changeLocation(selectedLocation);
  }

  const changeToggleButton = (checked) => {
    let toggleParamColorElement = document.getElementById("toggleParamColor");
    let spanElements = toggleParamColorElement.querySelectorAll("span");
    if (checked) {
      spanElements[0].style.color = "black";
      spanElements[1].style.color = "white";

    }
    else {
      spanElements[1].style.color = "black";
      spanElements[0].style.color = "white";
    }
  }

  /////// for adding components ///////////
  const handleCloseProdModal = () => {
    setShowProdModal(false)
  };
  const handleShowProdModal = () => {
    setShowProdModal(true);
  }

  const combineDateTime = (date, hours, minutes, seconds) => {
    const formattedDate = formatDate(date);
    const formattedTime = formatTime(hours, minutes, seconds);
    return `${formattedDate} ${formattedTime}`;
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const formatTime = (hours, minutes, seconds) => {
    return `${hours}:${minutes}:${seconds}`;
  };

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);
  useEffect(() => {
    const tkData = Cookies.get('tkData');
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      setSubdomain(tokenData.subdomain)
    }

    const fetchReportNames = async () => {
      await reportNames();
    };

    fetchReportNames();
  }, []);

  useEffect(() => {
    if (reportList.length > 0) {
      setSelectedReportName(reportList[0].report_name);
      setSelectedReportId(reportList[0]._id);
      setSelectedReportLink(reportList[0].api_url)
    }
  }, [reportList]);

  const handleSubmit = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    const datetime = combineDateTime(prodDate, prodHours, prodMinutes, prodSeconds);

    // const datetime = moment(prodDate).format("YYYY-MM-DD");
    const parameters = inputs.reduce((acc, cur) => {
      acc[cur.label] = cur.value.trim() !== '' ? parseFloat(cur.value) : 0
      return acc;
    }, {});

    const dataToSend = {
      access_token: token,
      user_log_id: userLogId,
      parameters: {
        datetime,
        ...parameters
      }
    };
    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/production_jsw/add_JSW_parameters`,
          dataToSend
        );

        if (response.status === 200 && response.data.success === true) {
          showFlashMessage(response.data.msg, "success");
          setInputs(inputs.map(input => ({ ...input, value: '' })));
          success = true;
        } else if (response.data.success === false) {
          console.error('Failed to add parameter');
          setInputs(inputs.map(input => ({ ...input, value: '' })));
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Failed to add parameter');
        }
      } catch (error) {
        console.error("Error:", error);
        showFlashMessage("Error sending data", "danger");
      }

      attempts++;
    }
    if (!success) {
      showFlashMessage("Parameter Not Added Successfully.", "danger");
    }
  };

  const energy_summary_report  = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    const combineDate = (date) => {
      const formattedDate = formatDate(date);
      return `${formattedDate}`
    };
    const datetime = combineDate(prodDate);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/energy_summary_report?start_date=${datetime}&end_date=${datetime}`,
        );

        if (response.status === 200 && response.data.success === true) {
        
        } else if (response.data.success === false) {
          console.error('Failed to send');
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Failed to send');
        }
      } catch (error) {
        console.error("Error:", error);
      }
  };

  const custom_calculations  = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    const combineDate = (date) => {
      const formattedDate = formatDate(date);
      return `${formattedDate}`
    };

    const datetime = combineDate(prodDate);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VARIABLE}/iotrms/custom_calculations?start_date=${datetime}&end_date=${datetime}`,
        );

        if (response.status === 200 && response.data.success === true) {

        } else if (response.data.success === false) {
          console.error('Failed to send');
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Failed to send');
        }
      } catch (error) {
        console.error("Error:", error);
      }
    
  };

  const handleChange = (index, event) => {
    const value = event.target.value;
    // Check if the value is numeric (optional: allow empty string as well)
    if (!isNaN(value) || value === '') {
      const values = [...inputs];
      values[index].value = value;
      setInputs(values);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setShowConfirmation(true); // Show confirmation modal instead of window.confirm
  };

  const handleConfirm = () => {
    handleSubmit();
    energy_summary_report()
    custom_calculations()
    handleCloseProdModal(); // Close the add production modal
    setShowConfirmation(false); // Close the confirmation modal
  };

   ///////// downloading report excel////////

   const reportNames = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/list_of_energy_summary_reports`,
          {access_token: token,
      user_log_id: userLogId,}
        );
        if ( response.data.success === true) {
          setReportList(response.data.data)
          success = true;
        } else if (response.data.success === false) {
          console.error("Reports are Not Found");
        }
        else {
          console.error("Reports are Not Found");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Reports are Not Found","danger")
    }
  };

  const handleExcelData = async (event) => {
    
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    // with time
    // const datetime = combineDateTime(excelDate, excelHours, excelMinutes, excelSeconds, excelToDate, excelToHours, excelToMinutes, excelToSeconds);

    // without time
    const startDate = formatDate(excelDate) 
    const endDate = formatDate(excelToDate)
    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}${selectedReportLink}`,
          { access_token: token,
            user_log_id: userLogId,
            "start_date":startDate,
            "end_date": endDate,
            "iot_sol":"EMS"
          }
        );
        // const responseData = { "success": true, "data": { "columns": ["Date", "A 03"], "rows": [["2024-03-20", 1], ["2024-03-19", 10.257683], ["2024-03-18", 5.678948], ["2024-03-16", 22], ["2024-03-15", 2], ["2024-03-14", 2]] } }
        if ( response.data.success === true) {

          setReportExcel(response.data.data)

          //excel
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Sheet1');
        
          // Add columns
          worksheet.columns = response.data.data.columns.map(column => ({ header: column, key: column }));
        
          // Add rows
          response.data.data.rows.forEach(row => {
            worksheet.addRow(row);
          });
        
          // Generate buffer
          const buffer = await workbook.xlsx.writeBuffer();
        
          // Save file
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          FileSaver.saveAs(blob, `${selectedReportName}.xlsx`);
          showFlashMessage("Report Downloaded Successfully", "success");
          success = true;
        } else if (response.data.success === false) {
          console.error('Report Not Downloaded Successfully', "danger");
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Report Not Downloaded Successfully', "danger");
        }
      } catch (error) {
        console.error("Error:", error);
        showFlashMessage("Error sending data", "danger");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Report Not Downloaded Successfully", "danger");
    }
  };

const handleExcel = () => {
  reportNames()
  setShowExcel(true);
};

const handleExcelSubmit = async (event) => {
  event.preventDefault();
  await handleExcelData();
  setShowExcel(false);
};


  // Calculate the last two dates
  const lastDate = new Date(moment().subtract(1, 'days').toDate());
  const secondLastDate = new Date(moment().subtract(2, 'days').toDate()); 
  return (
    <>
     
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="modal"
              href="#"
              data-bs-target="#alert"
               data-tooltip-id="Alert-tooltip"  
              data-tooltip-content="Alert"
            >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
            <Tooltip id="Alert-tooltip" place="bottom"  type="dark" effect="solid" style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowFirstModal}
                 data-tooltip-id="filter-tooltip"  
              data-tooltip-content="Filter"
            >
              <i className="fa fa-filter nav-icon"></i>
            </a>
            <Tooltip id="filter-tooltip" place="bottom"  type="dark" effect="solid"style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li>
          {['jswsms1bay', 'test1'].includes(subdomain) && (
            <>
          {/* <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowProdModal}
                 data-tooltip-id="Add-tooltip"  
              data-tooltip-content="Add"
            >
              <i className="fa fa-plus nav-icon"></i>
            </a>
            <Tooltip id="Add-tooltip" place="bottom"  type="dark" effect="solid" style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li> */}
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleExcel}
                data-tooltip-id="Excel-tooltip"  
              data-tooltip-content="Excel"
            >
              <i className="fa fa-file-excel-o"></i>
            </a>
            <Tooltip id="Excel-tooltip" place="bottom"  type="dark" effect="solid" style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li>
          </>
        )}
        </ul>
      </div>
      {/* filter modal */}
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirstModal}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5"><b>Filter</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} id="energy_consumtion_form">
            <div className="form-row">
              <div className="col-md-12">
                {/* <div className="selectWpr"> */}

                {/* is param toggle */}
                <div
                  className="form-group switch float-left"
                // onChange={getGroupName}
                >
                  <label className="switch-light">
                    <input
                      type="checkbox"
                      checked={formik.values.isParam}
                      onChange={(e) => {
                        formik.setFieldValue("isParam", e.target.checked);
                        changeIsParam(e.target.checked);
                        changeToggleButton(e.target.checked);
                      }}
                      onBlur={() => {
                        // formik.handleBlur('site');
                        formik.setFieldTouched("isParam", true);
                      }}
                      name="isGroupSwitchOn"
                    />
                    <span id='toggleParamColor'>
                      <span style={{ color: "white" }}>Parameter</span>
                      <span>Group</span>
                    </span>
                    <a className="btn btn-primary"></a>
                  </label>
                </div>

                {/* add group */}
                {formik.values.isParam === false && (
                  <div className="float-right form-group">
                    <span
                      onClick={(e) =>
                        formik.values.parameter.length < 2 && setDisabled(true)
                      }
                    >
                      <Button
                        className="btn btn-sm btn-c-outline-primary"
                        onClick={handleShowSecondModal}
                        disabled={
                          formik.values.isParam === false &&
                          formik.values.parameter.length <= 1
                        }
                      >
                        Add Group
                      </Button>
                    </span>
                  </div>
                )}

                <br />

                <div className="col-md-12" Style={"clear: both"}></div>

                {/* location */}
                <div className="form-group">
                  <label>Location *</label>
                  <Select
                    options={locationData}
                    placeholder="Select Location"
                    onChange={(e) => {
                      handleLocationChange(e)
                      formik.setFieldValue("site", "");
                      // changeLocation(e);
                      formik.setFieldTouched("location", true);
                      formik.setFieldValue("location", e);
                    }}
                    value={formik.values.location}
                    onBlur={() => {
                      // formik.handleBlur('location');
                      formik.setFieldTouched("location", true);
                    }}
                  />
                  {formik.errors.location && formik.touched.location && (
                    <div className="error-message text-danger">
                      {formik.errors.location.value}
                    </div>
                  )}
                </div>
                {/* site */}
                <div className="form-group">
                  <label>Asset *</label>
                  <Select
                    options={assetData}
                    placeholder="Select Site"
                    value={formik.values.site}
                    onChange={(e) => {
                      formik.setFieldValue("parameter", []);
                      changeAsset(e);
                      formik.setFieldTouched("site", true);
                      formik.setFieldValue("site", e);
                    }}
                    onBlur={() => {
                      // formik.handleBlur('site');
                      formik.setFieldTouched("site", true);
                    }}
                  />
                  {formik.errors.site && formik.touched.site && (
                    <div className="error-message text-danger">
                      {formik.errors.site.value}
                    </div>
                  )}
                  {/* </div> */}

                </div>



                {/* parameters */}
                <div className="col-md-12" Style={"clear: both"}></div>
                {formik.values.isParam === false && (
                  <div className="form-group fixHeight">
                    <label>Parameter *</label>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false} // This prop will keep the dropdown open
                      options={parameterData}
                      placeholder="Select Parameter"
                      value={formik.values.parameter}
                      onChange={(e) => {
                        changeParameter(e);
                        formik.setFieldTouched("parameter", true);
                        formik.setFieldValue("parameter", e);
                        setDisabled(false);

                      }}
                      onBlur={() => {
                        formik.setFieldTouched("parameter", true);
                      }}
                    />

                    {formik.errors.parameter && !formik.values.parameter.length <= 1 && formik.touched.parameter && (
                      <div className="error-message text-danger">
                        {formik.errors.parameter}
                      </div>
                    )}

                    {formik.values.parameter.length <= 1 &&
                      disabled && !formik.errors.parameter && (
                        <div className="error-message text-danger">
                          Group can be created it required more than one parameter.
                        </div>
                      )}
                  </div>
                )}

                {formik.values.isParam === true && (
                  <div className="form-group">
                    <label>Group *</label>
                    <Select
                      options={groupData}
                      placeholder="Select Group"
                      onChange={(e) => {
                        formik.setFieldTouched("group", true);
                        formik.setFieldValue("group", e);
                      }}
                      value={formik.values.group}
                      onBlur={() => {
                        formik.setFieldTouched("group", true);
                      }}
                    />
                    {formik.errors.group && (
                      <div className="error-message text-danger">
                        {formik.errors.group}
                      </div>
                    )}
                  </div>
                )}

                {/* from date */}
                <div className="form-group">
                  <label>From Date *</label>
                  <div className='row'>

                    <div className='col-8'>
                      <DatePicker
                        selected={formik.values.fromDate}
                        onChange={(date) => {
                          if (moment(date, "DD-MM-YYYY", true).isValid()) {
                            formik.setFieldValue('fromDate', date);
                            formik.setFieldTouched('fromDate', true);
                            setFromDate(date);
                            // const lastDate = new Date(moment(date).add(30, 'days'));
                            // formik.setFieldValue('toDate', lastDate);
                            // formik.setFieldTouched('toDate', true);
                            // setToDate(lastDate);
                          }
                  
                        }}
                     
                        dateFormat="dd-MM-yyyy"
                
                        className="form-control"
                     

                      maxDate={new Date()} // Maximum date as today
                      minDate={new Date(moment().subtract(3, "months"))} // Minimum date 3 months ago
                      customInput={<CustomDateInput />}
                      />
                    </div>

                    <div className='col-2'>
                      <select
                        className="form-control"
                        value={fromHours}
                        onChange={(e) => setFromHours(e.target.value)}
                      >
                        {Array.from({ length: 24 }, (_, i) => {
                          const hour = i.toString().padStart(2, '0');
                          return <option key={hour} value={hour}>{hour}</option>;
                        })}
                      </select>
                    </div>

                    <div className='col-2'>
                      <select
                        className="form-control"
                        value={fromMinutes}
                        onChange={(e) => setFromMinutes(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const minute = i.toString().padStart(2, '0');
                          return <option key={minute} value={minute}>{minute}</option>;
                        })}
                      </select>
                    </div>

                    <div className='col-2' style={{ display: 'none' }}>
                      <select
                        className="form-control"
                        value={fromSeconds}
                        onChange={(e) => setFromSeconds(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const second = i.toString().padStart(2, '0');
                          return <option key={second} value={second}>{second}</option>;
                        })}
                      </select>
                    </div>
                    {/* {formik.errors.fromDate && formik.touched.fromDate && (
                      <div className="error-message text-danger">
                        {formik.errors.fromDate}
                      </div>
                    )} */}

                  </div>
                </div>
                {/* to date */}
                <div className="form-group">
                  <label>To Date *</label>
                  <div className='row'>
                    <div className='col-8'>
                      <DatePicker
                        selected={formik.values.toDate}
                        onChange={(date) => {
                          if (moment(date, "DD-MM-YYYY", true).isValid()) {
                            formik.setFieldValue("toDate", date);
                            formik.setFieldTouched("toDate", true);
                            setToDate(date);
                          }

                          // updateDateRange(formik.values.fromDate, date);
                        }}
                        // showTimeSelect
                        // timeFormat="HH:mm"
                        // timeIntervals={15}
                        // timeCaption="Time"
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        includeDateIntervals={[
                          {
                            start: new Date(moment(fromDate).subtract(3, "months")
                            ),
                            end: new Date(moment(fromDate).add(3, "months")),
                          },
                        ]}
                        onBlur={(e) => {
                          try {
                            if (
                              moment(
                                e.target.value,
                                "DD-MM-YYYY",
                                true
                              ).isValid()
                            ) {
                              const date = new Date(
                                moment(e.target.value, "DD-MM-YYYY").format(
                                  "YYYY-MM-DD"
                                )
                              );
                              formik.setFieldValue("toDate", date);
                              formik.setFieldTouched("toDate", true);
                              setToDate(date);
                            }
                          } catch (error) { }
                        }}
                        maxDate={new Date()}
                        // maxDate={new Date(moment(fromDate).add(15, "days"))}
                        minDate={new Date(moment().subtract(3, "months"))}
                        customInput={<CustomDateInput />}
                      />
                    </div>
                    <div className='col-2'>
                      <select
                        className="form-control"
                        value={toHours}
                        onChange={(e) => setToHours(e.target.value)}
                      >
                        {Array.from({ length: 24 }, (_, i) => {
                          const hour = i.toString().padStart(2, '0');
                          return <option key={hour} value={hour}>{hour}</option>;
                        })}
                      </select>
                    </div>
                    <div className='col-2'>
                      <select
                        className="form-control"
                        value={toMinutes}
                        onChange={(e) => setToMinutes(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const minute = i.toString().padStart(2, '0');
                          return <option key={minute} value={minute}>{minute}</option>;
                        })}
                      </select>
                    </div>
                    <div className='col-2' style={{ display: 'none' }}>
                      <select
                        className="form-control"
                        value={toSeconds}
                        onChange={(e) => setToSeconds(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const second = i.toString().padStart(2, '0');
                          return <option key={second} value={second}>{second}</option>;
                        })}
                      </select>
                    </div>
                    {formik.errors.toDate && formik.touched.toDate && (
    <div className="error-message text-danger text-center">
      {formik.errors.toDate}
    </div>
  )}
                  </div>
                </div>
                <div className="ftrBtn">
                  <div className="form-group">
                    <input
                      type="submit"
                      name=""
                      value="Submit"
                      className="btn btn-primary btn-block"
                      data-disable-with="Submit"
                    />
                  </div>
                </div>
              </div>
             
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* add group modal */}
      <Modal
        show={showSecondModal}
        onHide={handleCloseSecondModal}
        data-bs-backdrop="static"
        className="rightGroup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Add Title To Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={groupFormik.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Title"
                name="groupName"
                value={groupFormik.values.group_name}
                // onBlur={formik.handleBlur}
                onChange={(e) => {
                  groupFormik.setFieldValue("group_name", e.target.value);
                }}
                onBlur={() => {
                  groupFormik.setFieldTouched("group_name", true);
                }}
              />
              {groupFormik.errors.group_name && (
                <div className="error-message text-danger">
                  {groupFormik.errors.group_name}
                </div>
              )}
            </div>
            <div className="">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Save"
                  className="btn btn-primary btn-block"
                  // disabled={!formik.isValid}
                  data-disable-with="Submit"
                  // checked={formik.values.isGroupSwitchOn}
                  // onChange={formik.handleChange}
                  onClick={(e) => {
                    // e.preventDefault();
                    // handleSaveGroup(formik.values.groupName);
                    handleCloseTitleGroupForm();
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* add production  */}
      <Modal
        show={showProdModal}
        onHide={handleCloseProdModal}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5"><b>Production</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit} id="energy_consumtion_form">
            <div className="form-group">
              <div className="row">
                <div className="col-8">
                  <label htmlFor="prodDate">Date</label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    minDate={secondLastDate}
                    maxDate={lastDate}
                    selected={prodDate}
                    onChange={(date) => setProdDate(date)}
                    className="form-control"
                    id="prodDate"
                  />
                </div>
                <div className="col-2">
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={prodHours}
                    onChange={(e) => setProdHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={prodMinutes}
                    onChange={(e) => setProdMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                {/* <div className="col-2">
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={prodSeconds}
                    onChange={(e) => setProdSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>

                </div> */}
              </div>
            </div>
            <div className="row">
              {inputs.map((input, index) => (
                <div className="col-md-12" key={index}>
                  <div className="form-group">
                    <label>{input.label}</label>
                    <input
                      type="text"
                      inputmode="numeric"
                      className="form-control"
                      value={input.value}
                      onChange={(event) => handleChange(index, event)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="ftrBtn">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Submit"
                  className="btn btn-primary btn-block"
                  data-disable-with="Submit"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <img
            src="assets/images/motware_logo.png"
            alt="motware Logo"
            width="40px"
            className="brand-image"
          />
          <h5 className="modal-title w-100 text-center">Production</h5>
        </Modal.Header>
        <Modal.Body><div className="text-center">Are you sure you want to add Parameters?</div></Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowConfirmation(false)}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirm}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

 {/* excel Modal */}
 <Modal
        show={showExcel}
        onHide={() => setShowExcel(false)}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5"><b>Reports</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleExcelSubmit} id="energy_consumtion_form">
          <div className="row">
              <div className="col-md-12" >
                <div className="form-group">
                  <label>Report Name</label>
                  <Form.Select 
  value={selectedReportId}
  onChange={(e) => {
    const selectedReport = reportList.find(report => report._id === e.target.value);
    setSelectedReportName(selectedReport.report_name);
    setSelectedReportId(selectedReport._id);
    setSelectedReportLink(selectedReport.api_url)
  }}
>
  {reportList.map(report => (
    <option key={report._id} value={report._id}>
      {report.report_name}
    </option>
  ))}
</Form.Select>

                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="prodDate">From Date</label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={excelDate}
                    onChange={(date) => setExcelDate(date)}
                    className="form-control"
                    id="excelDate"
                  />
                </div>
                {/* <div className="col-2">
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={prodHours}
                    onChange={(e) => setExcelHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={prodMinutes}
                    onChange={(e) => setExcelMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={prodSeconds}
                    onChange={(e) => setExcelSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>

                </div> */}
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="prodDate">To Date</label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={excelToDate}
                    onChange={(date) => setExcelToDate(date)}
                    className="form-control"
                    id="excelToDate"
                  />
                </div>
                {/* <div className="col-2">
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={prodHours}
                    onChange={(e) => setExcelToHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={prodMinutes}
                    onChange={(e) => setExcelToMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={prodSeconds}
                    onChange={(e) => setExcelToSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>

                </div> */}
              </div>
            </div>
           
            {/* <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => setShowExcel(false)}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleExcelSubmit}>
              Confirm
            </button>
          </Modal.Footer> */}
            <div className="ftrBtn">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Submit"
                  className="btn btn-primary btn-block"
                  data-disable-with="Submit"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Sidebar