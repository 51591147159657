/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Link, useLocation } from "react-router-dom";
import MapLoad from "./MapLoad";
import Table from "./Table";
import React, { useEffect, useState, useCallback, useContext } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import solutionContext from "../../../context/solution/solutionContext";
import Sidebar from "./Sidebar";
import DataTable from "react-data-table-component";
import TxiqSkeleton from "../../../Transformer/Summary/MainContent/TxiqSkeleton";
const MainContent = () => {
  // const { state } = useLocation();
  // Flash msg
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [locations, setLocations] = useState([]); // State for locations
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(0);
  const [device_count, setDeviceCount] = useState({
    grey: "-",
    red: "-",
    green: "-",
  });
  const { solution_id, update_solution } = useContext(solutionContext);
  const [solution, setSolution_id] = useState(solution_id);
  const [statusFilter, setStatusFilter] = useState("All");

  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }

    if (solution === null) {
      setSolution_id(localStorage.getItem("solution"));
      update_solution(localStorage.getItem("solution"));
    }
  }, []);

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const fetchMapData = useCallback(async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/solution_summary/solution_wise_summary_map`,
          {
            access_token: token,
            iot_sol: "EMS",
            user_log_id: userLogId,
          }
        );

        if (response.status === 200 && response.data.success === true) {
          const mapData = response.data;

          if (mapData.success === true) {
            const result = [...mapData.data];
            const grey = result.filter((item) => item.status === "Grey").length;
            const green = result.filter(
              (item) => item.status === "Green"
            ).length;
            const red = result.filter((item) => item.status === "Red").length;
            const data = { grey, green, red };
            setDeviceCount(data);
            setLocations(result);
            success = true;
          }
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Something went wrong";
          // showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Map API:", error);
        showFlashMessage("Something went wrong");
      }

      attempts++;
    }
    if (!success) {
      showFlashMessage("Something went wrong");
    }
  }, [token]);

  const fetchTableData = useCallback(async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          // `${process.env.REACT_APP_VARIABLE}/iotrms/summary/summary_table`
          `${process.env.REACT_APP_VARIABLE}/iotrms/solution_summary/solution_wise_summary_table`,
          {
            access_token: token,
            iot_sol: "EMS",
            user_log_id: userLogId,
          }
        );

        if (response.status === 200 && response.data.success) {
          setData(response.data.data?.data || []);
          success = true;
          // setData([]);
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Data Not Available for Solution Wise Summary";
          // showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Table API:", error);
        showFlashMessage("Data Not Available for Solution Wise Summary");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available for Solution Wise Summary");
    }
  }, [token]); // Run once on component mount

  const fetchCriticalAssetData = useCallback(async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          // `${process.env.REACT_APP_VARIABLE}/iotrms/summary/summary_table`
          `${process.env.REACT_APP_VARIABLE}/iotrms/solution_summary/solution_wise_summary_table`,
          {
            access_token: token,
            iot_sol: "EMS",
            user_log_id: userLogId,
            critical_asset: 1,
          }
        );

        if (response.status === 200 && response.data.success) {
          setData(response.data.data?.data || []);
          success = true;
          // setData([]);
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Data Not Available for Critical Asset Summary";
          // showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Table API:", error);
        showFlashMessage("Data Not Available for Critical Asset Summary");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available for Critical Asset Summary");
    }
  }, [token]); // Run once on component mount
  const [alertData, setAlertData] = useState([]);
  const fetchLastAlertData = useCallback(async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          // `${process.env.REACT_APP_VARIABLE}/iotrms/summary/summary_table`
          `${process.env.REACT_APP_VARIABLE}/iotrms/alerts/last_alerts`,
          {
            access_token: token,
            user_log_id: userLogId,
             iot_sol:"EMS"
          }
        );

        if (response.status === 200 && response.data.success) {

          setAlertData(response.data.data?.data || []);
          success = true;
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Data Not Available for Latest Alerts";
          // showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Table API:", error);
        showFlashMessage("Data Not Available for Latest Alerts");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available for Latest Alerts");
      setIsLoading(false);
    }
  }, [token]);

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    // Extract date components
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Extract time components
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await fetchLastAlertData();
          await fetchMapData();
          await fetchCriticalAssetData();
          setIsLoading(false);
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };

      const delay = 200;

      const timerId = setTimeout(() => {
        fetchData();
      }, delay);

      // for enable disable of the pdf icon
      // Check if both locations and data are blank

      // Cleanup function to clear the timer in case the component unmounts or the token changes
      return () => clearTimeout(timerId);
    }
  }, [token]);

  const isDataAbsent = locations.length === 0 && data.length === 0;
  if (isDataAbsent) {

    // Disable the link using its ID
    const pdfLink = document.getElementById("summary_pdf_icon");
    if (pdfLink) {
      pdfLink.style.pointerEvents = "none";
    }
  } else {
    const pdfLink = document.getElementById("summary_pdf_icon");
    if (pdfLink) {
      pdfLink.style.pointerEvents = "";
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setLoad((prevLoad) => {
        if (prevLoad < 100) {
          return prevLoad + 1;
        } else {
          // Reset load to 0 after reaching 100
          setTimeout(() => {
            setLoad(0);
          }, 2000);
        }
      });
    }, 50);
  }, []);
  // display loader
  // if (isLoading) {
  //   return (
  //     <div className='loaderWpr'>
  //       <div className="loader"></div>
  //     </div>
  //   );
  // }
  const handleStatusClick = (status) => {
    fetchTableData();
    setStatusFilter(status);
  };
  const NoDataComponent = () => (
    <table className="noDataTbl table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Datetime</th>
          <th>Location</th>
          <th>Asset</th>
          <th>Parameter</th>
          <th>Value</th>
          <th>Alert</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={tableColumns.length}>
            <div className="text-center">
              <p>No Data Available</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
  const tableColumns = [
    {
      name: "Datetime",
      selector: (row) => convertTimestamp(row[4]),
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row[2],
      sortable: true,
    },
    {
      name: "Asset",
      selector: (row) => row[1],
      sortable: true,
    },
    {
      name: "Parameter",
      selector: (row) => row[6],
      cell: (row) => <div style={{ whiteSpace: "pre-wrap" }}>{row[6]}</div>,
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => row[7],
      sortable: true,
    },
    {
      name: "Alert",
      selector: (row) => row[3],
      cell: (row) => <div style={{ whiteSpace: "pre-wrap" }}>{row[3]}</div>,
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal",
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };
  return (
    <>
      {" "}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {isLoading && (
             <TxiqSkeleton />
            )}
           
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                <div className="mdlContent card-body">
                  <div className="col-md-12 col-lg-12">
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <a
                          href="#"
                          className="info-box"
                          onClick={() => handleStatusClick("Active")}
                        >
                          <span className="info-box-icon bg-greenPin elevation-1"></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Active</span>
                          </div>
                          <div className="info-box-number">
                            <span className="info-box-text">
                              {device_count.green}
                            </span>
                          </div>
                        </a>
                      </div>
                      {/* <div className="col-md-4 col-lg-4">
                        <div className="info-box">
                          <span className="info-box-icon bg-redPin elevation-1"></span>
                          <div className="info-box-content">
                            <span className="info-box-text">System Not Working</span>
                          </div>
                          <div className="info-box-number">
                            <span className="info-box-text">{device_count.red}</span>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-6 col-lg-6">
                        <a
                          href="#"
                          className="info-box"
                          onClick={() => handleStatusClick("Inactive")}
                        >
                          <span className="info-box-icon bg-grayPin elevation-1"></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Inactive</span>
                          </div>
                          <div className="info-box-number">
                            <span className="info-box-text">
                              {device_count.grey}
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="map col-md-12 col-lg-12">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <MapLoad
                          zoomcntr={true}
                          gesture=""
                          mapType={true}
                          locations={locations}
                        />
                      </div>
                     
                    </div>
                   
                  </div> */}
                  <div className="col-md-12 col-lg-12">
                    <div className="card pr-card mb30 alerttblCard">
                      <div className="card-header">
                        <h5>Latest Alerts</h5>
                        <div className="form-inline pr-form-inline float-right">
                          <div className="input-group pr-form-group">
                            <a
                              data-bs-toggle="modal"
                              data-bs-target="#alert"
                              title="Alert"
                            >
                              View All
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <DataTable
                          columns={tableColumns}
                          data={alertData}
                          fixedHeader
                          paginationComponentOptions={
                            paginationComponentOptions
                          }
                          noDataComponent={<NoDataComponent />}
                          customStyles={customStyles}
                        />
                        {/* <div className="table-responsive">
                          <table className="table table-hover dtr-inline">
                            <thead>
                              <tr>
                                <th
                                  style={{ background: "none", border: "none" }}
                                >
                                  Datetime
                                </th>
                                <th
                                  style={{ background: "none", border: "none" }}
                                >
                                  Location
                                </th>
                                <th
                                  style={{ background: "none", border: "none" }}
                                >
                                  Asset
                                </th>
                                <th
                                  style={{ background: "none", border: "none" }}
                                >
                                  Parameter
                                </th>
                                <th
                                  style={{ background: "none", border: "none" }}
                                >
                                  Value
                                </th>
                                <th
                                  style={{ background: "none", border: "none" }}
                                >
                                  Alert
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {alertData.length !== 0 &&
                                alertData.map((el) => (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      {convertTimestamp(el[5])}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {el[2]}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {el[1]}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {el[3]}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {el[7]}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {el[4]}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <Table
                      data={data}
                      fetchTableData={fetchTableData}
                      fetchCriticalAssetData={fetchCriticalAssetData}
                      statusFilter={statusFilter}
                      setStatusFilter={setStatusFilter}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Sidebar />
    </>
  );
};
export default MainContent;
